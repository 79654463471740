import React from "react";

function DayNumber({ day, dateIsToday, otherMonth }) {
  if (dateIsToday) {
    return (
      <div
        className={`
        w-8 h-8 m-2 p-1 rounded-full font-bold text-white bg-purple-500 flex justify-center items-center
        `}
      >
        {day.getDate()}
      </div>
    );
  } else {
    const dayColor = otherMonth ? "text-gray-400" : "text-gray-600";

    return (
      <p className={`w-8 h-8 m-2 p-1 rounded-full ${dayColor}`}>
        {day.getDate()}
      </p>
    );
  }
}

export default DayNumber;
