import React from "react";
import SelectSearch from "react-select-search";
import { connect } from "react-redux";
import { Button } from "@material-ui/core";
import Search from "@material-ui/icons/Search";

import { updateDeliveryParams } from "../../../store/actions";
import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem";
import DateRangeSelector from "../../Shared/DateRange";

class DeliveriesSearch extends React.Component {
  constructor(props) {
    super(props);
    const providers = props.accounts.filter(
      account => account.type === "provider"
    );
    const providerOptions = providers.map(provider => ({
      name: provider.name,
      value: provider.id
    }));
    const queryParams = props.queryParams;
    this.state = {
      providerOptions,
      accountId: queryParams.accountId,
      startDate: queryParams.startDate,
      endDate: queryParams.endDate
    };
  }

  changeDate = (fieldName, value) => {
    this.setState({ [fieldName]: value }, this.loadData);
  };

  onResourceSelect = provider => {
    this.setState({ accountId: provider.value }, this.loadData);
  };

  submitForm = () => {
    this.props.updateParams(this.state);
  };

  render() {
    return (
      <GridContainer style={{ margin: "1em" }}>
        <GridItem xs={12} style={{ zIndex: "99", marginTop: "1em" }}>
          <DateRangeSelector
            startDate={this.state.startDate}
            endDate={this.state.endDate}
            dateChange={this.changeDate}
          />
        </GridItem>
        <GridItem xs={12} sm={6} style={{ marginTop: "1em" }}>
          <legend>Selecciona un proveedor</legend>
          <SelectSearch
            options={this.state.providerOptions}
            onChange={this.onSelect}
            value={this.state.accountId}
            name="account"
            placeholder="Selecciona un proveedor"
          />
        </GridItem>
        <GridItem
          container
          justify="flex-end"
          xs={12}
          style={{ margin: "1em" }}
        >
          <Button variant="contained" color="primary" onClick={this.submitForm}>
            <Search /> Buscar
          </Button>
        </GridItem>
      </GridContainer>
    );
  }
}

const mapStateToProps = state => ({
  accounts: state.mainData.accounts,
  queryParams: state.mainData.deliveryQuery
});

const mapDispatchToProps = dispatch => ({
  updateParams: params => dispatch(updateDeliveryParams(params))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeliveriesSearch);
