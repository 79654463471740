import DashboardIcon from "@material-ui/icons/Dashboard";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import ViewCarouselIcon from "@material-ui/icons/ViewCarousel";
import StorageIcon from "@material-ui/icons/Storage";
import CategoryIcon from "@material-ui/icons/Category";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import ProvidersIcon from "@material-ui/icons/PregnantWoman";
import ExitIcon from "@material-ui/icons/ExitToAppRounded";
import DeliveryIcon from "@material-ui/icons/ShoppingBasket";
import SalesIcon from "@material-ui/icons/ShoppingCart";
import ReportsIcon from "@material-ui/icons/ListAlt";

import Calendar from "../../views/Calendar";
import ListReservations from "../../views/Calendar/List";
import Slots from "../../views/Admin/Slots";
import Resources from "../../views/Admin/Resources";
import Users from "../../views/Admin/Users";
import AdminReservations from "../../views/Admin/Reservations";
import AdminCategories from "../../views/Admin/Categories";
import AdminProviders from "../../views/Admin/Providers";
import AdminAccounting from "../../views/Admin/Accounting";
import Logout from "../../views/Public/Logout";
import LogEntries from "../../views/LogEntries";
import AdminDeliveries from "../../views/Admin/Deliveries";
import Sales from "../../views/Sales";
import Reports from "../../views/Reports";

const sidebarRoutes = [
  {
    path: "/reservas",
    pathTo: "/reservas/:date?",
    name: "Calendario",
    icon: CalendarTodayIcon,
    component: Calendar
  },
  {
    path: "/reservas/listado",
    pathTo: "/reservas/listado/:date?",
    name: "Listado de reservas",
    icon: StorageIcon,
    component: ListReservations
  },
  {
    path: "/contabilidad",
    pathTo: "/contabilidad",
    name: "Contabilidad",
    icon: AccountBalanceIcon,
    component: LogEntries
  },
  {
    path: "/consumos",
    pathTo: "/consumos",
    name: "Consumos",
    icon: SalesIcon,
    component: Sales
  },
  {
    path: "/informes",
    pathTo: "/informes",
    name: "Informes",
    icon: ReportsIcon,
    component: Reports
  },
  {
    path: "/logout",
    pathTo: "/logout",
    name: "Salir",
    icon: ExitIcon,
    component: Logout
  }
];

const managerRoutes = [
  {
    path: "/admin/categorias",
    pathTo: "/admin/categorias/:id?",
    name: "Categorías",
    icon: CategoryIcon,
    component: AdminCategories
  },
  {
    path: "/admin/pedidos",
    pathTo: "/admin/pedidos/:id?",
    name: "Pedidos",
    icon: DeliveryIcon,
    component: AdminDeliveries
  }
];

const adminRoutes = [
  {
    path: "/admin/reservas",
    pathTo: "/admin/reservas",
    name: "Reservas",
    icon: StorageIcon,
    component: AdminReservations
  },
  {
    path: "/admin/actividades",
    pathTo: "/admin/actividades",
    name: "Actividades",
    icon: DashboardIcon,
    component: Slots
  },
  {
    path: "/admin/recursos",
    pathTo: "/admin/recursos",
    name: "Recursos",
    icon: ViewCarouselIcon,
    component: Resources
  },
  {
    path: "/admin/usuarios",
    pathTo: "/admin/usuarios",
    name: "Usuarios",
    icon: SupervisorAccountIcon,
    component: Users
  },
  {
    path: "/admin/proveedores",
    pathTo: "/admin/proveedores",
    name: "Proveedores",
    icon: ProvidersIcon,
    component: AdminProviders
  },
  {
    path: "/admin/contabilidad",
    pathTo: "/admin/contabilidad",
    name: "Contabilidad",
    icon: AccountBalanceIcon,
    component: AdminAccounting
  }
];

export { sidebarRoutes, adminRoutes, managerRoutes };
