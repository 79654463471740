import React from "react";;

const ErrorMessageDialog = ({ message }) => (
  <h3>
    { message
      ? message
      : 'Esto se ha roto! Que has tocado?  Ya verás cuando se entere Jorge.' }
  </h3>
);

export default ErrorMessageDialog;
