import React from "react";

import DeleteDialog from "../../Shared/Dialogs/Delete";
import { Products } from "../../../lib/database/";
import { Storage } from "../../../config/firebase";

const removeProducts = (item, onClose) => {
  Products.doc(item.id)
    .delete()
    .then(() => {
      if (item.image) {
        const fileRef = Storage.ref().child(item.image);
        fileRef.delete().then(() => {
          // warn the user
        });
      }
    });

  onClose();
};

const AdminDeleteProduct = ({ onClose, item, open }) => (
  <DeleteDialog
    title="Borrar producto"
    onClose={onClose}
    item={item}
    open={open}
    onDelete={removedItem => removeProducts(removedItem, onClose)}
  />
);

export default AdminDeleteProduct;
