import React from "react";
import { css } from "@emotion/core";
import { Link } from "react-router-dom";
import AddIcon from "@material-ui/icons/Add";
import withStyles from "@material-ui/core/styles/withStyles";

import SharedMessage from "../../Shared/Message";
import AdminCategoriesListItem from "./ListItem";
import AdminCategoriesNew from "./New";
import {
  findParentTitleById,
  countSubcategories,
  findSubcategories
} from "../../../lib/categories/presenter";
import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem";
import Button from "../../../components/CustomButtons/Button";
import { cardTitle } from "../../../assets/jss/material-dashboard-pro-react";

import AdminProducts from "../Products";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  }
};

class AdminCategoriesList extends React.Component {
  state = {
    showEdit: false,
    showNew: false,
    showDelete: false,
    activeCategory: {}
  };

  toggleModal = () => {
    this.setState({ showNew: !this.state.showNew });
  };

  showCategories = classes => {
    const subcategories = findSubcategories(
      this.props.parentId,
      this.props.categories
    );

    return subcategories.map(category => (
      <AdminCategoriesListItem
        key={category.id}
        category={category}
        classes={classes}
        count={countSubcategories(category.id, this.props.categories)}
      />
    ));
  };

  render() {
    const { classes, categories, parentId } = this.props;
    const parentName = findParentTitleById(parentId, categories);

    return (
      <GridContainer>
        <GridItem xs={12} lg={12}>
          {parentId !== "0" && (
            <Link to="/admin/categorias">
              <SharedMessage message="Inicio" />
            </Link>
          )}
          <SharedMessage message={parentName} />
        </GridItem>
        <GridItem xs={12} lg={12}>
          <GridContainer>
            {categories.length > 0 ? (
              this.showCategories(classes)
            ) : (
              <SharedMessage message="Todavía no hay creada ninguna categoría." />
            )}
          </GridContainer>
        </GridItem>
        <GridItem
          xs={12}
          lg={12}
          className={css`
            margin-top: 1em;
          `}
        >
          <Button
            round
            color="linkedin"
            aria-label="Nueva subcategoría"
            onClick={() => this.toggleModal()}
          >
            <AddIcon /> Nueva subcategoría
          </Button>

          {this.state.showNew && (
            <AdminCategoriesNew
              parentId={parentId}
              toggleModal={() => this.toggleModal()}
            />
          )}
        </GridItem>
        <GridItem xs={12} lg={12}>
          <AdminProducts categoryId={parentId} />
        </GridItem>
      </GridContainer>
    );
  }
}

export default withStyles(styles)(AdminCategoriesList);
