import React, { useEffect, useState } from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";

import Button from "../../../components/CustomButtons/Button";
import { orderResults } from "../../../lib/utils/results";
import { FirebaseResults, Users } from "../../../lib/database/";

const ListUsers = ({ onEdit, onDelete }) => {
  const [localState, setLocalState] = useState({
    loading: false,
    users: []
  });

  useEffect(() => {
    setLocalState({ loading: true, users: [] });
    return Users.orderBy("name").onSnapshot(docs => {
      setLocalState({ users: FirebaseResults.map(docs), loading: false });
    });
  }, []);

  const renderItems = () => {
    const userList = localState.users;
    if (userList.length === 0) {
      return [<p key="1">Nada</p>];
    }

    const users = orderResults(userList, "name");
    return users.map(user => (
      <tr className={user.active ? "" : "bg-gray-200"}>
        <td className="px-2">{user.name}</td>
        <td>{user.role}</td>
        <td>{user.active ? "Si" : "No"}</td>
        <td>{user.code ? "****" : "-No-"}</td>
        <td>
          {
            <Button
              key="edit"
              color="success"
              simple
              aria-label="Edit"
              onClick={() => onEdit(user)}
            >
              <EditIcon />
            </Button>
          }

          {
            <Button
              key="delete"
              color="danger"
              simple
              aria-label="Delete"
              onClick={() => onDelete(user)}
            >
              <DeleteIcon />
            </Button>
          }
        </td>
      </tr>
    ));
  };

  if (localState.loading) {
    return <div>Loading...</div>;
  }

  return (
    <table className="table-auto w-full text-gray-900">
      <thead className="font-semibold">
        <tr>
          <td>Nombre</td>
          <td>Tipo</td>
          <td>Activo</td>
          <td>Código</td>
          <td>Opciones</td>
        </tr>
      </thead>
      <tbody>{renderItems()}</tbody>
    </table>
  );
};

export default ListUsers;
