import React from "react";
import styled from "@emotion/styled";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

const FormContainer = styled("div")`
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 1em;
`;

const UserForm = ({
  name,
  email,
  password,
  role,
  errorName,
  errorEmail,
  enabled,
  onChange
}) => (
  <FormContainer>
    <TextField
      error={errorName}
      name="name"
      id="name"
      label="Nombre"
      value={name}
      onChange={onChange}
      helperText="Nombre del usuario"
    />
    <TextField
      error={errorEmail}
      name="email"
      id="email"
      label="Email"
      type="email"
      value={email}
      onChange={onChange}
      helperText="Email del usuario"
    />
    <TextField
      error={errorName}
      name="password"
      id="password"
      label="Contraseña"
      value={password}
      onChange={onChange}
      helperText="Contraseña (mínimo 6 caracteres)."
    />
    <TextField
      name="role"
      id="role"
      label="Role"
      value={role}
      onChange={onChange}
      helperText="Tipo de usuario"
    />
    <FormControlLabel
      control={
        <Checkbox
          checked={enabled}
          name="enabled"
          onChange={onChange}
          value={enabled ? "false" : "true"}
        />
      }
      label="Usuario activo?"
    />
  </FormContainer>
);

export default UserForm;
