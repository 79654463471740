import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import ResourceForm from "./form";
import { Resources } from "../../../lib/database/";

class EditResource extends React.Component {
  state = {
    id: "",
    name: "",
    seats: 0,
    oldId: "",
    available: true
  };

  onChange = event => {
    if (event.target.name === "available") {
      this.setState({ available: event.target.value === "true" });
    } else {
      this.setState({ [event.target.name]: event.target.value });
    }
  };

  componentWillReceiveProps = nextProps => {
    if (!nextProps.resource) {
      return false;
    }

    if (nextProps.resource.name !== this.props.resource.name) {
      this.setState({
        name: nextProps.resource.name,
        seats: nextProps.resource.seats,
        available: nextProps.resource.available
      });
    } else {
      this.setState({
        name: this.props.resource.name,
        seats: this.props.resource.seats,
        available: this.props.resource.available,
        oldId: this.state.oldId
      });
    }
  };

  onSubmit = event => {
    event.preventDefault();
    if (!this.state.name || this.state.name.trim().length === 0) {
      return false;
    }

    Resources.doc(this.props.resource.id).update({
      name: this.state.name,
      seats: parseInt(this.state.seats),
      available: this.state.available
    });
    this.props.onClose();
  };

  render() {
    const { open, onClose } = this.props;
    const { name, seats, available } = this.state;
    return (
      <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Modificar recurso</DialogTitle>
        <DialogContent>
          <ResourceForm
            name={name}
            seats={seats}
            available={available}
            onChange={this.onChange}
            errorName={false}
            errorSeats={false}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" onClick={this.onSubmit}>
            Guardar
          </Button>
          <Button onClick={this.props.onClose}>Cancelar</Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default EditResource;
