import React, { useState } from "react";

import AdminProductsEdit from "./Edit";
import AdminDeleteProduct from "./Delete";
import AdminProductsItemOptions from "./ItemOptions";

function AdminProductsListItems({ products }) {
  const [listItems, setListItems] = useState({
    showEdit: false,
    showDelete: false,
    activeProduct: {}
  });

  function toggleModal(name, activeProduct = {}) {
    switch (name) {
      case "edit":
        setListItems({
          ...listItems,
          showEdit: !listItems.showEdit,
          activeProduct
        });
        break;
      case "delete":
        setListItems({
          ...listItems,
          showDelete: !listItems.showDelete,
          activeProduct
        });
        break;
      default: {
        //
      }
    }
  }

  return (
    <div>
      <table className="table-auto w-full">
        <thead>
          <tr>
            <th className="p-4 text-gray-700 bg-gray-100 uppercase tracking-wider">
              Nombre
            </th>
            <th className="p-4 text-gray-700 bg-gray-100 uppercase tracking-wider">
              Stock
            </th>
            <th className="p-4 text-gray-700 bg-gray-100 uppercase tracking-wider">
              Precio compra
            </th>
            <th className="p-4 text-gray-700 bg-gray-100 uppercase tracking-wider">
              Precio venta
            </th>
            <th className="p-4 text-gray-700 bg-gray-100 uppercase tracking-wider">
              I.V.A.
            </th>
            <th className="p-4 text-gray-700 bg-gray-100 uppercase tracking-wider">
              Disponible?
            </th>
            <th className="p-4 text-gray-700 bg-gray-100 uppercase tracking-wider">
              Opciones
            </th>
          </tr>
        </thead>
        <tbody>
          {products.map(function(product, index) {
            return [
              <tr key={index} className="text-md text-gray-800">
                <td className="px-2">
                  <a href={`/admin/producto/${product.id}`}>{product.title}</a>
                </td>
                <td className="px-2">{product.stockAmount}</td>
                <td className="px-2">{product.buyPrice}</td>
                <td className="px-2">{product.sellPrice}</td>
                <td className="px-2">{product.vat}</td>
                <td className="px-2">{product.active ? "Si" : "No"}</td>
                <AdminProductsItemOptions
                  product={product}
                  toggleModal={toggleModal}
                />
              </tr>
            ];
          })}
        </tbody>
      </table>
      {listItems.showEdit && (
        <AdminProductsEdit
          toggleModal={() => toggleModal("edit")}
          activeProduct={listItems.activeProduct}
        />
      )}
      {listItems.showDelete && (
        <AdminDeleteProduct
          onClose={() => toggleModal("delete")}
          item={listItems.activeProduct}
          open
        />
      )}
    </div>
  );
}

export default AdminProductsListItems;
