import React, { useState } from "react";

import { findParentCategories } from "../../lib/categories/finder";

const TicketsCategoryTabs = ({ categories, filterCategory }) => {
  const [selectedId, setSelected] = useState("0");
  const filterCategoryIds = categoryId => {
    let categoryIds = new Set();
    if (categoryId !== "0") {
      categories.forEach(category => {
        let currentCategory = category;
        if (category.parentId !== "0") {
          currentCategory = findParentCategories(categories, category.parentId);
        }

        if (currentCategory.id === categoryId) {
          categoryIds.add(category.id);
        }
      });
    }
    setSelected(categoryId);
    filterCategory([...categoryIds]);
  };

  const rootCategories = categories.filter(
    category => category.parentId === "0"
  );

  const buttonColor = categoryId =>
    selectedId === categoryId ? "bg-pink-600" : "bg-purple-600";

  return (
    <div className="flex flex-wrap">
      <div className="w-1/3 p-2">
        <button
          className={
            "py-3 w-full rounded shadow text-white text-xl " + buttonColor("0")
          }
          onClick={() => filterCategoryIds("0")}
        >
          Todas
        </button>
      </div>
      {rootCategories.map((category, index) => (
        <div key={index} className="w-1/3 p-2">
          <button
            className={
              "py-3 w-full rounded shadow text-white text-xl " +
              buttonColor(category.id)
            }
            onClick={() => filterCategoryIds(category.id)}
          >
            {category.title}
          </button>
        </div>
      ))}
    </div>
  );
};

export default TicketsCategoryTabs;
