import React from "react";
import Dialog from "@material-ui/core/Dialog";

import AdminAccountsForm from "./Form";
import { Accounts } from "../../../../lib/database";

const toggleEdit = toggleModal => {
  toggleModal("edit");
};

const onSubmit = (account, toggleModal) => {
  Accounts.doc(account.id).update({
    name: account.name,
    tags: account.tags,
    active: account.active
  });
  toggleModal("edit");
};

const AdminAccountsEdit = ({ activeAccount, toggleModal }) => {
  return (
    <Dialog open>
      <AdminAccountsForm
        activeAccount={activeAccount}
        toggleModal={() => toggleEdit(toggleModal)}
        submitData={info => onSubmit(info, toggleModal)}
      />
    </Dialog>
  );
};

export default AdminAccountsEdit;
