import React from "react";
import Pay from "@material-ui/icons/EuroSymbol";
import Basket from "@material-ui/icons/ShoppingBasket";

import CustomCardHeader from "../../Shared/CustomCardHeader";
import { formatFloatToFixed } from "../../../lib/utils/format";

const SalesCategoriesHeader = ({ quantity, total }) => {
  return (
    <div className="m-1 flex">
      <CustomCardHeader
        icon={<Basket />}
        title="Cantidad consumida"
        amount={quantity}
      />
      <CustomCardHeader
        icon={<Pay />}
        title="Importe Total"
        amount={formatFloatToFixed(total)}
      />
    </div>
  );
};

export default SalesCategoriesHeader;
