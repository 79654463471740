import React from "react";
import DeleteIcon from "@material-ui/icons/Delete";

const BasketItem = ({ product, currentProduct, onUpdate, onRemove }) => {
  const selectProduct = () => {
    onUpdate(product, product.quantity);
  };

  const removeProduct = () => {
    onRemove(product);
  };

  const total = (product.quantity * product.price).toFixed(2);

  return (
    <div
      className={
        "mt-2 px-3 py-2 flex " +
        (currentProduct.productId === product.productId
          ? "bg-purple-100 text-purple-700"
          : "bg-gray-100 ")
      }
    >
      <p onClick={selectProduct} className="w-3/4">
        {`${product.quantity} ${product.title} ${product.price}`}
      </p>
      <div className="w-1/4 text-right flex justify-end">
        <p>{total}</p>
        <button className="ml-2 text-red-600" onClick={removeProduct}>
          <DeleteIcon />
        </button>
      </div>
    </div>
  );
};

export default BasketItem;
