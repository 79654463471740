import React from "react";

import DeleteDialog from "../../Shared/Dialogs/Delete";

const DELETE_RESOURCE = `
  mutation DeleteResource($input: DeleteResourceInput!) {
    deleteResource(input: $input) {
      id
    }
  }
`;

const DeleteResource = ({ onClose, item, open }) => (
  <DeleteDialog
    title="Borrar recurso"
    onClose={ onClose }
    item={ item }
    mutationQuery={ DELETE_RESOURCE }
    open={ open }
  />
);

export default DeleteResource;
