import { firstAndLastDayOfMonth } from "../../lib/dates/calendar";

const [firstDayMonth, lastDayMonth] = firstAndLastDayOfMonth(new Date());
const defaultParams = {
  startDate: firstDayMonth,
  endDate: lastDayMonth,
  status: "all",
  accountId: ""
};

const reservationsQuery = (state = defaultParams, action) => {
  switch (action.type) {
    case "UPDATE_RESERVATION_PARAMS":
      return action.params;
    default:
      return state;
  }
};

export { reservationsQuery };
