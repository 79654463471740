import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

import AdminLogEntries from "./LogEntries";
import AdminMonthlyFee from "./MonthlyFee";
import AdminAccounts from "./Accounts";
import AdminReports from "../Reports";

class AdminAccounting extends React.Component {
  state = { selectedTab: 0 };

  handleChange = (_event, value) => {
    this.setState({ selectedTab: value });
  };

  render() {
    const { selectedTab } = this.state;
    return (
      <div className="mt-1">
        <AppBar position="static">
          <Tabs value={selectedTab} onChange={this.handleChange}>
            <Tab label="Apuntes" />
            <Tab label="Generar cuotas mensuales" />
            <Tab label="Cuentas" />
            <Tab label="Generador informes" />
          </Tabs>
        </AppBar>
        {selectedTab === 0 && <AdminLogEntries />}
        {selectedTab === 1 && <AdminMonthlyFee />}
        {selectedTab === 2 && <AdminAccounts />}
        {selectedTab === 3 && <AdminReports />}
      </div>
    );
  }
}

export default AdminAccounting;
