import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { AppBar, Tabs, Tab } from "@material-ui/core";

import SalesCategories from "./Categories";
import SalesProducts from "./Products";
import { FirebaseResults, Products } from "../../lib/database/";
import LoadingDialog from "../Shared/Dialogs/Loading";

const SalesContent = styled("div")`
  margin-top: 5px;
`;

const Sales = () => {
  const [selectedTab, setSelected] = useState(0);
  const [products, setProducts] = useState([]);

  useEffect(() => {
    Products.get().then(docs => {
      setProducts(FirebaseResults.map(docs));
    });
  }, []);

  const handleChange = (_event, value) => {
    setSelected(value);
  };

  if (products.length === 0) {
    return <LoadingDialog />;
  }

  return (
    <SalesContent>
      <AppBar position="static">
        <Tabs value={selectedTab} onChange={handleChange}>
          <Tab label="Categorías de productos" />
          <Tab label="Productos" />
        </Tabs>
      </AppBar>
      {selectedTab === 0 && <SalesCategories products={products} />}
      {selectedTab === 1 && <SalesProducts products={products} />}
    </SalesContent>
  );
};

export default Sales;
