import React from "react";
import { Switch, Route } from "react-router";
import Tickets from "../Tickets";
import CalendarLoader from "./Loader";

const Calendar = () => (
  <Switch>
    <Route path="/reservas/ticket/:id?" component={Tickets} />
    <Route path="/reservas" component={CalendarLoader} />
  </Switch>
);

export default Calendar;
