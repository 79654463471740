import React, { useState } from "react";
import AdminLogEntriesAccountForm from "./AccountForm";
import AdminLogEntriesAccountEntry from "./AccountEntry";

function AdminLogEntriesAccounts({ logEntry, accountOptions, updateEntry }) {
  const [currentAccount, setCurrentAccount] = useState({});

  function selectEntry(selectedAccount) {
    setCurrentAccount(selectedAccount);
  }

  function removeEntry(selectedAccount) {
    updateEntry({ ...selectedAccount, type: "delete" });
  }

  return (
    <div className="mx-4">
      <AdminLogEntriesAccountForm
        accountOptions={accountOptions}
        submitAccount={updateEntry}
        currentAccount={currentAccount}
      />
      <AdminLogEntriesAccountEntry
        logEntry={logEntry}
        selectEntry={selectEntry}
        removeEntry={removeEntry}
      />
    </div>
  );
}

export default AdminLogEntriesAccounts;
