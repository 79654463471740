import React from "react";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  RadioGroup,
  FormControlLabel,
  Radio
} from "@material-ui/core";
import Button from "../../components/CustomButtons/Button";
import { formatFloatToFixed } from "../../lib/utils/format";

const TicketsPayment = ({
  showDialog,
  toggleModal,
  confirmPayment,
  netTotal,
  payment,
  onChange
}) => {
  return (
    <Dialog open={showDialog}>
      <DialogTitle>Forma de pago</DialogTitle>
      <DialogContent>
        <RadioGroup
          aria-label="Forma de pago"
          name="payment"
          value={payment}
          onChange={onChange}
          row
        >
          <FormControlLabel value="cash" control={<Radio />} label="Efectivo" />
          <FormControlLabel value="card" control={<Radio />} label="Tarjeta" />
        </RadioGroup>
        <p className="font-bold">
          Total sociedad: {formatFloatToFixed(netTotal)}&euro;
        </p>
      </DialogContent>
      <DialogActions>
        <Button onClick={toggleModal} color="transparent">
          Cerrar
        </Button>
        <Button onClick={confirmPayment} color="linkedin">
          Guardar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TicketsPayment;
