const getTables = (bookingResourceItems, resources) =>
  bookingResourceItems.map(resourceItem => {
    const resourceElement = resources.find(
      resource => resource.id === resourceItem.resourceId
    );

    return {
      resourceId: resourceItem.resourceId,
      seats: resourceItem.seats,
      name: resourceElement.name ? resourceElement.name : "Error"
    };
  });

const selectTable = (tables, resource, seats, pendingSeats) => {
  const newTables = Array.from(tables);
  const selectedTable = newTables.find(
    table => table.resourceId === resource.id
  );

  if (selectedTable) {
    const index = newTables.indexOf(selectedTable);
    if (index !== -1) {
      newTables.splice(index, 1);
    }
  } else if (pendingSeats > 0) {
    newTables.push({
      resourceId: resource.id,
      seats,
      name: resource.name
    });
  }

  return newTables;
};

const mapTableToResourceItems = tables =>
  tables.map(table => {
    return { resourceId: table.resourceId, seats: table.seats };
  });

export { selectTable, mapTableToResourceItems, getTables };
