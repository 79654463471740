import React from "react";

import SalesCategoriesSearch from "./Search";
import SalesItems from "../Items";

const SalesCategoriesList = ({ sales, queryParams, categories }) => (
  <React.Fragment>
    <SalesCategoriesSearch queryParams={queryParams} categories={categories} />
    <SalesItems sales={sales} />
  </React.Fragment>
);

export default SalesCategoriesList;
