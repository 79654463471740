import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import { filterProducts } from "../../lib/products/finder";
import { BasketPersistence } from "../../lib/basket/persistence";
import TicketsList from "./List";
import TicketsBasket from "./Basket";
import TicketsHeader from "./Header";
import { updateLayoutId } from "../../store/actions";
import { BookingsPersistence } from "../../lib/bookings/persistence";

class TicketsForm extends React.Component {
  constructor(props) {
    super(props);
    let currentProduct = {
      productId: "",
      quantity: 0,
      title: "",
      price: 0,
      isExtra: false
    };

    if (props.basket && props.basket.length > 0) {
      currentProduct = {
        productId: props.basket[0].productId,
        quantity: props.basket[0].quantity,
        title: props.basket[0].title,
        price: props.basket[0].price,
        isExtra: props.basket[0].isExtra
      };
    }

    this.state = {
      searchTerm: "",
      categoryIds: [],
      results: props.products,
      basket: props.basket || [],
      currentProduct
    };
    this.inputElement = null;
  }

  confirmPayment = payment => {
    this.props.confirmBooking(this.state.basket, payment);
  };

  addProductToBasket = product => {
    const newBasket = BasketPersistence(this.state.basket).add(product);
    const currentProduct = this.setCurrentProduct(newBasket, product.id);
    this.setState({ basket: newBasket, currentProduct });
    if (this.inputElement) {
      this.inputElement.focus();
    }
  };

  filterCategory = categoryIds => {
    this.setState({ categoryIds, searchTerm: "" }, this.filterProducts);
  };

  filterProducts = () => {
    const results = filterProducts(
      this.props.products,
      this.state.searchTerm,
      this.state.categoryIds
    );
    this.setState({ results });
  };

  setCurrentProduct = (basket, productId) => {
    const productIndex = basket.findIndex(
      element => productId === element.productId
    );

    if (productIndex > -1) {
      return basket[productIndex];
    } else {
      return this.state.currentProduct;
    }
  };

  removeProduct = item => {
    const newBasket = BasketPersistence(this.state.basket).remove(
      item.productId
    );
    BookingsPersistence.updateBasket(this.props.bookingId, newBasket, "cash");
    let currentProduct = this.state.currentProduct;
    if (this.state.currentProduct.productId === item.productId) {
      if (newBasket.length > 0) {
        currentProduct = this.setCurrentProduct(
          newBasket,
          newBasket[0].productId
        );
      } else {
        currentProduct = {
          productId: "",
          quantity: 0,
          title: "",
          price: 0,
          isExtra: false
        };
      }
    }
    this.setState({ basket: newBasket, currentProduct });
  };

  updateProduct = (item, quantity) => {
    let currentProduct = this.state.currentProduct;
    const newBasket = BasketPersistence(this.state.basket).update(
      item,
      quantity
    );
    currentProduct = this.setCurrentProduct(newBasket, item.productId);
    BookingsPersistence.updateBasket(this.props.bookingId, newBasket, "cash");
    this.setState({ basket: newBasket, currentProduct });
    if (this.inputElement) {
      this.inputElement.focus();
    }
  };

  updateSearch = event => {
    const newValue = event.target.value;

    this.setState({ searchTerm: newValue }, this.filterProducts);
  };

  navigateAway = () => {
    this.props.updateLayoutId({ id: "defaultApp" });
    this.props.history.push("/reservas");
  };

  render() {
    const { results, basket } = this.state;

    return (
      <div className="text-gray-900">
        <TicketsHeader
          basket={basket}
          navigateAway={this.navigateAway}
          confirmPayment={this.confirmPayment}
          categories={this.props.categories}
          filterCategory={this.filterCategory}
        />
        <div className="mt-4 flex">
          <TicketsList
            products={results}
            onSelect={this.addProductToBasket}
            onUpdate={this.updateProduct}
          />
          <TicketsBasket
            basket={basket}
            onUpdate={this.updateProduct}
            onRemove={this.removeProduct}
            currentProduct={this.state.currentProduct}
            updateSearch={this.updateSearch}
            searchTerm={this.state.searchTerm}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  ...state
});

const mapDispatchToProps = dispatch => ({
  updateLayoutId: newId => dispatch(updateLayoutId(newId))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(TicketsForm));
