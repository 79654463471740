import React from "react";
import { connect } from "react-redux";
import SelectSearch from "react-select-search";
import Search from "@material-ui/icons/Search";

import { updateLogEntryParams } from "../../store/actions";
import CustomInput from "../../components/CustomInput/CustomInput";
import DateRangeSelector from "./DateRange";
import "../../assets/css/select-search.css";

class LogEntriesSearch extends React.Component {
  constructor(props) {
    super(props);
    const accountOptions = props.accounts.map(account => ({
      name: account.name,
      value: account.id
    }));
    accountOptions.unshift({ name: "** Todos **", value: "" });
    const queryParams = props.queryParams;

    this.state = {
      accountOptions,
      statusOptions: ["approved", "pending", "all"],
      tags: queryParams.tags,
      approved: queryParams.approved,
      accountId: queryParams.accountId,
      startDate: queryParams.startDate,
      endDate: queryParams.endDate
    };
  }

  onChange = event => {
    this.setState({ tags: event.target.value });
  };

  changeDate = (fieldName, value) => {
    this.setState({ [fieldName]: value });
  };

  approvedChange = (_event, value) => {
    this.setState({ approved: this.state.statusOptions[value] });
  };

  onResourceSelect = account => {
    this.setState({ accountId: account.value });
  };

  submitForm = () => {
    const queryParams = {
      approved: this.state.approved,
      accountId: this.state.accountId,
      tags: this.state.tags,
      startDate: this.state.startDate,
      endDate: this.state.endDate
    };
    this.props.updateParams(queryParams);
  };

  render() {
    return (
      <div className="p-4 min-h-full w-full bg-gray-100">
        <div className="flex flex-col md:flex-row">
          <div className="w-1/2 md:1/3">
            <DateRangeSelector
              startDate={this.state.startDate}
              endDate={this.state.endDate}
              dateChange={this.changeDate}
            />
          </div>
          <div className="w-1/2 md:w-1/4">
            <label className="block">Etiqueta</label>
            <CustomInput
              labelText="Etiqueta"
              inputProps={{
                id: "tags",
                name: "tags",
                onChange: this.onChange,
                value: this.state.tags
              }}
            />
          </div>

          <div className="w-1/2 md:w-1/4">
            <label>Cuenta</label>
            <SelectSearch
              options={this.state.accountOptions}
              onChange={account => this.onResourceSelect(account)}
              value={this.state.accountId}
              name="account"
              placeholder="Selecciona una cuenta"
            />
          </div>
          <div className="w-1/2 md:w-1/4">
            <button className="btn primary" onClick={this.submitForm}>
              <Search /> Buscar
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  accounts: state.mainData.accounts,
  queryParams: state.mainData.logEntryQuery
});

const mapDispatchToProps = dispatch => ({
  updateParams: params => dispatch(updateLogEntryParams(params))
});

export default connect(mapStateToProps, mapDispatchToProps)(LogEntriesSearch);
