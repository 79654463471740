import React from "react";
import { connect } from "react-redux";

import { updateReservationParams } from "../../store/actions";
import GridContainer from "../../components/Grid/GridContainer.jsx";
import GridItem from "../../components/Grid/GridItem.jsx";
import Card from "../../components/Card/Card.jsx";
import CardBody from "../../components/Card/CardBody.jsx";
import Schedule from "./Schedule";
import { firstAndLastDayOfMonth } from "../../lib/dates/calendar";
import { updateMonth } from "../../lib/dates/format";
import LoadingDialog from "../Shared/Dialogs/Loading/index.jsx";
import { FirebaseResults, Bookings } from "../../lib/database/";
import { updateBookings } from "../../store/actions/index.js";

class CalendarLoader extends React.Component {
  unsubscribe = "";

  constructor(props) {
    super(props);

    this.state = {
      showDialog: false,
      defaultDate: props.queryParams.startDate
    };
  }

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.queryParams !== this.props.queryParams) {
      this.setState(
        { defaultDate: this.props.queryParams.startDate },
        this.loadData
      );
    }
  }

  loadData() {
    this.setState({ loading: true });

    let query = Bookings.orderBy("startDate")
      .where("startDate", ">=", this.props.queryParams.startDate)
      .where("startDate", "<=", this.props.queryParams.endDate);

    if (this.props.queryParams.accountId) {
      query = query.where("accountId", "==", this.props.queryParams.accountId);
    }

    if (
      this.props.queryParams.status &&
      this.props.queryParams.status !== "all"
    ) {
      query = query.where("status", "==", this.props.queryParams.status);
    }

    this.unsubscribe = query.onSnapshot(docs => {
      this.props.updateBookings(FirebaseResults.map(docs));
      this.setState({ loading: false });
    });
  }

  componentWillMount = () => {
    if (this.unsubscribe) {
      this.unsubscribe();
    }
  };

  handleClickOpen = defaultDate => {
    this.setState({ defaultDate });
  };

  changeMonth = newMonth => {
    const date = updateMonth(this.props.queryParams.startDate, newMonth);
    const [firstDayMonth, lastDayMonth] = firstAndLastDayOfMonth(date);

    this.props.updateParams({
      startDate: firstDayMonth,
      endDate: lastDayMonth
    });
  };

  render() {
    if (this.state.loading) {
      return <LoadingDialog />;
    }

    return (
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={10}>
          <Card>
            <CardBody calendar>
              <Schedule
                changeMonth={this.changeMonth}
                defaultDate={this.state.defaultDate}
                onSelect={this.handleClickOpen}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

const mapStateToProps = state => ({
  queryParams: state.mainData.reservationsQuery
});

const mapDispatchToProps = dispatch => ({
  updateBookings: bookings => dispatch(updateBookings(bookings)),
  updateParams: params => dispatch(updateReservationParams(params))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CalendarLoader);
