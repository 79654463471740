import React from "react";
import { InputAdornment } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";

import BasketItem from "./BasketItem";
import NumberDialog from "../Shared/Dialogs/NumericPad";
import CustomInput from "../../components/CustomInput/CustomInput";

const TicketsBasket = ({
  basket,
  searchTerm,
  updateSearch,
  currentProduct,
  onUpdate,
  onRemove
}) => {
  const onSubmit = quantity => {
    onUpdate(currentProduct, quantity);
  };

  return (
    <div className="w-1/2 md:w-1/4 overflow-auto">
      <CustomInput
        id="search"
        labelText="Buscador de productos"
        type="search"
        formControlProps={{
          fullWidth: true
        }}
        inputProps={{
          onChange: updateSearch,
          value: searchTerm,
          autoFocus: true,
          endAdornment: (
            <InputAdornment position="end">
              <SearchIcon />
            </InputAdornment>
          )
        }}
      />
      <NumberDialog onSubmit={onSubmit} currentProduct={currentProduct} />
      <h2 className="mt-4 p-2 text-xl text-orange-700 text-center bg-orange-100 rounded-t-lg font-bold">
        Consumo
      </h2>

      <div className="overflow-auto  text-gray-700">
        {basket.map(product => (
          <BasketItem
            product={product}
            key={product.productId}
            currentProduct={currentProduct}
            onUpdate={onUpdate}
            onRemove={onRemove}
          />
        ))}
      </div>
    </div>
  );
};

export default TicketsBasket;
