import React from "react";

import Table from "../../../../components/Table/Table";
import AdminAccountsEdit from "./Edit";
import AdminAccountsItemOptions from "./ItemOptions";

class AdminAccountsListItems extends React.Component {
  state = {
    showEdit: false,
    showDelete: false,
    activeAccount: {}
  };

  toggleModal = (name, activeAccount = {}) => {
    switch (name) {
      case "edit":
        this.setState({ showEdit: !this.state.showEdit, activeAccount });
        break;
      case "delete":
        this.setState({ showDelete: !this.state.showDelete, activeAccount });
        break;
      default:
        break;
    }
  };

  showAccounts = () => {
    return this.props.accounts.map(account => [
      account.name,
      account.tags.join(", "),
      account.id,
      this.optionsForAccount(account)
    ]);
  };

  optionsForAccount = account => (
    <AdminAccountsItemOptions
      account={account}
      toggleModal={this.toggleModal}
    />
  );

  render() {
    return (
      <div>
        <Table
          striped
          tableHead={["Nombre", "Etiquetas", "Id", "Opciones"]}
          tableData={this.showAccounts()}
        />
        {this.state.showEdit && (
          <AdminAccountsEdit
            toggleModal={() => this.toggleModal("edit")}
            activeAccount={this.state.activeAccount}
          />
        )}
      </div>
    );
  }
}

export default AdminAccountsListItems;
