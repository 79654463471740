const layout = (state = { id: "defaultApp" }, action) => {
  switch (action.type) {
    case "UPDATE_LAYOUT_ID":
      return action.layoutInfo;
    default:
      return state;
  }
};

export { layout };
