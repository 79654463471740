import { firstAndLastDayOfMonth } from "../../lib/dates/calendar";

const [firstDayMonth, lastDayMonth] = firstAndLastDayOfMonth(new Date());
const defaultParams = {
  categoryId: "all",
  startDate: firstDayMonth,
  endDate: lastDayMonth
};

const salesQuery = (state = defaultParams, action) => {
  switch (action.type) {
    case "UPDATE_SALES_QUERY":
      return action.params;
    default:
      return state;
  }
};

export { salesQuery };
