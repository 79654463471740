import * as React from "react";
import {
  Link,
  withStyles,
  Card,
  CardContent,
  Typography
} from "@material-ui/core";

import ShowImage from "../../../Shared/ShowImage";

const styles = {
  card: {
    maxWidth: "100%"
  },
  media: {
    display: "inline-block",
    height: 140
  },
  title: {
    display: "inline-block"
  }
};

const ProductHeader = ({ product, classes }) => {
  return (
    <Card className={classes.card}>
      <CardContent>
        <ShowImage product={product} className={classes.media} />
        <Typography
          gutterBottom
          variant="h5"
          component="h2"
          className={classes.title}
        >
          {product.title} |{" "}
          <Link href={`/admin/categorias/${product.categoryId}`}>
            Volver a la categoría
          </Link>
        </Typography>
      </CardContent>
    </Card>
  );
};

export default withStyles(styles)(ProductHeader);
