function AccountsAmount(accountsEntry) {
  const accounts = accountsEntry || [];

  function total() {
    return accounts.reduce(function(total, account) {
      if (account.type === "debit") {
        return total + account.total;
      } else {
        return total - account.total;
      }
    }, 0);
  }

  return Object.freeze({ total });
}

export { AccountsAmount };
