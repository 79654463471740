import React from "react";
import { withRouter } from "react-router";
import { firebaseConnect } from "react-redux-firebase";
import { compose } from "redux";
import { connect } from "react-redux";

class Logout extends React.Component {
  componentDidMount = () => {
    this.props.firebase.logout();
    this.props.history.push("/");
  };

  render() {
    return null;
  }
}

export default compose(
  firebaseConnect(),
  connect(({ firebase: { auth } }) => ({ auth }))
)(withRouter(Logout));
