import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Dialog from "@material-ui/core/Dialog";
import withStyles from "@material-ui/core/styles/withStyles";

import AdminReservationForm from "./Form";
import { FirebaseResults, Slots } from "../../../lib/database/";
import modalStyle from "../../../assets/jss/modalStyle";
import LoadingDialog from "../../Shared/Dialogs/Loading";

const AdminNewReservation = ({
  accounts,
  open,
  toggleModal,
  booking,
  operation,
  profile
}) => {
  const [localState, setLocalState] = useState({
    loading: false,
    activeUsers: [],
    slots: []
  });

  useEffect(() => {
    setLocalState({ loading: true, slots: [], activeUsers: [] });

    const activeUsers = accounts.filter(account => account.type === "user");
    return Slots.orderBy("name").onSnapshot(docs => {
      setLocalState({
        slots: FirebaseResults.map(docs),
        activeUsers,
        loading: false
      });
    });
  }, [accounts]);

  if (localState.loading) {
    return <LoadingDialog />;
  }

  return (
    <Dialog open={open}>
      <AdminReservationForm
        operation={operation}
        toggleModal={toggleModal}
        booking={booking}
        slots={localState.slots}
        currentUser={profile.currentUser}
        users={localState.activeUsers}
      />
    </Dialog>
  );
};

export default connect(({ firebase: { profile } }) => ({ profile }))(
  withStyles(modalStyle)(AdminNewReservation)
);
