import React from "react";
import { connect } from "react-redux";
import SelectSearch from "react-select-search";
import { RadioGroup, FormControlLabel, Radio } from "@material-ui/core";
import Search from "@material-ui/icons/Search";

import { updateReservationParams } from "../../../store/actions";
import DateRangeSelector from "../../Shared/DateRange";

class ReservationsSearch extends React.Component {
  constructor(props) {
    super(props);
    const userAccounts = props.accounts.filter(
      account => account.type === "user"
    );
    const userOptions = userAccounts.map(user => ({
      name: user.name,
      value: user.id
    }));
    userOptions.unshift({ name: "** Todos **", value: "" });
    const queryParams = props.queryParams;
    this.state = {
      userOptions,
      accountId: queryParams.accountId,
      startDate: queryParams.startDate,
      endDate: queryParams.endDate,
      status: queryParams.status
    };
  }

  changeDate = (fieldName, value) => {
    this.setState({ [fieldName]: value }, this.loadData);
  };

  onChange = event => {
    const logEntry = { ...this.state.logEntry };
    logEntry[event.target.name] = event.target.value;
    this.setState({ status: event.target.value });
  };

  onSelect = account => {
    this.setState({ accountId: account.value });
  };

  removeUser = () => {
    this.setState({ accountId: null });
  };

  submitForm = () => {
    this.props.updateParams(this.state);
  };

  renderItems = () => {
    const entryValues = [
      { id: "pending", value: "Pendiente" },
      { id: "approved", value: "Aprobada" },
      { id: "all", value: "Todas" }
    ];
    return entryValues.map(entry => (
      <FormControlLabel
        key={entry.id}
        value={entry.id}
        control={<Radio />}
        label={entry.value}
      />
    ));
  };

  render() {
    return (
      <div className="p-4 min-h-full w-full bg-gray-100">
        <div className="flex flex-col md:flex-row">
          <div className="w-1/2 md:1/3">
            <DateRangeSelector
              startDate={this.state.startDate}
              endDate={this.state.endDate}
              dateChange={this.changeDate}
            />
          </div>
          <div className="w-1/2 md:w-1/4">
            <label>Selecciona un socio</label>
            <SelectSearch
              options={this.state.userOptions}
              onChange={this.onSelect}
              value={this.state.accountId}
              name="account"
              placeholder="Selecciona un socio"
            />
          </div>
          <div className="w-1/2 md:w-1/4">
            <RadioGroup
              aria-label="Estado"
              name="status"
              value={this.state.status}
              onChange={this.onChange}
              row
            >
              {this.renderItems()}
            </RadioGroup>
          </div>

          <div className="w-1/2 md:w-1/4">
            <button className="btn primary" onClick={this.submitForm}>
              <Search /> Buscar
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  accounts: state.mainData.accounts,
  queryParams: state.mainData.reservationsQuery
});

const mapDispatchToProps = dispatch => ({
  updateParams: params => dispatch(updateReservationParams(params))
});

export default connect(mapStateToProps, mapDispatchToProps)(ReservationsSearch);
