import React from "react";
import { connect } from "react-redux";

import Layout from "../../layout";
import { Accounts, FirebaseResults, Providers, Resources, Slots, Users } from "../../lib/database/";
import {
  updateActiveUsers,
  updateSlots,
  updateProviders,
  updateResources,
  updateAccounts
} from "../../store/actions";
import LoadingDialog from "../Shared/Dialogs/Loading";

class Admin extends React.Component {
  state = {
    usersLoaded: false,
    providersLoaded: false,
    slotsLoaded: false,
    resourcesLoaded: false
  };
  componentDidMount() {
    Accounts.orderBy("name")
      .get()
      .then(docs => {
        this.props.updateAccounts(FirebaseResults.map(docs));
      });

    Users.orderBy("name")
      .where("active", "==", true)
      .get()
      .then(docs => {
        this.setState({ usersLoaded: true });
        this.props.updateUsers(FirebaseResults.map(docs));
      });

    Providers.orderBy("name")
      .get()
      .then(docs => {
        this.setState({ providersLoaded: true });
        this.props.updateProviders(FirebaseResults.map(docs));
      });

    Slots.orderBy("name")
      .get()
      .then(docs => {
        this.setState({ slotsLoaded: true });
        this.props.updateSlots(FirebaseResults.map(docs));
      });

    Resources.orderBy("name")
      .get()
      .then(docs => {
        this.setState({ resourcesLoaded: true });
        this.props.updateResources(FirebaseResults.map(docs));
      });
  }

  render() {
    const {
      resourcesLoaded,
      slotsLoaded,
      usersLoaded,
      providersLoaded
    } = this.state;
    const { profile, history, layoutId } = this.props;
    if (
      !profile.isLoaded ||
      !resourcesLoaded ||
      !slotsLoaded ||
      !usersLoaded ||
      !providersLoaded
    ) {
      return <LoadingDialog />;
    }

    if (profile.isEmpty) {
      history.push("/login");
    }

    return (
      <div id={layoutId}>
        <Layout currentUser={profile} />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  profile: state.firebase.profile,
  layoutId: state.mainData.layout.id
});

const mapDispatchToProps = dispatch => ({
  updateUsers: users => dispatch(updateActiveUsers(users)),
  updateProviders: providers => dispatch(updateProviders(providers)),
  updateSlots: slots => dispatch(updateSlots(slots)),
  updateResources: resources => dispatch(updateResources(resources)),
  updateAccounts: accounts => dispatch(updateAccounts(accounts))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Admin);
