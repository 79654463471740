import React from "react";
import { connect } from "react-redux";

import AdminDeliveriesForm from "./Form";
import { Deliveries } from "../../../lib/database/";
import { findAccountById } from "../../../lib/accounts/finder";
import { addGlobalMessage } from "../../../store/actions";
import LoadingDialog from "../../Shared/Dialogs/Loading";

class AdminDeliveriesEdit extends React.Component {
  state = {
    delivery: {},
    loading: true
  };
  componentDidMount() {
    const deliveryId = this.props.match.params.id.toString();

    this.setState({ loading: true });
    Deliveries.doc(deliveryId)
      .get()
      .then(doc => {
        const delivery = doc.data();
        delivery.id = deliveryId;
        this.setState({ delivery, loading: false });
      });
  }
  onSubmit = deliveryInfo => {
    const { accounts, addMessage, history } = this.props;
    const deliveryId = deliveryInfo.id;
    deliveryInfo.total = parseFloat(deliveryInfo.total);
    deliveryInfo.approved = false;

    if (deliveryInfo.accountId && deliveryInfo.accountId.trim().length > 0) {
      const account = findAccountById(accounts, deliveryInfo.accountId);
      deliveryInfo.accountName = account.name;
    } else {
      addMessage("Cada pedido debe llevar asociado un proveedor");
      return;
    }

    delete deliveryInfo["id"];

    Deliveries.doc(deliveryId)
      .update(deliveryInfo)
      .then(() => {
        addMessage("Pedido actualizado correctamente");
        history.push("/admin/pedidos");
      })
      .catch(error => {
        addMessage(error.message);
      });
  };

  render() {
    const { accounts } = this.props;
    if (this.state.loading) {
      return <LoadingDialog />;
    }

    return (
      <AdminDeliveriesForm
        delivery={this.state.delivery}
        accounts={accounts}
        submitData={this.onSubmit}
      />
    );
  }
}

const mapStateToProps = state => ({
  accounts: state.mainData.accounts
});

const mapDispatchToProps = dispatch => ({
  addMessage: message => dispatch(addGlobalMessage(message))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminDeliveriesEdit);
