import React from "react";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";

import Button from "../../../../components/CustomButtons/Button";

const AdminLogEntriesItemOptions = ({ logEntry, toggleModal }) => [
  <Button
    key="edit"
    color="success"
    simple
    onClick={() => toggleModal("edit", logEntry)}
    className="p-0"
  >
    <EditIcon />
  </Button>,
  <Button
    key="delete"
    color="danger"
    simple
    onClick={() => toggleModal("delete", logEntry)}
    className="p-0"
  >
    <DeleteIcon />
  </Button>
];

export default AdminLogEntriesItemOptions;
