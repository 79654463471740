import React, { Suspense } from "react";
import classnames from "classnames";
import { withRouter, Switch, Route, Redirect } from "react-router-dom";
import withStyles from "@material-ui/core/styles/withStyles";
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

import { availableRoutes } from "../lib/users/permission";
import Sidebar from "../components/Sidebar/Sidebar";
import Header from "../components/Header/Header";
import appStyle from "../assets/jss/layouts/dashboardStyle";
import image from "../assets/img/sidebar-2.jpg";
import logo from "../assets/img/logo-white.svg";
import AdminProductsShow from "../views/Admin/Products/Show";
import AdminProvidersShow from "../views/Admin/Providers/Show";
import AdminDeliveriesShow from "../views/Admin/Deliveries/Show";
import ReservationsShowHeader from "../views/Reservations/Show";
import LoadingDialog from "../views/Shared/Dialogs/Loading";

const switchRoutes = currentUser => (
  <Switch>
    {availableRoutes(currentUser).map((prop, index) => {
      if (prop.redirect) {
        return <Redirect from={prop.path} to={prop.pathTo} key={index} />;
      }
      if (prop.collapse) {
        if (prop.views) {
          return prop.views.map((view, index2) => {
            return (
              <Route
                path={view.pathTo}
                component={view.component}
                key={index2}
              />
            );
          });
        }
      }
      return (
        <Route path={prop.pathTo} component={prop.component} key={index} />
      );
    })}
    <Route
      path="/reserva/:id"
      component={ReservationsShowHeader}
      key="bookingShow"
    />
    <Route
      path="/admin/producto/:id"
      component={AdminProductsShow}
      key="productShow"
    />
    <Route
      path="/admin/proveedor/:id"
      component={AdminProvidersShow}
      key="providerShow"
    />
    <Route
      path="/admin/pedido/:id"
      component={AdminDeliveriesShow}
      key="deliveriesShow"
    />
  </Switch>
);

let ps;

class Layout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileOpen: false,
      miniActive: false
    };
    this.resizeFunction = this.resizeFunction.bind(this);
    this.mainPanel = null;
  }

  componentDidMount = () => {
    const mainPanel = this.mainPanel || "";
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(mainPanel, {
        suppressScrollX: true,
        suppressScrollY: false
      });
      document.body.style.overflow = "hidden";
    }
    window.addEventListener("resize", this.resizeFunction);
  };

  componentWillUnmount = () => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
    }
    window.removeEventListener("resize", this.resizeFunction);
  };

  componentDidUpdate(prevProps) {
    const mainPanel = this.mainPanel;
    if (prevProps.history.location.pathname !== prevProps.location.pathname) {
      mainPanel.scrollTop = 0;
      if (this.state.mobileOpen) {
        this.setState({ mobileOpen: false });
      }
    }
  }

  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };

  getRoute = () => {
    return this.props.location.pathname !== "/maps/full-screen-maps";
  };

  sidebarMinimize = () => {
    this.setState({ miniActive: !this.state.miniActive });
  };

  resizeFunction = () => {
    if (window.innerWidth >= 960) {
      this.setState({ mobileOpen: false });
    }
  };

  render() {
    const { classes, currentUser } = this.props;

    const mainPanel =
      classes.mainPanel +
      " " +
      classnames({
        [classes.mainPanelSidebarMini]: this.state.miniActive,
        [classes.mainPanelWithPerfectScrollbar]:
          navigator.platform.indexOf("Win") > -1
      });

    return (
      <div className={classes.wrapper}>
        <Sidebar
          routes={availableRoutes(currentUser)}
          logoText={currentUser.name}
          logo={logo}
          image={image}
          handleDrawerToggle={this.handleDrawerToggle}
          open={this.state.mobileOpen}
          color="blue"
          bgColor="black"
          miniActive={this.state.miniActive}
        />
        <div
          id="dpcMainPanel"
          className={mainPanel}
          ref={c => {
            this.mainPanel = c;
          }}
        >
          <Header
            sidebarMinimize={this.sidebarMinimize}
            miniActive={this.state.miniActive}
            routes={availableRoutes(currentUser)}
            handleDrawerToggle={this.handleDrawerToggle}
            currentUser={currentUser}
          />
          <Suspense fallback={LoadingDialog}>
            <div className="m-6 p-4">
              <div className={classes.container}>
                {switchRoutes(currentUser)}
              </div>
            </div>
          </Suspense>
        </div>
      </div>
    );
  }
}

export default withStyles(appStyle)(withRouter(Layout));
