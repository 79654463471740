import React, { useEffect, useState } from "react";

import AdminCategoriesList from "./List";
import LoadingDialog from "../../Shared/Dialogs/Loading";
import { Categories, FirebaseResults } from "../../../lib/database/";

const AdminCategoriesFetch = ({ parentId }) => {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    return Categories.orderBy("title").onSnapshot(docs => {
      setCategories(FirebaseResults.map(docs));
    });
  }, []);

  if (categories.length === 0) {
    return <LoadingDialog />;
  }

  return <AdminCategoriesList parentId={parentId} categories={categories} />;
};

export default AdminCategoriesFetch;
