import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import TicketsForm from "./Form";
import { canEditBooking } from "../../lib/users/permission";
import ErrorMessageDialog from "../Shared/Dialogs/ErrorMessage";
import { initializeBasket } from "../../lib/products/basket";
import {
  Bookings,
  Categories,
  FirebaseResults,
  Products
} from "../../lib/database/";
import { findAccountById } from "../../lib/accounts/finder";

import { BookingsPersistence } from "../../lib/bookings/persistence";

class TicketsFetch extends React.Component {
  state = {
    products: [],
    categories: [],
    booking: {},
    accounts: {},
    seats: 0,
    accountId: "",
    status: "pending",
    basket: [],
    loading: false
  };

  closeProducts = null;
  closeCategories = null;

  componentDidMount = () => {
    this.setState({ loading: true });
    this.closeProducts = Products.orderBy("title")
      .where("active", "==", true)
      .onSnapshot(docs => {
        this.setState({ products: FirebaseResults.map(docs) });
      });

    this.closeCategories = Categories.orderBy("title").onSnapshot(docs => {
      this.setState({ categories: FirebaseResults.map(docs) });
    });

    Bookings.doc(this.props.bookingId)
      .get()
      .then(doc => {
        const booking = doc.data();
        booking.id = this.props.bookingId;
        this.setState({ booking }, this.setupData);
      });
  };

  componentWillUnmount = () => {
    this.closeProducts();
    this.closeCategories();
  };

  setupData = () => {
    const basket = initializeBasket(
      this.state.products,
      this.state.booking.seats,
      this.state.booking.items
    );

    this.setState({
      seats: this.state.booking.seats,
      basket,
      accountId: this.state.booking.accountId,
      status: this.state.booking.status,
      loading: false
    });
  };

  confirmBooking = (basket, payment) => {
    BookingsPersistence.updateBasket(
      this.props.bookingId,
      basket,
      payment
    ).then(() => {
      this.setState({ basket });
      this.props.history.push(`/reservas/listado`);
    });
  };

  render() {
    if (this.state.products.length === 0 || this.state.loading) {
      return null;
    }

    const {
      products,
      categories,
      seats,
      basket,
      accountId,
      status
    } = this.state;
    const account = findAccountById(this.props.accounts, accountId);

    if (!canEditBooking(this.props.profile, account.originId, status)) {
      return (
        <ErrorMessageDialog message="No tienes permiso para modificar este ticket" />
      );
    }

    return (
      <TicketsForm
        bookingId={this.props.bookingId}
        products={products}
        categories={categories}
        seats={seats}
        basket={basket}
        confirmBooking={this.confirmBooking}
      />
    );
  }
}

export default connect(({ firebase: { profile } }) => ({ profile }))(
  withRouter(TicketsFetch)
);
