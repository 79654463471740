import React from "react";

import { Switch, Route } from "react-router-dom";

import AdminDeliveriesFetch from "./Fetch";
import AdminDeliveriesNew from "./New";
import AdminDeliveriesEdit from "./Edit";

const AdminDeliveries = props => (
  <Switch>
    <Route path="/admin/pedidos/new" component={AdminDeliveriesNew} />
    <Route path="/admin/pedidos/edit/:id" component={AdminDeliveriesEdit} />
    <Route path="/admin/pedidos" component={AdminDeliveriesFetch} />
  </Switch>
);

export default AdminDeliveries;
