import React, { useState } from "react";
import { connect } from "react-redux";

import SharedMessage from "../../../Shared/Message";
import AdminLogEntriesListItems from "./ListItems";
import AdminLogEntriesNew from "./New";
import LogEntriesSearch from "../../../Shared/LogEntriesSearch";
import { AdminLogEntriesListHeader } from "./ListHeader";

function AdminLogEntriesList({ logEntries, accounts, queryParams }) {
  const [newEntry, setNewEntry] = useState({
    show: false
  });

  function toggleModal() {
    setNewEntry({ show: !newEntry.show });
  }

  if (accounts.length === 0) {
    return null;
  }

  return (
    <div className="h-min-screen ml-4 font-sans">
      <LogEntriesSearch queryParams={queryParams} />
      <AdminLogEntriesListHeader
        toggleModal={toggleModal}
        logEntries={logEntries}
        selectedAccountId={queryParams.accountId}
      />

      <div>
        {logEntries.length > 0 ? (
          <AdminLogEntriesListItems
            logEntries={logEntries}
            accounts={accounts}
          />
        ) : (
          <SharedMessage message="Todavía no hay ningun apunte." />
        )}
      </div>
      {newEntry.show && (
        <AdminLogEntriesNew
          toggleModal={() => toggleModal()}
          accounts={accounts}
        />
      )}
    </div>
  );
}

const mapStateToProps = state => ({
  accounts: state.mainData.accounts
});

export default connect(mapStateToProps)(AdminLogEntriesList);
