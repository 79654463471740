import React from "react";
import { connect } from "react-redux";
import styled from "@emotion/styled";
import IconButton from "@material-ui/core/IconButton";
import BackButton from "@material-ui/icons/NavigateBefore";
import NextButton from "@material-ui/icons/NavigateNext";

import { monthNameAndYear } from "../../../lib/dates/format";
import { updateReservationParams } from "../../../store/actions";
import { addMonths, startOfMonth, endOfMonth } from "date-fns";

const NavigationContainer = styled("div")`
  display: grid;
  grid-template-columns: 1fr 4fr 1fr;
  justify-items: center;
  align-items: center;
`;

class CalendarNavigation extends React.Component {
  navigateTo = increment => {
    const newMonth = addMonths(this.props.defaultDate, increment);
    this.props.updateParams({
      startDate: startOfMonth(newMonth),
      endDate: endOfMonth(newMonth)
    });
  };

  render() {
    return (
      <NavigationContainer>
        <p>
          <IconButton
            size="small"
            variant="contained"
            color="default"
            aria-label="Mes anterior"
            onClick={() => this.navigateTo(-1)}
          >
            <BackButton />
          </IconButton>
        </p>
        <h3>{monthNameAndYear(this.props.defaultDate)}</h3>
        <p>
          <IconButton
            size="small"
            variant="contained"
            color="default"
            aria-label="Mes siguiente"
            onClick={() => this.navigateTo(1)}
          >
            <NextButton />
          </IconButton>
        </p>
      </NavigationContainer>
    );
  }
}

const mapStateToProps = state => ({
  users: state.mainData.users,
  queryParams: state.mainData.reservationsQuery
});

const mapDispatchToProps = dispatch => ({
  updateParams: params => dispatch(updateReservationParams(params))
});

export default connect(mapStateToProps, mapDispatchToProps)(CalendarNavigation);
