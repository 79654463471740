import React from "react";
import AddIcon from "@material-ui/icons/Add";
import FinancialInfoCard from "../../../Shared/FinancialInfoCard";
import { sumLogEntries } from "../../../../lib/log_entries/calc";

function AdminLogEntriesListHeader({
  toggleModal,
  logEntries,
  selectedAccountId
}) {
  const sumEntries = sumLogEntries(logEntries, selectedAccountId);
  return (
    <div className="p-4 w-full flex justify-between items-center">
      <FinancialInfoCard
        totalIncome={sumEntries.totalIncome}
        totalPending={sumEntries.totalPending}
        totalExpenses={sumEntries.totalExpenses}
      />
      <div className="w-1/6">
        <button
          className="btn secondary"
          aria-label="Nuevo apunte"
          onClick={() => toggleModal()}
        >
          <AddIcon /> Nuevo apunte
        </button>
      </div>
    </div>
  );
}

export { AdminLogEntriesListHeader };
