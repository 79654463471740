import React, { useEffect, useState } from "react";

import LoadingDialog from "../../../Shared/Dialogs/Loading";
import { Deliveries, FirebaseResults } from "../../../../lib/database/";
import ProductsShowItems from "./Items";

function ProductsShowDeliveries({ productId }) {
  const [localState, setLocalState] = useState({
    loading: false,
    deliveries: []
  });

  useEffect(
    function() {
      setLocalState({ loading: true, deliveries: [] });
      return Deliveries.orderBy("createdAt")
        .where("productIds", "array-contains", productId)
        .onSnapshot(docs => {
          setLocalState({
            deliveries: FirebaseResults.map(docs),
            loading: false
          });
        });
    },
    [productId]
  );

  if (localState.loading) {
    return <LoadingDialog />;
  }

  return (
    <ProductsShowItems
      deliveries={localState.deliveries}
      productId={productId}
    />
  );
}

export default ProductsShowDeliveries;
