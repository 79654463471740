import React from "react";
import { css } from "@emotion/core";

import SummaryIncome from "./Income";
import SummaryExpenses from "./Expenses";
import GridContainer from "../../../components/Grid/GridContainer";

const SummaryContent = ({ summaryReport }) => {
  return (
    <GridContainer className={css(`margin-top: 1em;`)}>
      <SummaryIncome summaryReport={summaryReport} />
      <SummaryExpenses summaryReport={summaryReport} />
    </GridContainer>
  );
};

export default SummaryContent;
