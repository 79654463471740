import React from "react";
import AddIcon from "@material-ui/icons/Add";
import Button from "@material-ui/core/Button";

import NewResource from "./new";
import ListResources from "./list";
import EditResource from "./edit";
import DeleteResource from "./delete";

class Resources extends React.Component {
  state = {
    resource: {
      id: "",
      name: "",
      seats: 0,
      available: true
    },
    new: false,
    edit: false,
    remove: false,
    reload: false
  };

  openForm = (formName) => {

    this.setState({ [formName]: true });
  };

  closeForm = (formName) => {

    this.setState({ [formName]: false, reload: true });
  };

  onEdit = (resource) => {
    this.setState({ resource, edit: true });
  };

  onDelete = (resource) => {
    this.setState({ resource, remove: true });
  };

  render() {
    return (
      <div>
        <Button
          variant="contained"
          color="primary"
          onClick={ () => this.openForm("new") }
        >
          <AddIcon /> Nuevo recurso
        </Button>
        <ListResources onEdit={ this.onEdit } onDelete={ this.onDelete } />

        <NewResource
          open={ this.state.new }
          onClose={ () => this.closeForm("new") }
        />
        <EditResource
          open={ this.state.edit }
          resource={ this.state.resource }
          onClose={ () => this.closeForm("edit") }
        />
        <DeleteResource
          open={ this.state.remove }
          item={ this.state.resource }
          onClose={ () => this.closeForm("delete") }
        />
      </div>
    );
  }
}

export default Resources;
