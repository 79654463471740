const reservedSeatsByResource = (events, tables, slotId, resources) => {
  const results = {};

  events.forEach(event => {
    if (event.slotId === slotId && event.resources) {
      event.resources.forEach(eventResource => {
        if (results[eventResource.resourceId]) {
          results[eventResource.resourceId] += parseInt(eventResource.seats);
        } else {
          results[eventResource.resourceId] = parseInt(eventResource.seats);
        }
      });
    }
  });

  resources.forEach(resource => {
    if (results[resource.id] && results[resource.id] >= resource.seats) {
      results[resource.id] = -1;
    }
  });

  tables.forEach(table => {
    if (results[table.resourceId]) {
      results[table.resourceId] += table.seats;
    } else {
      results[table.resourceId] = table.seats;
    }
  });

  return results;
};

const currentlyReservedSeats = resources =>
  resources.reduce(
    (totalSeats, resource) => (totalSeats += parseInt(resource.seats)),
    0
  );

const currentlyReservedTables = tables =>
  tables.reduce(
    (totalSeats, table) => (totalSeats += parseInt(table.seats)),
    0
  );

const findResourceById = (resources, resourceId) =>
  resources.find(resource => resource.id === resourceId);

const getResourcesNames = (resources, bookingResources) =>
  bookingResources.map(resource => {
    const bookedResource = findResourceById(resources, resource.resourceId);
    return bookedResource.name;
  });

export {
  reservedSeatsByResource,
  currentlyReservedSeats,
  currentlyReservedTables,
  getResourcesNames
};
