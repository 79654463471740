const findSubcategories = (parentId, categories) =>
  categories.filter(category => category.parentId === parentId);

const findAllSubcategories = (parentId, categories, result) => {
  const subCategories = categories.filter(
    category => category.parentId === parentId
  );

  if (subCategories.length === 0) {
    return result;
  } else {
    return subCategories.forEach(subCategory => {
      result.add(subCategory.id);
      return findAllSubcategories(subCategory.id, categories, result);
    });
  }
};

const findParentTitleById = (parentId, categories) => {
  if (parentId === "0") {
    return "";
  }

  const findCategory = categories.find(category => category.id === parentId);

  if (findCategory) {
    return findCategory.title;
  } else {
    return "";
  }
};

const countSubcategories = (categoryId, categories) => {
  let total = 0;

  categories.forEach(category => {
    if (category.parentId === categoryId) {
      total += 1;
    }
  });

  return total;
};

export {
  findSubcategories,
  findParentTitleById,
  countSubcategories,
  findAllSubcategories
};
