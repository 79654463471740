import React from "react";
import AddIcon from "@material-ui/icons/Add";

import CustomCardHeader from "../../../../Shared/CustomCardHeader";
import { sumBookings } from "../../../../../lib/bookings/finder";
import { formatDinero } from "../../../../../lib/utils/format";

const AdminReservationsTableHeader = ({ bookings, newBooking }) => {
  const [totalPayments, _totalPending, totalSeats] = sumBookings(bookings);
  return (
    <div className="m-4 flex justify-between items-center">
      <div className="flex">
        <CustomCardHeader
          title="Total reservas"
          amount={formatDinero(totalPayments)}
        />
        <CustomCardHeader title="Num. Comensales" amount={totalSeats} />
      </div>
      <div>
        <button
          className="btn secondary"
          aria-label="Nueva reserva"
          onClick={newBooking}
        >
          <AddIcon /> Nueva reserva
        </button>
      </div>
    </div>
  );
};

export default AdminReservationsTableHeader;
