import React from "react";
import TagsInput from "react-tagsinput";
import { css } from "@emotion/core";
import FormControl from "@material-ui/core/FormControl";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

import CustomInput from "../../../components/CustomInput/CustomInput";
import Button from "../../../components/CustomButtons/Button";

const inputFont = css`
  input {
    font-size: 1.2em;
  }
`;

class AdminProvidersForm extends React.Component {
  constructor(props) {
    super(props);
    if (this.props.activeProvider) {
      this.state = {
        provider: this.props.activeProvider
      };
    } else {
      this.state = {
        provider: {
          id: "",
          name: "",
          tags: [],
          oldId: ""
        }
      };
    }
  }

  handleTags = tags => {
    const provider = { ...this.state.provider };
    provider.tags = tags;

    this.setState({ provider });
  };

  onChange = event => {
    const provider = { ...this.state.provider };
    provider[event.target.name] = event.target.value;
    this.setState({ provider });
  };

  onSubmit = event => {
    event.preventDefault();

    this.props.submitData(this.state.provider);
  };

  render() {
    const { toggleModal } = this.props;
    const { provider } = this.state;

    return (
      <form onSubmit={this.onSubmit}>
        <DialogTitle>Proveedores</DialogTitle>
        <DialogContent>
          <FormControl fullWidth>
            <CustomInput
              labelText="Nombre"
              inputProps={{
                id: "name",
                name: "name",
                autoFocus: true,
                onChange: this.onChange,
                value: provider.name
              }}
            />
          </FormControl>
          <FormControl fullWidth>
            <legend>Etiquetas</legend>
            <TagsInput
              onlyUnique
              maxTags="5"
              className={inputFont}
              value={provider.tags}
              onChange={this.handleTags}
              addKeys={[9, 13, 188]}
              tagProps={{ className: "react-tagsinput-tag info" }}
              inputProps={{
                placeholder: "Añadir etiquetas"
              }}
            />
          </FormControl>
          <FormControl fullWidth>
            <CustomInput
              labelText="old ID"
              inputProps={{
                id: "oldId",
                name: "oldId",
                onChange: this.onChange,
                value: provider.oldId
              }}
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={toggleModal} color="transparent">
            Cancelar
          </Button>
          <Button type="submit" color="success">
            Guardar
          </Button>
        </DialogActions>
      </form>
    );
  }
}

export default AdminProvidersForm;
