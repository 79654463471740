import React, { useState } from "react";

import AdminLogEntriesEdit from "./Edit";
import AdminLogEntriesListItem from "./Items";
import DeleteDialog from "../../../Shared/Dialogs/Delete";
import { LogEntries } from "../../../../lib/database";

function AdminLogEntriesListItems({ accounts, logEntries }) {
  const [newEntry, setNewEntry] = useState({
    showEdit: false,
    showDelete: false,
    activeLogEntry: {}
  });

  function onDelete() {
    LogEntries.doc(newEntry.activeLogEntry.id)
      .delete()
      .then(() => {
        setNewEntry({ ...newEntry, showDelete: false });
      });
  }

  function onClose() {
    setNewEntry({ ...newEntry, showDelete: false });
  }

  function toggleModal(name, activeLogEntry = {}) {
    switch (name) {
      case "edit":
        setNewEntry({
          ...newEntry,
          showEdit: !newEntry.showEdit,
          activeLogEntry
        });
        break;
      case "delete":
        setNewEntry({
          ...newEntry,
          showDelete: !newEntry.showDelete,
          activeLogEntry
        });
        break;
      default:
        setNewEntry({ ...newEntry, activeLogEntry });
        break;
    }
  }

  return (
    <div className="w-full">
      <div className="rounded-lg shadow mt-4">
        <table className="table-auto w-full text-gray-900">
          <thead>
            <tr>
              <th className="p-4 text-gray-700 bg-gray-100 uppercase tracking-wider flex">
                Fecha
              </th>
              <th className="p-4 text-gray-700 bg-gray-100 uppercase tracking-wider">
                Descripción
              </th>
              <th className="p-4 text-gray-700 bg-gray-100 uppercase tracking-wider">
                Etiquetas
              </th>
              <th className="p-4 text-gray-700 bg-gray-100 uppercase tracking-wider">
                Cuenta
              </th>
              <th className="p-4 text-gray-700 bg-gray-100 uppercase tracking-wider">
                Entrada
              </th>
              <th className="p-4 text-gray-700 bg-gray-100 uppercase tracking-wider">
                Salida
              </th>
              <th className="p-4 text-gray-700 bg-gray-100 uppercase tracking-wider">
                Opciones
              </th>
            </tr>
          </thead>
          <tbody>
            {logEntries.map((logEntry, key) => (
              <AdminLogEntriesListItem
                key={key}
                logEntry={logEntry}
                toggleModal={toggleModal}
              />
            ))}
          </tbody>
        </table>
      </div>
      {newEntry.showEdit && (
        <AdminLogEntriesEdit
          toggleModal={() => toggleModal("edit")}
          activeLogEntry={newEntry.activeLogEntry}
          accounts={accounts}
        />
      )}
      {newEntry.showDelete && (
        <DeleteDialog
          title="Borrar apunte"
          onClose={onClose}
          item={newEntry.activeLogEntry}
          open
          onDelete={onDelete}
        />
      )}
    </div>
  );
}

export default AdminLogEntriesListItems;
