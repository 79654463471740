import React from "react";

import Button from "../../../components/CustomButtons/Button";
import LinkButton from "../../Shared/LinkButton";
import GridItem from "../../../components/Grid/GridItem";

const DeliveriesActionButtons = ({ onSubmit }) => (
  <GridItem container alignContent="flex-end" xs={12}>
    <LinkButton to="/admin/pedidos" color="transparent">
      Cancelar
    </LinkButton>
    <Button type="button" onClick={() => onSubmit()} color="success">
      Guardar
    </Button>
  </GridItem>
);

export default DeliveriesActionButtons;
