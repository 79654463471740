import React from "react";
import CardContent from "@material-ui/core/CardContent";

const message = (seats) => {
  if (seats > 0) {
    return `Disponible: ${ seats }`;
  } else {
    return "No disponible";
  }
};

const TableContent = ({ name, seats }) => (
  <CardContent className="tableContent">
    <strong>{ name }</strong>
    <br />
    { message(seats) }
  </CardContent>
);

export default TableContent;
