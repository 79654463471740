import React from "react";
import Dialog from "@material-ui/core/Dialog";
import withStyles from "@material-ui/core/styles/withStyles";

import ReservationForm from "./New/Form";
import modalStyle from "../../assets/jss/modalStyle";
import { getTables } from "../../lib/events/validations";
import { Bookings } from "../../lib/database/";
import { findSlotById } from "../../lib/slots/finder";
import { formatDate } from "../../lib/dates/format";

const updateReservation = (slots, bookingInfo, newInfo, toggleModal) => {
  const slot = findSlotById(slots, newInfo.slotId);

  newInfo.slotName = slot.name;

  Bookings.doc(bookingInfo.id).update(newInfo);
  toggleModal();
};

const EditReservation = ({
  toggleModal,
  bookingInfo,
  eventsForToday,
  slots,
  resources
}) => (
  <Dialog open>
    <ReservationForm
      toggleModal={toggleModal}
      onSubmit={newInfo =>
        updateReservation(slots, bookingInfo, newInfo, toggleModal)
      }
      seats={bookingInfo.seats}
      defaultDate={formatDate(bookingInfo.startDate)}
      slotId={bookingInfo.slotId}
      tables={getTables(bookingInfo.resources, resources)}
      slots={slots}
      resources={resources}
      eventsForToday={eventsForToday}
    />
  </Dialog>
);

export default withStyles(modalStyle)(EditReservation);
