import React from "react";
import { Paper, Tabs, Tab } from "@material-ui/core";

import { translate } from "../../locale/es";

const SelectApproved = ({ defaultValue, onChange, elements }) => {
  const renderElements = () =>
    elements.map(element => <Tab label={translate[element]} key={element} />);
  const selectedElement = elements.indexOf(defaultValue);

  return (
    <Paper square>
      <Tabs
        value={selectedElement}
        indicatorColor="primary"
        textColor="primary"
        onChange={onChange}
      >
        {renderElements()}
      </Tabs>
    </Paper>
  );
};

export default SelectApproved;
