import React, { useState, useEffect } from "react";
import { getYear } from "date-fns";

import SummarySearch from "../../Shared/SummarySearch";
import SummaryContent from "./Content";
import LoadingDialog from "../../Shared/Dialogs/Loading";
import { Reports } from "../../../lib/database/";
import { FirebaseResults } from "../../../lib/database/";

const currentYear = getYear(new Date());
const elements = [currentYear - 2, currentYear - 1, currentYear];

const ReportsSummary = () => {
  const [selectedYear, setSelectedYear] = useState(currentYear);
  const yearChange = (_event, value) => {
    setSelectedYear(elements[value]);
  };

  const [localState, setLocalState] = useState({
    loading: true,
    summaryReport: {}
  });

  useEffect(() => {
    setLocalState({ loading: true, deliveries: [] });
    Reports.where("name", "==", "summary")
      .where("year", "==", selectedYear)
      .get()
      .then(docs => {
        setLocalState({
          summaryReport: FirebaseResults.map(docs)[0],
          loading: false
        });
      });
  }, [selectedYear]);

  if (localState.loading) {
    return <LoadingDialog />;
  }

  return (
    <>
      <h1>Resumen general</h1>
      <SummarySearch
        elements={elements}
        selectedYear={selectedYear}
        onChange={yearChange}
      />
      <SummaryContent summaryReport={localState.summaryReport} />
    </>
  );
};

export default ReportsSummary;
