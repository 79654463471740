import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import SalesCategoriesFetch from "./Fetch";
import LoadingDialog from "../../Shared/Dialogs/Loading";
import { Categories, FirebaseResults } from "../../../lib/database/";

const SalesCategories = ({ queryParams, products }) => {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    Categories.orderBy("parentId")
      .get()
      .then(docs => {
        setCategories(FirebaseResults.map(docs));
      });
  }, []);

  if (categories.length === 0) {
    return <LoadingDialog />;
  }
  return (
    <SalesCategoriesFetch
      queryParams={queryParams}
      categories={categories}
      products={products}
    />
  );
};

const mapStateToProps = state => ({
  queryParams: state.mainData.salesQuery
});

export default connect(mapStateToProps)(SalesCategories);
