const translate = {
  income: "Ingreso",
  expense: "Gasto",
  pending: "Pendiente",
  all: "Todos",
  approved: "Aprobada",
  created: "Pagada",
  rejected: "Rechazada",
  cash: "Efectivo",
  bank: "Transferencia",
  card: "Tarjeta",
  true: "Si",
  false: "No"
};

export { translate };
