import React from "react";
import { withStyles } from "@material-ui/core";

import Table from "../../../../components/Table/Table";
import extendedTablesStyle from "../../../../assets/jss/views/extendedTablesStyle";

const showItem = (items, classes) =>
  items.map(item => [
    <p className={classes.tdNameSmall}>{item.productName}</p>,
    <p className={classes.tdNumber}>{item.quantity}</p>,
    <p className={classes.tdNumber}>{item.price}</p>,
    <p className={classes.tdNumber}>{item.discount}</p>
  ]);

const DeliveryShowProducts = ({ deliveryItems, classes }) => (
  <Table
    striped
    tableHead={["Producto", "Cantidad", "Coste Total", "Descuento"]}
    tableData={showItem(deliveryItems, classes)}
  />
);

export default withStyles(extendedTablesStyle)(DeliveryShowProducts);
