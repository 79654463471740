import React from "react";
import { css } from "@emotion/core";
import DeleteIcon from "@material-ui/icons/Delete";

import Button from "../../../../components/CustomButtons/Button";

const DeliveriesItemOptions = ({ productId, deleteItem }) => (
  <Button
    key="delete"
    color="danger"
    simple
    onClick={() => deleteItem(productId)}
    className={css`
      padding: 0;
    `}
  >
    <DeleteIcon />
  </Button>
);

export default DeliveriesItemOptions;
