import React from "react";

import DateInputLocalised from "./DateInputLocalised";

const DateRangeSelector = ({ startDate, endDate, dateChange }) => {
  return (
    <div className="flex">
      <div className="w-1/2 flex flex-col">
        <label className="mb-2 font-semibold">Fecha inicio</label>
        <DateInputLocalised
          dateValue={startDate}
          dateChange={value => dateChange("startDate", value)}
          placeholder="Fecha de inicio"
        />
      </div>
      <div className="w-1/2 flex flex-col">
        <label className="mb-2 font-semibold">Fecha fin</label>
        <DateInputLocalised
          dateValue={endDate}
          dateChange={value => dateChange("endDate", value)}
          placeholder="Fecha de fin"
        />
      </div>
    </div>
  );
};

export default DateRangeSelector;
