import React from "react";
import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import "react-datepicker/dist/react-datepicker.css";

registerLocale("es", es);

const DateInputLocalised = ({ dateValue, dateChange, placeholder }) => (
  <DatePicker
    selected={dateValue}
    onChange={dateChange}
    dateFormat="dd-MM-yyyy"
    locale="es"
    placeholderText={placeholder}
  />
);

export default DateInputLocalised;
