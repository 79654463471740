import React from "react";

const AdminReservationsTableContentHead = () => (
  <thead>
    <tr>
      <th className="p-4 text-gray-700 bg-gray-100 uppercase tracking-wider">
        Fecha
      </th>
      <th className="p-4 text-gray-700 bg-gray-100 uppercase tracking-wider">
        Socio
      </th>
      <th className="p-4 text-gray-700 bg-gray-100 uppercase tracking-wider">
        Actividad
      </th>
      <th className="p-4 text-gray-700 bg-gray-100 uppercase tracking-wider">
        Comensales
      </th>
      <th className="p-4 text-gray-700 bg-gray-100 uppercase tracking-wider">
        Importe
      </th>
      <th className="p-4 text-gray-700 bg-gray-100 uppercase tracking-wider">
        Forma pago
      </th>
      <th className="p-4 text-gray-700 bg-gray-100 uppercase tracking-wider">
        Acciones
      </th>
    </tr>
  </thead>
);

export default AdminReservationsTableContentHead;
