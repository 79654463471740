import React from "react";
import { withStyles, Link } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import CheckIcon from "@material-ui/icons/Check";

import Button from "../../../components/CustomButtons/Button";
import Table from "../../../components/Table/Table";
import { formatDate } from "../../../lib/dates/format";
import DeleteDialog from "../../Shared/Dialogs/Delete";
import extendedTablesStyle from "../../../assets/jss/views/extendedTablesStyle";
import { Deliveries } from "../../../lib/database/";
import LinkButton from "../../Shared/LinkButton";
import DeliveriesApproveDialog from "./ApproveDialog";
import { formatDinero } from "../../../lib/utils/format";

class AdminDeliveriesListItems extends React.Component {
  state = {
    showEdit: false,
    showDelete: false,
    showApproveDialog: false,
    activeDelivery: {}
  };

  onDelete = () => {
    Deliveries.doc(this.state.activeDelivery.id)
      .delete()
      .then(() => {
        this.setState({ showDelete: false });
      });
  };

  showApproveDialog = activeDelivery => {
    this.setState({ activeDelivery, showApproveDialog: true });
  };

  closeApproved = () => {
    this.setState({ showApproveDialog: false });
  };

  onClose = () => {
    this.setState({ showDelete: false });
  };

  toggleModal = (name, activeDelivery = {}) => {
    switch (name) {
      case "edit":
        this.setState({ activeDelivery, showEdit: !this.state.showEdit });
        break;
      case "delete":
        this.setState({ activeDelivery, showDelete: !this.state.showDelete });
        break;
      default:
        this.setState({ activeDelivery });
        break;
    }
  };

  showDeliveries = () => {
    const { classes, deliveries } = this.props;

    return deliveries.map(delivery => [
      <p className={classes.tdNameSmall}>
        <Link href={`/admin/pedido/${delivery.id}`}>
          {formatDate(delivery.createdAt)}
        </Link>
      </p>,
      <p className={classes.tdNameSmall}>{delivery.accountName}</p>,
      <p className={classes.tdNameSmall}>{delivery.reference}</p>,
      <p className={classes.tdNumber}>{formatDinero(delivery.amount)}</p>,
      <p className={classes.tdNumber}>{this.optionsForDelivery(delivery)}</p>
    ]);
  };

  optionsForDelivery = delivery => {
    const options = [
      <LinkButton
        key="edit"
        color="success"
        simple
        to={`/admin/pedidos/edit/${delivery.id}`}
        className="p-0"
      >
        <EditIcon />
      </LinkButton>,
      <Button
        key="delete"
        color="danger"
        simple
        onClick={() => this.toggleModal("delete", delivery)}
        className="p-0"
      >
        <DeleteIcon />
      </Button>
    ];

    if (!delivery.approved) {
      options.push(
        <Button
          key="aprobar"
          color="info"
          simple
          onClick={() => this.showApproveDialog(delivery)}
          className="p-0"
        >
          <CheckIcon />
        </Button>
      );
    }

    return options;
  };

  render() {
    return (
      <div>
        <Table
          striped
          tableHead={[
            "Fecha",
            "Proveedor",
            "Referencia",
            "Importe",
            "Opciones"
          ]}
          tableData={this.showDeliveries()}
        />
        {this.state.showDelete && (
          <DeleteDialog
            title="Borrar pedido"
            onClose={this.onClose}
            item={this.state.activeDelivery}
            open
            onDelete={this.onDelete}
          />
        )}
        {this.state.showApproveDialog && (
          <DeliveriesApproveDialog
            deliveryInfo={this.state.activeDelivery}
            closeApproved={this.closeApproved}
            accounts={this.props.accounts}
          />
        )}
      </div>
    );
  }
}

export default withStyles(extendedTablesStyle)(AdminDeliveriesListItems);
