import React from "react";
import AddAlert from "@material-ui/icons/AddAlert";

import Snackbar from "../../components/Snackbar/Snackbar";

const NotificationMessage = ({ message, toggleNotification }) => (
  <Snackbar
    open
    place="tc"
    icon={ AddAlert }
    message={ message }
    close
    closeNotification={ toggleNotification }
    color="danger"
  />
);

export default NotificationMessage;
