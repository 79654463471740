import React from "react";
import AddIcon from "@material-ui/icons/Add";
import Button from "@material-ui/core/Button";

import NewSlot from "./new";
import EditSlot from "./edit";
import ListSlots from "./list";
import DeleteSlot from "./delete";

class Slots extends React.Component {
  state = {
    slot: {
      id: "",
      name: "",
      reservation: false,
      startHour: "",
      endHour: ""
    },
    new: false,
    edit: false,
    remove: false,
    reload: false
  };

  openForm = formName => {
    this.setState({ [formName]: true });
  };

  closeForm = formName => {
    this.setState({ [formName]: false, reload: true });
  };

  onEdit = slot => {
    this.setState({ slot, edit: true });
  };

  onDelete = slot => {
    this.setState({ slot, remove: true });
  };

  render() {
    const { edit, slot, remove } = this.state;

    return (
      <div>
        <Button
          variant="contained"
          color="primary"
          onClick={() => this.openForm("new")}
        >
          <AddIcon /> Nueva actividad
        </Button>
        <ListSlots onEdit={this.onEdit} onDelete={this.onDelete} />

        <NewSlot open={this.state.new} onClose={() => this.closeForm("new")} />
        <EditSlot
          open={edit}
          slot={slot}
          onClose={() => this.closeForm("edit")}
        />
        <DeleteSlot
          open={remove}
          item={slot}
          onClose={() => this.closeForm("delete")}
        />
      </div>
    );
  }
}

export default Slots;
