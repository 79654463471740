import * as React from "react";
import { CardContent, CardActions, Button } from "@material-ui/core";

import Table from "../../../../components/Table/Table";
import DeliveriesItemOptions from "./ItemsOptions";
import DeliveriesItemForm from "./ItemForm";
import { FirebaseResults, Products } from "../../../../lib/database/";
import LoadingDialog from "../../../Shared/Dialogs/Loading";
import { findProductById } from "../../../../lib/products/finder";

class DeliveriesFormItems extends React.Component {
  state = {
    loading: false,
    openForm: false,
    products: []
  };

  stopSubscription = null;

  componentDidMount() {
    this.setState({ loading: true });

    this.stopSubscription = Products.orderBy("title").onSnapshot(docs => {
      const productList = FirebaseResults.map(docs);
      productList.forEach(product => (product.name = product.title));
      this.setState({ products: productList, loading: false });
    });
  }

  componentWillUnmount = () => {
    this.stopSubscription();
  };

  showItems = () => {
    return this.props.items.map(item => [
      item.productName,
      item.quantity,
      item.price,
      item.discount,
      <DeliveriesItemOptions
        productId={item.productId}
        deleteItem={this.deleteItem}
      />
    ]);
  };

  deleteItem = productId => {
    const items = this.props.items.filter(item => item.productId !== productId);

    this.props.updateItems(items);
  };

  toggleModal = newStatus => {
    this.setState({ openForm: newStatus });
  };

  onSubmit = item => {
    const items = Array.from(this.props.items);
    const product = findProductById(this.state.products, item.productId);
    const newItem = {
      discount: parseFloat(item.discount),
      price: parseFloat(item.price),
      quantity: parseFloat(item.quantity),
      productId: item.productId,
      productName: product.title,
      vat: product.vat || 0
    };
    items.push(newItem);
    this.props.updateItems(items);
    this.toggleModal(false);
  };

  render() {
    const { loading, products, openForm } = this.state;

    if (loading) {
      return <LoadingDialog />;
    }

    return (
      <CardContent>
        <CardActions>
          <Button onClick={() => this.toggleModal(true)}>
            Añadir producto
          </Button>
        </CardActions>
        <Table
          striped
          tableHead={[
            "Producto",
            "Cantidad",
            "Precio compra",
            "Descuento",
            "Opciones"
          ]}
          tableData={this.showItems()}
        />
        <DeliveriesItemForm
          openForm={openForm}
          toggleModal={this.toggleModal}
          products={products}
          onSubmit={this.onSubmit}
        />
      </CardContent>
    );
  }
}

export default DeliveriesFormItems;
