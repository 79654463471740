import React from "react";
import Dialog from "@material-ui/core/Dialog";

import AdminAccountsForm from "./Form";
import { Accounts } from "../../../../lib/database";

const toggleNew = toggleModal => {
  toggleModal("new");
};

const onSubmit = (accountInfo, toggleModal) => {
  const newAccount = {
    name: accountInfo.name,
    tags: accountInfo.tags,
    active: accountInfo.active
  };

  Accounts.add(newAccount);
  toggleNew(toggleModal);
};

const AdminAccountsNew = ({ toggleModal }) => (
  <Dialog open>
    <AdminAccountsForm
      toggleModal={() => toggleNew(toggleModal)}
      submitData={accountInfo => onSubmit(accountInfo, toggleModal)}
    />
  </Dialog>
);

export default AdminAccountsNew;
