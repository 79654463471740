import React, { useEffect, useState } from "react";

import LoadingDialog from "../../../Shared/Dialogs/Loading";
import { Deliveries, FirebaseResults } from "../../../../lib/database/";
import DeliveryShowItems from "./Items";

function ProviderShowDeliveries({ providerId }) {
  const [localState, setLocalState] = useState({
    loading: false,
    deliveries: []
  });

  useEffect(
    function() {
      setLocalState({ loading: true, deliveries: [] });
      return Deliveries.orderBy("createdAt")
        .where("providerId", "==", providerId)
        .onSnapshot(docs => {
          setLocalState({
            deliveries: FirebaseResults.map(docs),
            loading: false
          });
        });
    },
    [providerId]
  );

  if (localState.loading) {
    return <LoadingDialog />;
  }

  return (
    <DeliveryShowItems
      deliveries={localState.deliveries}
      providerId={providerId}
    />
  );
}

export default ProviderShowDeliveries;
