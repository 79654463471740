import ReportsSummary from "./summary";

const reportsGenerator = () => {
  const summary = selectedYear => {
    return ReportsSummary.generate(selectedYear);
  };

  return Object.freeze({
    summary
  });
};

const ReportsGenerator = reportsGenerator();

export default ReportsGenerator;
