import React from "react";

import HeaderAmount from "./Amount";
import HeaderCategories from "./Categories";

const TicketsHeader = ({
  basket,
  navigateAway,
  confirmPayment,
  categories,
  filterCategory
}) => {
  return (
    <div className="p-2 flex flex-col md:flex-row bg-gray-100 rounded">
      <HeaderAmount
        basket={basket}
        navigateAway={navigateAway}
        confirmPayment={confirmPayment}
      />
      <HeaderCategories
        categories={categories}
        filterCategory={filterCategory}
      />
    </div>
  );
};

export default TicketsHeader;

{
  /* <GridItem xs={12} sm={12} md={9} lg={10}>
          <CustomInput
            id="search"
            labelText="Buscador de productos"
            inputTextStyle={inputFont}
            type="search"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              onChange: this.updateSearch,
              value: this.state.searchTerm,
              autoFocus: true,
              inputRef: input => {
                this.inputElement = input;
              },
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              )
            }}
          /> */
}
