import React from "react";
import styled from "@emotion/styled";

const Title = styled("p")`
  font-weight: bold;
  font-size: 1em;
`;

const Amount = styled("p")`
  font-size: 1em;
  text-align: right;
  padding: 0em 2em 0em 1em;
`;

const SummaryItem = ({ title, amount }) => {
  return (
    <React.Fragment>
      <Title>{title}</Title>
      <Amount>{amount}</Amount>
    </React.Fragment>
  );
};

export default SummaryItem;
