import React from "react";

import AdminCategoriesFetch from "./Fetch";

const AdminCategories = props => {
  let parentId = "0";
  const { match } = props;

  if (match && match.params && match.params.id) {
    parentId = match.params.id;
  }

  return <AdminCategoriesFetch parentId={parentId} />;
};

export default AdminCategories;
