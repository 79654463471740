import React from "react";

import SalesProductsSearch from "./Search";
import SalesItems from "../Items";

const SalesProductsList = ({ sales, queryParams, products }) => (
  <React.Fragment>
    <SalesProductsSearch queryParams={queryParams} products={products} />
    <SalesItems sales={sales} />
  </React.Fragment>
);

export default SalesProductsList;
