import React from "react";
import { connect } from "react-redux";

import AdminAccountsList from "./List";

class AdminAccounts extends React.Component {
  render() {
    const { accounts } = this.props;

    if (accounts.length === 0) {
      return null;
    }

    return <AdminAccountsList accounts={accounts} />;
  }
}

const mapStateToProps = state => ({
  accounts: state.mainData.accounts
});

export default connect(mapStateToProps)(AdminAccounts);
