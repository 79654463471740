import React from "react";
import { withStyles } from "@material-ui/core";

import Table from "../../../../components/Table/Table";
import { formatDate } from "../../../../lib/dates/format";
import extendedTablesStyle from "../../../../assets/jss/views/extendedTablesStyle";
import { calculatePrice } from "../../../../lib/products/price";

const showItem = (delivery, productId, classes) => {
  const [total, unitPrice, quantity] = calculatePrice(
    delivery.items,
    productId
  );

  return [
    <p className={classes.tdNameSmall}>{formatDate(delivery.createdAt)}</p>,
    <p className={classes.tdNameSmall}>{`${delivery.accountName} - ${
      delivery.reference
    }`}</p>,
    <p className={classes.tdNumber}>{quantity}</p>,
    <p className={classes.tdNumber}>{total}</p>,
    <p className={classes.tdNumber}>{unitPrice}</p>
  ];
};

const ProductsShowItems = ({ deliveries, productId, classes }) => (
  <Table
    striped
    tableHead={[
      "Fecha",
      "Proveedor / Referencia",
      "Cantidad",
      "Coste Total",
      "Coste x unidad"
    ]}
    tableData={deliveries.map(delivery =>
      showItem(delivery, productId, classes)
    )}
  />
);

export default withStyles(extendedTablesStyle)(ProductsShowItems);
