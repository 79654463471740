import React from "react";
import { Link } from "@material-ui/core";

import AdminLogEntriesItemOptions from "./ItemOptions";
import { formatDate } from "../../../../lib/dates/format";
import AdminLogEntriesListAmount from "./Amount";

const AdminLogEntriesListItem = ({ logEntry, toggleModal }) => {
  if (!logEntry.accounts) {
    return null;
  }

  return (
    <>
      <tr>
        <td className="p-2 text-sm">
          <Link href={`/reserva/${logEntry.bookingId}`}>
            {formatDate(logEntry.createdAt)}
          </Link>
        </td>
        <td className="p-2 text-sm">{logEntry.title}</td>
        <td className="p-2 text-sm italic" colSpan="4">
          {logEntry.tags.join(", ")}
        </td>
        <td className="p-2 text-sm text-right">
          <AdminLogEntriesItemOptions
            logEntry={logEntry}
            toggleModal={toggleModal}
          />
        </td>
      </tr>
      {logEntry.accounts && (
        <AdminLogEntriesListAmount accounts={logEntry.accounts} />
      )}
    </>
  );
};

export default AdminLogEntriesListItem;
