function productCategories() {
  const SEATS_ID = "73NYkpjDq2DcuN3RhHx4";
  const EXTRAS_ID = "4z2E8jG241EMRtrHSlTT";

  const isExtraProduct = product => {
    return product.id === EXTRAS_ID;
  };

  return Object.freeze({ isExtraProduct });
}

const ProductCategories = productCategories();

export { ProductCategories };
