import Dinero from "dinero.js";

function formatFloatToFixed(number) {
  if (!number || isNaN(number)) {
    return 0;
  }

  return parseFloat(number).toFixed(2);
}

function formatDinero(number, currencySymbol = true) {
  if (!number) {
    number = 0;
  }
  const amount = parseInt(number);

  if (currencySymbol) {
    return Dinero({ amount, currency: "EUR" }).toFormat();
  } else {
    return Dinero({ amount, precision: 2 }).toUnit();
  }
}

// Converts a number to a Dinero amount (no decimals)
function convertToDinero(total) {
  let amount = 0;
  if (!isNaN(parseFloat(total))) {
    amount = Math.round(parseFloat(total).toFixed(2) * 100);
  }

  return Dinero({ amount }).getAmount();
}

export { formatFloatToFixed, formatDinero, convertToDinero };
