import { firstAndLastDayOfMonth } from "../../lib/dates/calendar";

const [firstDayMonth, lastDayMonth] = firstAndLastDayOfMonth(new Date());
const defaultParams = {
  productId: "all",
  startDate: firstDayMonth,
  endDate: lastDayMonth
};

const salesProductsQuery = (state = defaultParams, action) => {
  switch (action.type) {
    case "UPDATE_SALES_PRODUCTS_QUERY":
      return action.params;
    default:
      return state;
  }
};

export { salesProductsQuery };
