import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import SlotForm from "./form";
import { Slots } from "../../../lib/database/";

class NewSlot extends React.Component {
  state = {
    id: "",
    name: "",
    startHour: "12",
    endHour: "22",
    requireSeats: true
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  toggleCheckBox = () => {
    this.setState({ requireSeats: !this.state.requireSeats });
  };

  onSubmit = (event, newSlot) => {
    event.preventDefault();
    if (!this.state.name || this.state.name.trim().length === 0) {
      return false;
    }

    Slots.add({
      name: this.state.name,
      startHour: this.state.startHour,
      endHour: this.state.endHour,
      requireSeats: this.state.requireSeats
    });
    this.props.onClose();
  };

  render() {
    const { open, onClose } = this.props;
    const { name, startHour, endHour, requireSeats } = this.state;
    return (
      <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Nueva actividad</DialogTitle>
        <DialogContent>
          <SlotForm
            name={name}
            startHour={startHour}
            endHour={endHour}
            requireSeats={requireSeats}
            onChange={this.onChange}
            toggleCheckBox={this.toggleCheckBox}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" onClick={this.onSubmit}>
            Guardar
          </Button>
          <Button onClick={onClose}>Cancelar</Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default NewSlot;
