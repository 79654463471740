import { Firestore } from "../../config/firebase";
import FirebaseResults from "./firebase_results";

const Accounts = Firestore.collection("accounts");
const Bookings = Firestore.collection("bookings");
const Categories = Firestore.collection("categories");
const Deliveries = Firestore.collection("deliveries");
const LogEntries = Firestore.collection("log_entries");
const Products = Firestore.collection("products");
const Providers = Firestore.collection("providers");
const Reports = Firestore.collection("reports");
const Resources = Firestore.collection("resources");
const Slots = Firestore.collection("slots");
const Users = Firestore.collection("users");

export {
  Accounts,
  Bookings,
  Categories,
  Deliveries,
  FirebaseResults,
  LogEntries,
  Products,
  Providers,
  Reports,
  Resources,
  Slots,
  Users
};
