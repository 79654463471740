import React from "react";
import { connect } from "react-redux";

import AdminReservationsFormField from "./Fields";
import { Bookings, LogEntries } from "../../../../lib/database/";
import { findSlotById } from "../../../../lib/slots/finder";
import { findAccountById } from "../../../../lib/accounts/finder";
import {
  createLogEntryFromBooking,
  updateLogEntryFromBooking
} from "../../../../lib/log_entries/persistence";
import { formatDinero, convertToDinero } from "../../../../lib/utils/format";

class AdminReservationUpdateForm extends React.Component {
  constructor(props) {
    super(props);
    let startDate;
    if (props.booking.startDate) {
      startDate = new Date(props.booking.startDate.seconds * 1000);
    } else {
      startDate = new Date();
    }

    this.state = {
      snackOpen: false,
      infoMessage: "",
      booking: {
        id: props.booking.id,
        slotId: props.booking.slotId || "",
        selectedDate: startDate,
        total: formatDinero(props.booking.amount, false),
        seats: props.booking.seats || 0,
        accountId: props.booking.accountId || "",
        payment: props.booking.payment || "cash",
        status: props.booking.status || "pending",
        comments: props.booking.comments || "",
        resources: props.booking.resources || []
      }
    };
  }

  dateChange = date => {
    const booking = { ...this.state.booking };
    booking.selectedDate = date;
    this.setState({ booking });
  };

  onChange = event => {
    const booking = { ...this.state.booking };
    booking[event.target.name] = event.target.value;
    this.setState({ booking });
  };

  onSelect = user => {
    const booking = { ...this.state.booking };
    booking.accountId = user.value;
    this.setState({ booking });
  };

  handleSubmit = event => {
    event.preventDefault();

    const { booking } = this.state;

    const slot = findSlotById(this.props.slots, booking.slotId);
    const account = findAccountById(this.props.users, booking.accountId);
    const totalAmount = parseFloat(booking.total);
    const amount = convertToDinero(totalAmount);

    const updatedBooking = {
      slotId: booking.slotId,
      slotName: slot.name,
      startDate: booking.selectedDate,
      endDate: booking.selectedDate,
      amount,
      total: totalAmount,
      seats: parseInt(booking.seats),
      accountId: account.id,
      accountName: account.name,
      payment: booking.payment,
      status: booking.status
    };

    if (booking.comments && booking.comments.trim().length > 0) {
      updatedBooking.comments = booking.comments;
    }

    Bookings.doc(booking.id).update(updatedBooking);
    this.props.toggleModal();
  };

  render() {
    const { toggleModal, slots, users } = this.props;

    return (
      <div>
        <AdminReservationsFormField
          title="Modificar reserva"
          toggleModal={toggleModal}
          slots={slots}
          onSubmit={this.handleSubmit}
          onChange={this.onChange}
          onSelect={this.onSelect}
          dateChange={this.dateChange}
          bookingInfo={this.state.booking}
          users={users}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  accounts: state.mainData.accounts
});

export default connect(mapStateToProps)(AdminReservationUpdateForm);
