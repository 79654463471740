import React, { useState } from "react";
import { Dialog, DialogActions, InputAdornment } from "@material-ui/core";
import Pay from "@material-ui/icons/EuroSymbol";

import { BookingsPersistence } from "../../../../lib/bookings/persistence";
import Button from "../../../../components/CustomButtons/Button";
import DateInputLocalised from "../../../Shared/DateInputLocalised";
import CustomInput from "../../../../components/CustomInput/CustomInput";
import SelectApproved from "../../../Shared/SelectApproved";
import { generatePaymentFromBooking } from "../../../../lib/log_entries/persistence";
import { formatFloatToFixed } from "../../../../lib/utils/format";

const ReservationsApproveDialog = ({
  bookingInfo,
  closeApproved,
  accounts
}) => {
  const selectedPayment = ["cash", "bank", "card"];
  const initialState = {
    date: bookingInfo.startDate.toDate(),
    total: bookingInfo.total,
    payment: bookingInfo.payment,
    expenses: 0,
    amount: bookingInfo.amount,
    accountId: bookingInfo.accountId,
    accountName: bookingInfo.accountName,
    id: bookingInfo.id
  };
  const [booking, setBooking] = useState(initialState);

  const valueChange = (name, newValue) => {
    const currentBooking = { ...booking };
    currentBooking[name] = newValue;
    setBooking(currentBooking);
  };

  const paymentChange = (_event, value) => {
    const currentBooking = { ...booking };
    currentBooking.payment = selectedPayment[value];
    if (currentBooking.payment === "card" && currentBooking.total > 0) {
      currentBooking.expenses = formatFloatToFixed(
        (currentBooking.total * 0.4) / 100
      );
    }
    setBooking(currentBooking);
  };

  const generatePayment = () => {
    generatePaymentFromBooking(booking, accounts);
    BookingsPersistence.approve(bookingInfo.id);
    closeApproved();
  };

  return (
    <Dialog open aria-labelledby="form-dialog-title">
      <h2 className="text-2xl p-4 font-bold text-blue-800">Aprobar reserva</h2>
      <div className="m-4">
        <div className="flex">
          <label className="mr-4">Fecha del pago</label>
          <DateInputLocalised
            dateValue={booking.date}
            dateChange={newDate => valueChange("date", newDate)}
            placeholder="Fecha del apunte"
          />
        </div>
        <div className="mt-4">
          <SelectApproved
            onChange={paymentChange}
            defaultValue={booking.payment}
            elements={selectedPayment}
          />
        </div>
        <div className="mt-4 flex">
          <div className="w-1/2">
            <CustomInput
              labelText="Importe"
              type="number"
              inputProps={{
                onChange: event =>
                  valueChange(event.target.name, event.target.value),
                id: "total",
                name: "total",
                value: booking.total,
                endAdornment: (
                  <InputAdornment position="end">
                    <Pay />
                  </InputAdornment>
                )
              }}
            />
          </div>
          <div className="w-1/2">
            <CustomInput
              labelText="Gastos"
              type="number"
              inputProps={{
                onChange: event =>
                  valueChange(event.target.name, event.target.value),
                id: "expenses",
                name: "expenses",
                value: booking.expenses
              }}
            />
          </div>
        </div>
      </div>
      <DialogActions>
        <Button variant="contained" onClick={closeApproved}>
          Cancelar
        </Button>
        <Button variant="contained" color="success" onClick={generatePayment}>
          Generar pago
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ReservationsApproveDialog;
