import React from "react";
import firebase from "firebase/app";
import Dinero from "dinero.js";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { createStore, combineReducers } from "redux";
import { Provider } from "react-redux";
import {
  ReactReduxFirebaseProvider,
  firebaseReducer
} from "react-redux-firebase";

import "../../lib/users/auth";
import "../../config/firebase";

import mainData from "../../store/reducers";
import PublicIndex from "../Public";
import Login from "../Public/Login";
import Logout from "../Public/Logout";
import Admin from "../Admin";
import ProtectedRoute from "../Shared/ProtectedRoute";
import GlobalMessage from "../Shared/GlobalMessage";

const rrfConfig = {
  userProfile: "users",
  useFirestoreForProfile: true
};

Dinero.globalLocale = "es-ES";

const rootReducer = combineReducers({
  firebase: firebaseReducer,
  mainData
});

const initialState = {};
const store = createStore(rootReducer, initialState);

const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch
};

const App = () => (
  <Provider store={store}>
    <ReactReduxFirebaseProvider {...rrfProps}>
      <GlobalMessage />
      <Router>
        <Switch>
          <Route exact path="/" component={PublicIndex} />
          <Route path="/logout" component={Logout} />
          <Route path="/login" component={Login} />
          <ProtectedRoute path="/(.*)" component={Admin} />
        </Switch>
      </Router>
    </ReactReduxFirebaseProvider>
  </Provider>
);

export default App;
