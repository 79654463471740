import React from "react";
import { FormControlLabel, RadioGroup, Radio } from "@material-ui/core";

import { orderSlots } from "../../../lib/slots/format";

function Slots({ slotId, onChange, slots }) {
  function renderItems(currentSlots) {
    if (!currentSlots || currentSlots.length === 0) {
      return <p>Nada</p>;
    }

    currentSlots = orderSlots(currentSlots, "order");

    return currentSlots.map(slotItem => (
      <FormControlLabel
        key={slotItem.id}
        value={slotItem.id}
        control={<Radio />}
        label={slotItem.name}
      />
    ));
  }

  return (
    <RadioGroup
      aria-label="Evento"
      name="slotId"
      value={slotId}
      onChange={onChange}
      row
    >
      {renderItems(slots)}
    </RadioGroup>
  );
}

export default Slots;
