import React from "react";
import { Link } from "@material-ui/core";
import Edit from "@material-ui/icons/Edit";
import Delete from "@material-ui/icons/Delete";
import CheckIcon from "@material-ui/icons/Check";

import Button from "../../../../components/CustomButtons/Button";
import { formatDate } from "../../../../lib/dates/format";
import AdminReservationsTableHeader from "./Header";
import AdminReservationsTableContent from "./Content";
import ReservationBadge from "../../../Shared/ReservationBadge";
import DeleteDialog from "../../../Shared/Dialogs/Delete";
import { translate } from "../../../../locale/es";
import { formatDinero } from "../../../../lib/utils/format";
import ReservationsApproveDialog from "./ApproveDialog";
import { BookingsPersistence } from "../../../../lib/bookings/persistence";

class AdminReservationsTable extends React.Component {
  emptyBooking = () => {
    return {
      id: "",
      startDate: new Date(),
      endDate: new Date(),
      slot: {
        id: "",
        name: "",
        reservation: false,
        startHour: "",
        endHour: ""
      },
      user: {
        id: "",
        name: "",
        role: "",
        cognitoId: ""
      },
      seats: 0,
      payment: "cash",
      status: "created",
      comments: "",
      total: 0,
      resources: [
        {
          resourceId: "",
          seats: 0
        }
      ],
      items: []
    };
  };

  state = {
    deleteOpen: false,
    modalOpen: false,
    approveModal: false,
    operation: "new",
    bookingInfo: this.emptyBooking()
  };

  toggleModal = () => {
    this.setState({ modalOpen: !this.state.modalOpen });
  };

  showApproveDialog = bookingInfo => {
    this.setState({ bookingInfo, approveModal: true });
  };

  closeApproved = () => {
    this.setState({ approveModal: false });
  };

  newBooking = () => {
    this.setState({ modalOpen: true, operation: "new" });
  };

  editBooking = bookingInfo => {
    this.setState({ modalOpen: true, operation: "edit", bookingInfo });
  };

  deleteBooking = bookingInfo => {
    this.setState({ deleteOpen: true, bookingInfo });
  };

  onClose = () => {
    this.setState({ deleteOpen: false });
  };

  onDelete = () => {
    BookingsPersistence.remove(this.state.bookingInfo.id).then(() => {
      this.setState({ deleteOpen: false });
    });
  };

  actionButtons = booking => {
    const buttons = [
      {
        color: "success",
        icon: Edit,
        onClick: () => this.editBooking(booking),
        text: ""
      },
      {
        color: "danger",
        icon: Delete,
        onClick: () => this.deleteBooking(booking),
        text: ""
      }
    ];

    if (booking.status !== "approved") {
      buttons.push({
        color: "info",
        icon: CheckIcon,
        onClick: () => this.showApproveDialog(booking),
        text: "Aprobar"
      });
    }

    return buttons.map(element => (
      <Button
        key={element.color}
        color={element.color}
        simple
        className="m-0 mr-5 p-0"
        onClick={element.onClick}
      >
        <element.icon />
      </Button>
    ));
  };

  render() {
    const { bookings, accounts } = this.props;

    const tableRows = bookings.map((booking, key) => {
      return [
        <tr key={key} className="border-b border-gray-200">
          <td className="p-2 text-sm text-center">
            <Link href={`/reserva/${booking.id}`}>
              {formatDate(booking.startDate)}
            </Link>
          </td>
          <td className="p-2 text-sm text-center">{booking.accountName}</td>
          <td className="p-2 text-sm text-center">
            <ReservationBadge name={booking.slotName}>
              {booking.slotName}
            </ReservationBadge>
          </td>
          <td className="p-2 text-sm text-right">{booking.seats}</td>
          <td className="p-2 text-sm text-right">
            {formatDinero(booking.amount)}
          </td>
          <td className="p-2 text-sm text-center">
            {translate[booking.payment]}
          </td>
          <td className="p-2 text-sm text-right">
            {this.actionButtons(booking)}
          </td>
        </tr>
      ];
    });

    return (
      <div>
        <AdminReservationsTableHeader
          bookings={bookings}
          newBooking={this.newBooking}
        />

        <AdminReservationsTableContent
          modalOpen={this.state.modalOpen}
          operation={this.state.operation}
          tableRows={tableRows}
          accounts={accounts}
          toggleModal={this.toggleModal}
          bookingInfo={this.state.bookingInfo}
        />

        <DeleteDialog
          title="Borrar reserva"
          onClose={this.onClose}
          item={this.state.bookingInfo}
          onDelete={this.onDelete}
          open={this.state.deleteOpen}
        />
        {this.state.approveModal && (
          <ReservationsApproveDialog
            bookingInfo={this.state.bookingInfo}
            closeApproved={this.closeApproved}
            accounts={accounts}
          />
        )}
      </div>
    );
  }
}

export default AdminReservationsTable;
