import React from "react";

import { totalPayable } from "../../lib/products/basket";
import TicketsPayment from "./Payment";

class BasketTotal extends React.Component {
  state = {
    showDialog: false,
    payment: "cash"
  };

  toggleModal = () => {
    this.setState({ showDialog: !this.state.showDialog });
  };

  onChange = event => {
    const value = event.target.value;
    this.setState({ payment: value });
  };

  render() {
    const { basket, confirmPayment } = this.props;

    return (
      <React.Fragment>
        <button
          className="px-4 py-1 bg-green-500 text-white rounded shadow"
          onClick={this.toggleModal}
        >
          Pagar
        </button>
        <TicketsPayment
          showDialog={this.state.showDialog}
          toggleModal={this.toggleModal}
          confirmPayment={() => confirmPayment(this.state.payment)}
          netTotal={totalPayable(basket)}
          onChange={this.onChange}
          payment={this.state.payment}
        />
      </React.Fragment>
    );
  }
}

export default BasketTotal;
