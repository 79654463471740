import React from "react";
import { Link } from "react-router-dom";
import styled from "@emotion/styled";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";

import AdminCategoriesEdit from "./Edit";
import AdminDeleteCategory from "./Delete";
import GridItem from "../../../components/Grid/GridItem";
import Card from "../../../components/Card/Card";
import CardHeader from "../../../components/Card/CardHeader";
import CardFooter from "../../../components/Card/CardFooter";
import Button from "../../../components/CustomButtons/Button";

const CardImage = styled("div")`
  img {
    width: 100%;
  }
`;

const CategoryOptions = styled("div")`
  display: flex;
  flex-direction: row;
  align-items: center;
  div {
    width: 17em;
  }
  button {
    padding: 0;
    margin: 0;
    margin-left: 1em;
  }
`;

class AdminCategoriesListItem extends React.Component {
  state = {
    showEdit: false,
    showDelete: false
  };

  toggleModal = name => {
    switch (name) {
      case "edit":
        this.setState({ showEdit: !this.state.showEdit });
        break;
      case "delete":
        this.setState({ showDelete: !this.state.showDelete });
        break;
      default:
        break;
    }
  };

  render() {
    const s3Url =
      "https://depuracepaaws-userfiles-mobilehub-933783480.s3.eu-central-1.amazonaws.com/public/";
    const { category, classes, count } = this.props;

    return (
      <GridItem xs={12} sm={6} md={4} lg={3}>
        <Card chart>
          <CardHeader color="info">
            <CardImage>
              <Link to={`/admin/categorias/${category.id}`}>
                <img src={s3Url + category.s3Image} alt={category.title} />
              </Link>
            </CardImage>
            <Link to={`/admin/categorias/${category.id}`}>
              <h4 className={classes.cardTitle}>{category.title}</h4>
            </Link>
          </CardHeader>
          <CardFooter stats>
            <CategoryOptions>
              <div className={classes.stats}>{count} subcategorías</div>
              <Button
                color="success"
                simple
                onClick={() => this.setState({ showEdit: true })}
              >
                <EditIcon />
              </Button>
              <Button
                color="danger"
                simple
                onClick={() => this.setState({ showDelete: true })}
              >
                <DeleteIcon />
              </Button>
            </CategoryOptions>
          </CardFooter>
        </Card>
        {this.state.showEdit && (
          <AdminCategoriesEdit
            toggleModal={() => this.toggleModal("edit")}
            activeCategory={category}
          />
        )}
        {this.state.showDelete && (
          <AdminDeleteCategory
            onClose={() => this.toggleModal("delete")}
            item={category}
            open
          />
        )}
      </GridItem>
    );
  }
}

export default AdminCategoriesListItem;
