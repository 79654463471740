import React from "react";
import Dialog from "@material-ui/core/Dialog";

import AdminProductsForm from "./Form";
import { Products } from "../../../lib/database/";

const toggleNew = toggleModal => {
  toggleModal("new");
};

const onSubmit = async (productInfo, toggleModal) => {
  const newProduct = {
    title: productInfo.title,
    categoryId: productInfo.categoryId,
    image: productInfo.image,
    tags: productInfo.tags,
    vat: parseFloat(productInfo.vat),
    stockAmount: parseFloat(productInfo.stockAmount),
    buyPrice: parseFloat(productInfo.buyPrice),
    sellPrice: parseFloat(productInfo.sellPrice),
    active: productInfo.active,
    createdAt: new Date()
  };

  if (productInfo.image.trim().length > 0) {
    newProduct.image = productInfo.image;
  }

  Products.add(newProduct);
  toggleModal("new");
};

const AdminProductsNew = ({ toggleModal, categoryId }) => (
  <Dialog open>
    <AdminProductsForm
      categoryId={categoryId}
      toggleModal={() => toggleNew(toggleModal)}
      submitData={productInfo => onSubmit(productInfo, toggleModal)}
    />
  </Dialog>
);

export default AdminProductsNew;
