import firebase from "firebase/app";
import "firebase/firebase-firestore";
import "firebase/firebase-auth";
import "firebase/firebase-functions";
import "firebase/firebase-storage";

const config = {
  apiKey: "AIzaSyBPk164P8NFI8GVIoOaATZ17N75K7v0h2I",
  authDomain: "sociedad-de-pura-cepa.firebaseapp.com",
  databaseURL: "https://sociedad-de-pura-cepa.firebaseio.com",
  projectId: "sociedad-de-pura-cepa",
  storageBucket: "sociedad-de-pura-cepa.appspot.com",
  messagingSenderId: "7303840202"
};

firebase.initializeApp(config).functions("europe-west1");

const Firestore = firebase.firestore();
const Auth = firebase.auth();
const Functions = firebase.functions();
const Storage = firebase.storage();

export { Firestore, Auth, Functions, Storage };
