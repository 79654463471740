import React from "react";
import TagsInput from "react-tagsinput";
import { css } from "@emotion/core";
import "react-tagsinput/react-tagsinput.css";
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControlLabel,
  Checkbox
} from "@material-ui/core";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import Check from "@material-ui/icons/Check";

import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem";
import ImageSelector from "../../Shared/ImageSelector";
import CustomInput from "../../../components/CustomInput/CustomInput";
import Button from "../../../components/CustomButtons/Button";

const inputFont = css`
  input {
    font-size: 1.2em;
  }
`;

class AdminProductsForm extends React.Component {
  constructor(props) {
    super(props);
    if (this.props.activeProduct) {
      this.state = {
        loadingImage: false,
        product: this.props.activeProduct
      };
    } else {
      this.state = {
        loadingImage: false,
        product: {
          id: "",
          title: "",
          categoryId: this.props.categoryId || "0",
          image: "",
          tags: [],
          sellPrice: 0,
          buyPrice: 0,
          vat: 0,
          active: true,
          stockAmount: 0
        }
      };
    }
  }

  onChange = event => {
    const product = { ...this.state.product };
    product[event.target.name] = event.target.value;

    this.setState({ product });
  };

  toggleCheckBox = () => {
    const product = { ...this.state.product };
    product.active = !this.state.product.active;

    this.setState({ product });
  };

  updateImageName = name => {
    const product = { ...this.state.product };
    product.image = name;

    this.setState({ product });
  };

  handleTags = tags => {
    const product = { ...this.state.product };
    product.tags = tags;

    this.setState({ product });
  };

  loading = (status = false) => {
    this.setState({ loadingImage: status });
  };

  onSubmit = event => {
    event.preventDefault();

    this.props.submitData(this.state.product);
  };

  render() {
    const { toggleModal } = this.props;
    const { product } = this.state;
    const imageName = product.s3Image || product.image;

    return (
      <form onSubmit={this.onSubmit}>
        <DialogTitle>Productos</DialogTitle>
        <DialogContent>
          <GridContainer>
            <GridItem xs={12} md={12} lg={12}>
              <CustomInput
                inputTextStyle={inputFont}
                labelText="Nombre"
                autoFocus
                inputProps={{
                  id: "title",
                  name: "title",
                  autoFocus: true,
                  onChange: this.onChange,
                  value: product.title
                }}
                formControlProps={{
                  fullWidth: true
                }}
              />
            </GridItem>
            <GridItem xs={12} md={6} lg={6}>
              <CustomInput
                inputTextStyle={inputFont}
                labelText="Precio compra"
                inputProps={{
                  id: "buyPrice",
                  name: "buyPrice",
                  onChange: this.onChange,
                  value: product.buyPrice
                }}
                formControlProps={{
                  fullWidth: true
                }}
              />
            </GridItem>
            <GridItem xs={12} md={6}>
              <CustomInput
                inputTextStyle={inputFont}
                labelText="Precio venta"
                inputProps={{
                  id: "sellPrice",
                  name: "sellPrice",
                  onChange: this.onChange,
                  value: product.sellPrice
                }}
                formControlProps={{
                  fullWidth: true
                }}
              />
            </GridItem>
            <GridItem xs={12} md={6}>
              <CustomInput
                inputTextStyle={inputFont}
                labelText="Cantidad Stock"
                inputProps={{
                  id: "stockAmount",
                  name: "stockAmount",
                  onChange: this.onChange,
                  value: product.stockAmount
                }}
                formControlProps={{
                  fullWidth: true
                }}
              />
            </GridItem>
            <GridItem xs={12} md={6}>
              <CustomInput
                inputTextStyle={inputFont}
                labelText="IVA"
                inputProps={{
                  id: "vat",
                  name: "vat",
                  onChange: this.onChange,
                  value: product.vat
                }}
                formControlProps={{
                  fullWidth: true
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={8} md={6}>
              <legend>Etiquetas</legend>
              <TagsInput
                onlyUnique
                maxTags="5"
                className={inputFont}
                value={this.state.product.tags}
                onChange={this.handleTags}
                addKeys={[9, 13, 188]}
                tagProps={{ className: "react-tagsinput-tag info" }}
                inputProps={{
                  placeholder: "Añadir etiquetas"
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={4} md={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={() => this.toggleCheckBox()}
                    icon={<CheckBoxOutlineBlankIcon />}
                    checkedIcon={<Check />}
                    checked={product.active}
                  />
                }
                label="Disponible?"
              />
            </GridItem>
            <GridItem xs={12} md={12}>
              <ImageSelector
                filesPath="product"
                imageName={imageName}
                updateImageName={this.updateImageName}
                loading={this.loading}
              />
            </GridItem>
          </GridContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={toggleModal} color="transparent">
            Cancelar
          </Button>
          <Button
            disabled={this.state.loadingImage}
            type="submit"
            color="success"
          >
            Guardar
          </Button>
        </DialogActions>
      </form>
    );
  }
}

export default AdminProductsForm;
