import React from "react";

import { sumLogEntries } from "../../../lib/log_entries/calc";
import FinancialInfoCard from "../../Shared/FinancialInfoCard";
import LogEntriesListEntry from "./entry";

function LogEntriesListItems({ logEntries, selectedAccountId }) {
  const sumEntries = sumLogEntries(logEntries, selectedAccountId);

  return (
    <div>
      <FinancialInfoCard
        totalIncome={sumEntries.totalIncome}
        totalPending={sumEntries.totalPending}
        totalExpenses={sumEntries.totalExpenses}
      />

      <div className="rounded-lg shadow mt-4">
        <table className="table-auto w-full text-gray-900">
          <thead>
            <tr>
              <th className="p-4 text-gray-700 bg-gray-100 uppercase tracking-wider">
                Fecha
              </th>
              <th className="p-4 text-gray-700 bg-gray-100 uppercase tracking-wider">
                Descripción
              </th>
              <th className="p-4 text-gray-700 bg-gray-100 uppercase tracking-wider">
                Cuenta
              </th>
              <th className="p-4 text-gray-700 bg-gray-100 uppercase tracking-wider">
                Entrada
              </th>
              <th className="p-4 text-gray-700 bg-gray-100 uppercase tracking-wider">
                Salida
              </th>
            </tr>
          </thead>
          <tbody>
            {logEntries.map((logEntry, key) => (
              <LogEntriesListEntry key={key} logEntry={logEntry} />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default LogEntriesListItems;
