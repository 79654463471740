import React from "react";

import bgImage from "../../assets/img/bg8.jpg";

console.log(bgImage);
const backgroundStyle = {
  backgroundImage: "url(" + bgImage + ")",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  opacity: 0.95,
  height: "100vh"
};

const PublicIndex = () => (
  <div style={backgroundStyle}>
    <div className="pl-20 z-10 w-full h-full text-white flex flex-col justify-center align-middle">
      <h1 className="text-5xl font-bold text-white">De Pura Cepa</h1>
      <h2 className="text-2xl text-white">
        No hay mejor sociedad, que yo sepa.
      </h2>
      <a
        href="/reservas"
        className="mt-10 w-56 py-4 px-6 btn bg-red-600 text-white"
      >
        Gestión de reservas
      </a>
    </div>
  </div>
);

export default PublicIndex;
