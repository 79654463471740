import React, { useState } from "react";
import TagsInput from "react-tagsinput";

import "../../../../assets/css/select-search.css";
import DateInputLocalised from "../../../Shared/DateInputLocalised";
import AdminLogEntriesAccounts from "./Accounts";
import { AccountsAmount } from "../../../../lib/accounts/amount";
import { addOrReplaceAccount } from "../../../../lib/accounts/finder";

function AdminLogEntriesForm({
  accounts,
  activeLogEntry,
  submitData,
  toggleModal
}) {
  const accountOptions = accounts.map(account => ({
    name: account.name,
    value: account.id
  }));
  let initialState = {};

  if (activeLogEntry) {
    const logEntry = { ...activeLogEntry };
    logEntry.createdAt = new Date(logEntry.createdAt.seconds * 1000);
    initialState = { accountOptions, logEntry };
  } else {
    initialState = {
      accountOptions,
      submitDisabled: false,
      logEntry: {
        id: "",
        type: "expense",
        incomeId: "",
        outcomeId: "",
        title: "",
        tags: [],
        total: 0,
        accounts: [],
        accountIds: [],
        createdAt: new Date(),
        approved: true
      }
    };
  }

  const [formEntries, setFormEntries] = useState(initialState);

  function dateChange(date) {
    const newDate = new Date(date);
    const updatedLogEntry = { ...formEntries.logEntry };
    updatedLogEntry.createdAt = newDate;
    setFormEntries({ logEntry: updatedLogEntry });
  }

  function handleTags(tags) {
    const updatedLogEntry = { ...formEntries.logEntry };
    updatedLogEntry.tags = tags;

    setFormEntries({ logEntry: updatedLogEntry });
  }

  function onChange(event) {
    const updatedLogEntry = { ...formEntries.logEntry };
    updatedLogEntry[event.target.name] = event.target.value;
    setFormEntries({ logEntry: updatedLogEntry });
  }

  function onSubmit(event) {
    event.preventDefault();
    submitData(formEntries.logEntry);
  }

  function updateEntry(account) {
    const updatedLogEntry = { ...formEntries.logEntry };
    updatedLogEntry.accounts = addOrReplaceAccount(
      updatedLogEntry.accounts,
      account
    );
    updatedLogEntry.accountIds = updatedLogEntry.accounts.map(
      account => account.id
    );
    const totalAmount = AccountsAmount(updatedLogEntry.accounts).total();
    const submitDisabled = totalAmount !== 0;
    setFormEntries({ logEntry: updatedLogEntry, submitDisabled });
  }

  const { submitDisabled, logEntry } = formEntries;
  if (accountOptions.length === 0) {
    return null;
  }

  return (
    <form onSubmit={onSubmit}>
      <legend className="p-6 text-2xl text-blue-600">Apuntes</legend>
      <div className="p-4">
        <div className="z-50">
          <DateInputLocalised
            dateChange={dateChange}
            dateValue={new Date(logEntry.createdAt)}
            placeholder="Fecha del apunte"
          />
        </div>
        <div className="w-full">
          <input
            className="w-full text-lg text-gray-700 border-2 rounded p-1"
            autoFocus
            type="text"
            name="title"
            value={logEntry.title}
            onChange={onChange}
          />
        </div>
        <div className="w-full">
          <legend>Etiquetas</legend>
          <TagsInput
            onlyUnique
            maxTags="5"
            className="text-lg"
            value={logEntry.tags}
            onChange={handleTags}
            addKeys={[9, 13, 188]}
            tagProps={{ className: "react-tagsinput-tag info" }}
            inputProps={{
              placeholder: "Añadir etiquetas"
            }}
          />
        </div>
      </div>
      <AdminLogEntriesAccounts
        logEntry={logEntry}
        accountOptions={accountOptions}
        updateEntry={updateEntry}
      />
      <div className="flex justify-end mr-4 mb-4">
        <button className="btn flat-cancel mr-4" onClick={toggleModal}>
          Cancelar
        </button>
        <button type="submit" className="btn success" disabled={submitDisabled}>
          Guardar
        </button>
      </div>
    </form>
  );
}

export default AdminLogEntriesForm;
