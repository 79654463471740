import React from "react";
import { formatDinero } from "../../../../lib/utils/format";
import { AccountsAmount } from "../../../../lib/accounts/amount";

function formatTotal(accounts) {
  const totalAmount = AccountsAmount(accounts).total();
  const total = formatDinero(totalAmount);

  return totalAmount !== 0 ? (
    <span className="text-red-800 font-bold">{total}</span>
  ) : (
    total
  );
}

function AdminLogEntriesAccountTotal({ accounts }) {
  return <p className="mb-2">Saldo: {formatTotal(accounts)}</p>;
}

export default AdminLogEntriesAccountTotal;
