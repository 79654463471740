import React, { useState } from "react";
import { connect } from "react-redux";
import InputAdornment from "@material-ui/core/InputAdornment";
import Email from "@material-ui/icons/Email";
import Lock from "@material-ui/icons/LockOutlined";
import { Dialog, TextField } from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

import CustomInput from "../../components/CustomInput/CustomInput.jsx";
import LoadingDialog from "../Shared/Dialogs/Loading";
import { Auth } from "../../config/firebase.js";
import { validateEmail } from "../../lib/utils/validations.js";
import { addGlobalMessage } from "../../store/actions/index.js";
import { welcomeMessage } from "../../locale/welcome_message";

function Login({ addMessage, history }) {
  const [loginState, setLoginState] = useState({
    email: "",
    emailRecovery: "",
    recoveryHelp: "",
    recoveryError: false,
    password: "",
    errorMessage: "",
    isDisabled: false,
    passwordDialog: false
  });

  function onChange(event) {
    setLoginState({
      ...loginState,
      [event.target.name]: event.target.value,
      errorMessage: ""
    });
  }

  function requestNewPassword() {
    if (
      !loginState.emailRecovery ||
      loginState.emailRecovery.trim().length === 0 ||
      !validateEmail(loginState.emailRecovery)
    ) {
      addMessage("Por favor, introduce un correo electrónico válido.");
      setLoginState({
        ...loginState,
        recoveryError: true,
        recoveryHelp: "Por favor, introduce un correo electrónico válido."
      });
      return;
    }
    Auth.sendPasswordResetEmail(loginState.emailRecovery)
      .then(() => {
        setLoginState({
          ...loginState,
          passwordDialog: false,
          emailRecovery: "",
          recoveryError: false,
          recoveryHelp: "",
          errorMessage:
            "Recibirás un correo electrónico con un enlace donde cambiar tu contraseña."
        });
      })
      .catch(() => {
        setLoginState({
          ...loginState,
          passwordDialog: false,
          emailRecovery: "",
          recoveryError: false,
          recoveryHelp: "",
          errorMessage: "Ese email no está registrado."
        });
      });
  }

  function onSubmit(event) {
    event.preventDefault();
    setLoginState({ loginState, errorMessage: "", isDisabled: true });
    const loginInfo = {
      email: loginState.email,
      password: loginState.password
    };

    Auth.signInWithEmailAndPassword(loginInfo.email, loginInfo.password)
      .then(() => {
        // TODO fix this ugly hack
        const randomNumber = Math.floor(Math.random() * 9) + 1;
        const randomQuote = `random_${randomNumber}`;

        addMessage(welcomeMessage[randomQuote]);
        setTimeout(() => {
          history.push("/reservas");
        }, 2000);
      })
      .catch(error => {
        addMessage(error.message);
        setLoginState({ ...loginState, isDisabled: false });
        console.log(error);
      });
  }

  return (
    <div className="mt-16 flex justify-center">
      <form onSubmit={onSubmit}>
        <div className="rounded shadow-lg">
          <div className=" m-4 -mt-6 p-3 bg-pink-600 rounded">
            <h4 className="text-white text-xl">
              De Pura Cepa - Sociedad gastronómica
            </h4>
          </div>
          <div className="p-4">
            <CustomInput
              labelText="Tu email"
              id="email"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                name: "email",
                autoFocus: true,
                onChange: onChange,
                endAdornment: (
                  <InputAdornment position="end">
                    <Email />
                  </InputAdornment>
                )
              }}
            />
            <CustomInput
              labelText="Contraseña"
              id="password"
              onChange={onChange}
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                name: "password",
                type: "password",
                onChange: onChange,
                endAdornment: (
                  <InputAdornment position="end">
                    <Lock />
                  </InputAdornment>
                )
              }}
            />
            <p>
              <a
                href="!#"
                onClick={event => {
                  event.preventDefault();
                  setLoginState({ ...loginState, passwordDialog: true });
                }}
              >
                He olvidado mi contraseña.
              </a>
            </p>
            <p className="text-red-600">{loginState.errorMessage}</p>
          </div>
          <div className="p-4">
            <div>
              {loginState.isDisabled ? (
                <LoadingDialog noText />
              ) : (
                <button
                  className="btn primary w-full py-2 text-2xl uppercase tracking-wider"
                  type="submit"
                >
                  Entrar
                </button>
              )}
            </div>
            <div className="mt-4">
              <h6 className="text-sm text-gray-700">Versión 2.0.1</h6>
            </div>
          </div>
        </div>
      </form>
      <Dialog open={loginState.passwordDialog}>
        <DialogTitle>Recuperar contraseña</DialogTitle>
        <DialogContent>
          <TextField
            label="Escribe tu email"
            id="emailRecovery"
            onChange={onChange}
            helperText={loginState.recoveryHelp}
            name="emailRecovery"
            autoFocus
            error={loginState.recoveryError}
            value={loginState.emailRecovery}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Email />
                </InputAdornment>
              )
            }}
          />
        </DialogContent>
        <DialogActions>
          <button
            onClick={() =>
              setLoginState({
                ...loginState,
                emailRecovery: "",
                errorMessage: "",
                passwordDialog: false
              })
            }
            className="btn cancel"
          >
            Cancelar
          </button>
          <button
            type="button"
            onClick={requestNewPassword}
            className="btn success"
          >
            Enviar email
          </button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const mapStateToProps = state => ({
  showMessage: state.mainData.showMessage,
  message: state.mainData.message,
  firebase: state.firebase
});

const mapDispatchToProps = dispatch => ({
  addMessage: message => dispatch(addGlobalMessage(message))
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
