import React, { useEffect } from "react";
import { connect } from "react-redux";

import TicketsFetch from "./Fetch";
import LoadingDialog from "../Shared/Dialogs/Loading";
import "./tickets.css";
import { updateLayoutId } from "../../store/actions";

const Tickets = ({ match, accounts, updateLayoutId }) => {
  useEffect(() => {
    updateLayoutId({ id: "tickets" });
  });

  if (accounts.length === 0) {
    return <LoadingDialog />;
  }

  return (
    <div>
      <TicketsFetch bookingId={match.params.id} accounts={accounts} />
    </div>
  );
};

const mapStateToProps = state => ({
  accounts: state.mainData.accounts
});

const mapDispatchToProps = dispatch => ({
  updateLayoutId: newId => dispatch(updateLayoutId(newId))
});

export default connect(mapStateToProps, mapDispatchToProps)(Tickets);
