const findCategoryById = (categories, categoryId) =>
  categories.find(category => category.id === categoryId);

const findParentCategories = (categories, categoryId) => {
  const currentCategory = findCategoryById(categories, categoryId);

  if (currentCategory.parentId === "0") {
    return currentCategory;
  } else {
    return findParentCategories(categories, currentCategory.parentId);
  }
};

export { findCategoryById, findParentCategories };
