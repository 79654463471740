import React from "react";

import TicketsProductItem from "./ProductItem";

const TicketsList = ({ products, onSelect, onUpdate }) => (
  <div className="w-1/2 md:w-3/4  overflow-auto">
    <div className="flex flex-wrap">
      {products.map(product => (
        <TicketsProductItem
          product={product}
          key={product.id}
          onSelect={onSelect}
          onUpdate={onUpdate}
        />
      ))}
    </div>
  </div>
);

export default TicketsList;
