import React from "react";

import SharedMessage from "../../Shared/Message";
import LogEntriesListItems from "./Items";
import LogEntriesSearch from "../../Shared/LogEntriesSearch";

const LogEntriesList = ({ logEntries, queryParams }) => (
  <div>
    <LogEntriesSearch queryParams={queryParams} />
    {logEntries.length > 0 ? (
      <LogEntriesListItems
        logEntries={logEntries}
        queryParams={queryParams}
        selectedAccountId={queryParams.accountId}
      />
    ) : (
      <SharedMessage message="No hay información en las fechas seleccionadas." />
    )}
  </div>
);

export default LogEntriesList;
