import React from "react";

const CustomCardHeader = ({ title, amount, icon }) => (
  <div className="mt-2 mr-2 p-4 rounded shadow">
    <p className="text-sm">{title}</p>
    <p className="font-semibold text-lg">{amount}</p>
  </div>
);

export default CustomCardHeader;
