import React from "react";
import Dialog from "@material-ui/core/Dialog";

import AdminLogEntriesForm from "./Form";
import { LogEntries } from "../../../../lib/database";

const toggleNew = toggleModal => {
  toggleModal("new");
};

const onSubmit = (logEntryInfo, toggleModal) => {
  delete logEntryInfo.id;

  LogEntries.add(logEntryInfo).then(() => toggleModal("new"));
};

const AdminLogEntriesNew = ({ toggleModal, users, accounts }) => (
  <Dialog open>
    <AdminLogEntriesForm
      toggleModal={() => toggleNew(toggleModal)}
      users={users}
      accounts={accounts}
      submitData={logEntryInfo => onSubmit(logEntryInfo, toggleModal)}
    />
  </Dialog>
);

export default AdminLogEntriesNew;
