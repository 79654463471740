import React from "react";

import BasketTotal from "../BasketTotal";
import { formatFloatToFixed } from "../../../lib/utils/format";
import {
  totalAmount,
  totalPayable,
  totalSeats
} from "../../../lib/products/basket";

const HeaderAmount = ({ basket, navigateAway, confirmPayment }) => {
  const total = totalAmount(basket);
  const netTotal = totalPayable(basket);
  let totalPerPerson = 0;
  const seats = totalSeats(basket);
  if (seats > 0) {
    totalPerPerson = formatFloatToFixed(total / seats);
  }
  const totalExtras = total - netTotal;
  return (
    <div className="w-full md:w-1/2">
      <div className="w-full flex justify-center px-3 py-2 text-4xl text-pink-800 bg-pink-200 rounded ">
        <h2 className="mr-4">Total: {formatFloatToFixed(netTotal)}&euro;</h2>
        <BasketTotal basket={basket} confirmPayment={confirmPayment} />
      </div>
      <div className="flex">
        <div className="w-3/4 mt-3">
          <div className="px-2 py-1 font-bold flex justify-between bg-teal-200 text-teal-700">
            <h3 className="text-center">
              Total bruto
              <br /> {formatFloatToFixed(total)}&euro;
            </h3>
            <h3 className="text-center">
              Total extras
              <br /> {formatFloatToFixed(totalExtras)}&euro;
            </h3>
            <h3 className="text-center">
              Coste x comensal
              <br /> {formatFloatToFixed(totalPerPerson)}&euro;
            </h3>
          </div>
        </div>

        <div className="w-1/4 text-right pt-6 pl-4">
          <a
            className="border-gray-500 border rounded px-3 py-2 text-xs text-gray-700"
            title="Salir sin guardar"
            href="#!"
            onClick={navigateAway}
          >
            Salir sin guardar
          </a>
        </div>
      </div>
    </div>
  );
};

export default HeaderAmount;
