import React from "react";
import { connect } from "react-redux";

import {
  findEventsForDay,
  filterEventsByUser
} from "../../../lib/events/finder";
import ManagerList from "./List";
import { Auth } from "../../../config/firebase";
import { findAccountByOriginId } from "../../../lib/accounts/finder";

const CalendarManager = ({
  defaultDate,
  toggleCalendar,
  bookings,
  profile,
  slots,
  resources,
  open,
  accounts
}) => {
  if (!open || accounts.length === 0) {
    return null;
  }
  const eventsForToday = findEventsForDay(bookings, defaultDate);
  const account = findAccountByOriginId(accounts, Auth.currentUser.uid);
  const myBookings = filterEventsByUser(eventsForToday, account.id);

  return (
    <ManagerList
      myBookings={myBookings}
      eventsForToday={eventsForToday}
      defaultDate={defaultDate}
      toggleCalendar={toggleCalendar}
      profile={profile}
      resources={resources}
      slots={slots}
    />
  );
};

const mapStateToProps = state => {
  return {
    bookings: state.mainData.bookings,
    accounts: state.mainData.accounts,
    slots: state.mainData.slots,
    resources: state.mainData.resources,
    profile: state.firebase.profile
  };
};

export default connect(mapStateToProps)(CalendarManager);
