const users = (state = {}, action) => {
  switch (action.type) {
    case "UPDATE_ACTIVE_USERS":
      return action.users;
    default:
      return state;
  }
};

export { users };
