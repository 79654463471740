import React from "react";
import { withStyles } from "@material-ui/core";

import Table from "../../../components/Table/Table";
import extendedTablesStyle from "../../../assets/jss/views/extendedTablesStyle";
import { formatFloatToFixed } from "../../../lib/utils/format";

const showItem = (item, classes) => [
  <p className={classes.tdNameSmall}>{item.title}</p>,
  <p className={classes.tdNumber}>{item.quantity}</p>,
  <p className={classes.tdNumber}>{item.price}</p>,
  <p className={classes.tdNumber}>
    {formatFloatToFixed(item.price * item.quantity)}
  </p>
];

const ReservationsShowItems = ({ booking, classes }) => (
  <Table
    striped
    tableHead={["Producto", "Cantidad", "Importe"]}
    tableData={booking.items.map(item => showItem(item, classes))}
  />
);

export default withStyles(extendedTablesStyle)(ReservationsShowItems);
