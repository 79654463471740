import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

class DeleteDialog extends React.Component {
  onSubmit = async event => {
    event.preventDefault();
    if (!this.props.item || !this.props.item.id) {
      return false;
    }

    // this.props.document

    if (this.props.onDelete) {
      this.props.onDelete(this.props.item);
    } else {
      this.props.onClose();
    }
  };

  render() {
    const { open, onClose, title } = this.props;
    return (
      <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>Estas seguro?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="secondary"
            onClick={event => this.onSubmit(event)}
          >
            Borrar
          </Button>
          <Button onClick={onClose}>Cancelar</Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default DeleteDialog;
