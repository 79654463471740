import React from "react";
import { connect } from "react-redux";

import AdminDeliveriesForm from "./Form";
import { Deliveries } from "../../../lib/database/";
import { findAccountById } from "../../../lib/accounts/finder";
import { addGlobalMessage } from "../../../store/actions";

const onSubmit = (deliveryInfo, accounts, addMessage, history) => {
  delete deliveryInfo.id;
  deliveryInfo.total = parseFloat(deliveryInfo.total);
  deliveryInfo.approved = false;

  if (deliveryInfo.accountId && deliveryInfo.accountId.trim().length > 0) {
    const account = findAccountById(accounts, deliveryInfo.accountId);
    deliveryInfo.accountName = account.name;
  } else {
    addMessage("Cada pedido debe llevar asociado un proveedor");
    return;
  }

  Deliveries.add(deliveryInfo)
    .then(() => {
      addMessage("Pedido creado correctamente");
      history.push("/admin/pedidos");
    })
    .catch(error => {
      addMessage(error.message);
    });
};

const AdminDeliveriesNew = ({ accounts, history, addMessage }) => (
  <AdminDeliveriesForm
    accounts={accounts}
    submitData={deliveryInfo =>
      onSubmit(deliveryInfo, accounts, addMessage, history)
    }
  />
);

const mapStateToProps = state => ({
  accounts: state.mainData.accounts
});

const mapDispatchToProps = dispatch => ({
  addMessage: message => dispatch(addGlobalMessage(message))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminDeliveriesNew);
