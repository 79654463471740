import { formatDinero } from "../utils/format";

function sumLogEntries(logEntries, selectedAccountId = null) {
  let totalIncome = 0;
  let totalExpenses = 0;
  let totalPending = 0;
  logEntries.forEach(function(entry) {
    const result = sumLogEntryAccounts(entry, selectedAccountId);
    totalIncome += result.totalIncome;
    totalExpenses += result.totalExpenses;
  });

  totalPending = formatDinero(totalIncome - totalExpenses);
  totalIncome = formatDinero(totalIncome);
  totalExpenses = formatDinero(totalExpenses);

  return { totalIncome, totalExpenses, totalPending };
}

function sumLogEntryAccounts(entry, selectedAccountId = null) {
  let totalIncome = 0;
  let totalExpenses = 0;

  entry.accounts &&
    entry.accounts.forEach(function(account) {
      if (
        !selectedAccountId ||
        (selectedAccountId && selectedAccountId === account.id)
      ) {
        if (account.type === "debit") {
          totalIncome += account.total;
        } else {
          totalExpenses += account.total;
        }
      }
    });
  return { totalIncome, totalExpenses };
}

export { sumLogEntries, sumLogEntryAccounts };
