import React from "react";
import { connect } from "react-redux";

import LogEntriesFetch from "./Fetch";

const LogEntries = ({ queryParams }) => (
  <LogEntriesFetch queryParams={queryParams} />
);

const mapStateToProps = state => ({
  queryParams: state.mainData.logEntryQuery
});

export default connect(mapStateToProps)(LogEntries);
