import { LogEntries } from "../database/";
import { findAccountByName, findAccountById } from "../accounts/finder";
import { approveDelivery } from "../deliveries/persistence";
import { convertToDinero } from "../utils/format";

const createLogEntryFromDelivery = (deliveryInfo, accounts) => {
  const outcome = getAccountFromPayment(deliveryInfo.payment, accounts);
  const income = findAccountById(accounts, deliveryInfo.accountId);

  let logEntryInfo = {
    incomeId: income.id,
    incomeName: income.name,
    total: deliveryInfo.total,
    amount: convertToDinero(deliveryInfo.total),
    tags: ["pedidos", outcome.name],
    approved: true,
    createdAt: deliveryInfo.date,
    outcomeId: outcome.id,
    outcomeName: outcome.name,
    title: `Pedido ${outcome.name} - ${deliveryInfo.reference}`,
    deliveryId: deliveryInfo.id
  };

  logEntryInfo.accounts = [
    {
      id: income.id,
      name: income.name,
      total: logEntryInfo.amount,
      type: "debit"
    },
    {
      id: outcome.id,
      name: outcome.name,
      total: logEntryInfo.amount,
      type: "credit"
    }
  ];
  logEntryInfo.accountIds = [income.id, outcome.id];

  console.log(logEntryInfo);
  // LogEntries.add(logEntryInfo).then(() => {
  //   approveDelivery(deliveryInfo);
  // });
};

const createLogEntryFromBooking = (bookingInfo, accounts) => {
  const reservationAccount = getReservationsAccount(accounts);
  const reservationUser = findAccountById(accounts, bookingInfo.accountId);

  let logEntryInfo = {
    incomeId: reservationUser.id,
    incomeName: reservationUser.name,
    total: bookingInfo.total,
    amount: convertToDinero(bookingInfo.total),
    tags: ["reservas", reservationUser.name],
    approved: false,
    createdAt: bookingInfo.date,
    outcomeId: reservationAccount.id,
    outcomeName: reservationAccount.name,
    title: "Reserva " + reservationUser.name,
    bookingId: bookingInfo.id
  };

  logEntryInfo.accounts = [
    {
      id: reservationUser.id,
      name: reservationUser.name,
      total: logEntryInfo.amount,
      type: "debit"
    },
    {
      id: "U1Y1SiuYlKPZMZQ6kZrQ",
      name: "Consumos socios",
      total: logEntryInfo.amount,
      type: "credit"
    }
  ];
  logEntryInfo.accountIds = [reservationUser.id, "U1Y1SiuYlKPZMZQ6kZrQ"];

  LogEntries.add(logEntryInfo);
};

const updateLogEntryFromBooking = (bookingInfo, accounts) => {
  const reservationAccount = getReservationsAccount(accounts);
  const reservationUser = findAccountById(accounts, bookingInfo.accountId);

  let logEntryInfo = {
    incomeId: reservationUser.id,
    incomeName: reservationUser.name,
    total: bookingInfo.total,
    amount: convertToDinero(bookingInfo.total),
    tags: ["reservas", reservationUser.name],
    approved: false,
    createdAt: bookingInfo.date,
    outcomeId: reservationAccount.id,
    outcomeName: reservationAccount.name,
    title: "Reserva " + reservationUser.name,
    bookingId: bookingInfo.id
  };

  logEntryInfo.accounts = [
    {
      id: reservationUser.id,
      name: reservationUser.name,
      total: logEntryInfo.amount,
      type: "debit"
    },
    {
      id: "U1Y1SiuYlKPZMZQ6kZrQ",
      name: "Consumos socios",
      total: logEntryInfo.amount,
      type: "credit"
    }
  ];
  logEntryInfo.accountIds = [reservationUser.id, "U1Y1SiuYlKPZMZQ6kZrQ"];

  LogEntries.doc(logEntryInfo.id).update(logEntryInfo);
};

const generatePaymentFromBooking = (bookingInfo, accounts) => {
  const payment = getAccountFromPayment(bookingInfo.payment, accounts);

  let totalPayment = bookingInfo.amount;
  let logEntryInfo = {
    createdAt: bookingInfo.date,
    incomeId: payment.id,
    incomeName: payment.name,
    total: bookingInfo.amount,
    tags: ["reservas", bookingInfo.accountName],
    outcomeId: bookingInfo.accountId,
    outcomeName: bookingInfo.accountName,
    title: "Reserva " + bookingInfo.accountName,
    bookingId: bookingInfo.id
  };

  if (bookingInfo.payment === "card") {
    const expenses = convertToDinero(bookingInfo.expenses);
    totalPayment -= expenses;
    logEntryInfo.accounts = [
      {
        id: "sWXTdZOQVHPkFQt3MnZF",
        name: "Gastos bancarios",
        total: expenses,
        type: "debit"
      }
    ];
    logEntryInfo.accountIds = ["sWXTdZOQVHPkFQt3MnZF"];
  } else {
    logEntryInfo.accounts = [];
    logEntryInfo.accountIds = [];
  }

  logEntryInfo.accounts.push({
    id: payment.id,
    name: payment.name,
    total: totalPayment,
    type: "debit"
  });
  logEntryInfo.accounts.push({
    id: bookingInfo.accountId,
    name: bookingInfo.accountName,
    total: bookingInfo.amount,
    type: "credit"
  });

  logEntryInfo.accountIds.push(bookingInfo.accountId);
  logEntryInfo.accountIds.push(payment.id);

  LogEntries.add(logEntryInfo);
};

const getAccountFromPayment = (payment, accounts) => {
  if (payment === "cash") {
    return findAccountByName(accounts, "Caja metálico");
  } else {
    return findAccountByName(accounts, "Caja Rural");
  }
};

const getReservationsAccount = accounts =>
  // 'U1Y1SiuYlKPZMZQ6kZrQ' ==> Consumos socios
  findAccountById(accounts, "U1Y1SiuYlKPZMZQ6kZrQ");

export {
  createLogEntryFromBooking,
  createLogEntryFromDelivery,
  generatePaymentFromBooking,
  updateLogEntryFromBooking
};
