import React from "react";
import Dialog from "@material-ui/core/Dialog";

import AdminLogEntriesForm from "./Form";
import { LogEntries } from "../../../../lib/database";

const toggleEdit = toggleModal => {
  toggleModal("edit");
};

const onSubmit = (logEntryInfo, toggleModal) => {
  const entryId = logEntryInfo.id;
  delete logEntryInfo["id"];
  logEntryInfo.total = parseFloat(logEntryInfo.total);

  LogEntries.doc(entryId)
    .update(logEntryInfo)
    .then(() => {
      toggleModal("edit");
    });
};

const AdminLogEntriesEdit = ({ activeLogEntry, toggleModal, accounts }) => {
  return (
    <Dialog open>
      <AdminLogEntriesForm
        activeLogEntry={activeLogEntry}
        accounts={accounts}
        toggleModal={() => toggleEdit(toggleModal)}
        submitData={info => onSubmit(info, toggleModal)}
      />
    </Dialog>
  );
};

export default AdminLogEntriesEdit;
