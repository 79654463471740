import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  InputAdornment
} from "@material-ui/core";
import Pay from "@material-ui/icons/EuroSymbol";

import GridItem from "../../../components/Grid/GridItem";
import Button from "../../../components/CustomButtons/Button";
import DateInputLocalised from "../../Shared/DateInputLocalised";
import CustomInput from "../../../components/CustomInput/CustomInput";
import SelectApproved from "../../Shared/SelectApproved";
import { createLogEntryFromDelivery } from "../../../lib/log_entries/persistence";

const DeliveriesApproveDialog = ({ deliveryInfo, closeApproved, accounts }) => {
  const selectedPayment = ["cash", "bank", "card"];
  const initialState = {
    date: new Date(deliveryInfo.createdAt.seconds * 1000),
    total: deliveryInfo.total,
    amount: deliveryInfo.amount,
    payment: 1,
    expenses: 0,
    accountId: deliveryInfo.accountId,
    accountName: deliveryInfo.accountName,
    id: deliveryInfo.id
  };
  const [delivery, setDelivery] = useState(initialState);

  const valueChange = (name, newValue) => {
    const currentDelivery = { ...delivery };
    currentDelivery[name] = newValue;
    setDelivery(currentDelivery);
  };
  const paymentChange = (_event, value) => {
    const currentDelivery = { ...delivery };
    currentDelivery.payment = selectedPayment[value];
    setDelivery(currentDelivery);
  };

  const generatePayment = () => {
    createLogEntryFromDelivery(delivery, accounts);
    closeApproved();
  };

  return (
    <Dialog open aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Aprobar pedido</DialogTitle>
      <DialogContent style={{ margin: "1em" }}>
        <GridItem xs={12} style={{ marginTop: "1em" }}>
          <label>Fecha del pago</label>&nbsp;&nbsp;
          <DateInputLocalised
            dateValue={delivery.date}
            dateChange={newDate => valueChange("date", newDate)}
            placeholder="Fecha del apunte"
          />
        </GridItem>
        <GridItem xs={12} style={{ marginTop: "1em" }}>
          <label>Tipo</label>
          <SelectApproved
            onChange={paymentChange}
            defaultValue={delivery.payment}
            elements={selectedPayment}
          />
        </GridItem>
        <GridItem xs={12} sm={5} style={{ marginTop: "1em" }}>
          <label>Importe</label>
          <CustomInput
            labelText="Importe"
            type="number"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              onChange: event =>
                valueChange(event.target.name, event.target.value),
              id: "total",
              name: "total",
              value: delivery.total,
              endAdornment: (
                <InputAdornment position="end">
                  <Pay />
                </InputAdornment>
              )
            }}
          />
        </GridItem>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={closeApproved}>
          Cancelar
        </Button>
        <Button variant="contained" color="success" onClick={generatePayment}>
          Generar pago
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeliveriesApproveDialog;
