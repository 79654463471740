import React from "react";
import Dialog from "@material-ui/core/Dialog";

import AdminProvidersForm from "./Form";
import { Providers } from "../../../lib/database/";

const toggleEdit = toggleModal => {
  toggleModal("edit");
};

const onSubmit = (provider, toggleModal) => {
  Providers.doc(provider.id).update({
    name: provider.name,
    tags: provider.tags,
    oldId: provider.oldId
  });
  toggleModal("edit");
};

const AdminProvidersEdit = ({ activeProvider, toggleModal }) => {
  return (
    <Dialog open>
      <AdminProvidersForm
        activeProvider={activeProvider}
        toggleModal={() => toggleEdit(toggleModal)}
        submitData={info => onSubmit(info, toggleModal)}
      />
    </Dialog>
  );
};

export default AdminProvidersEdit;
