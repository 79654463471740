import React from "react";

import TableContent from "../table";
import { orderResults } from "../../../lib/utils/results";

const availableSeatsPerResource = (reservedResources, resource) => {
  if (reservedResources[resource.id]) {
    if (~reservedResources[resource.id]) {
      return -1;
    } else {
      return 0;
    }
  } else {
    return resource.seats;
  }
};

class ResourceItems extends React.Component {
  render() {
    const {
      onClick,
      tables,
      reservedResources,
      items,
      pendingSeats
    } = this.props;

    if (!items || items.length === 0) {
      return null;
    }
    const resources = orderResults(items, "name");

    return resources.map((resource, index) => {
      const totalSeats = availableSeatsPerResource(reservedResources, resource);
      let requiredSeats = 0;
      if (pendingSeats > totalSeats) {
        requiredSeats = totalSeats;
      } else {
        requiredSeats = pendingSeats;
      }

      const rowIndex = index > 2 ? "w-1/2" : "w-1/3";
      const selected = tables.find(table => table.resourceId === resource.id)
        ? " bg-blue-200 "
        : " ";
      const occupied = totalSeats === -1 ? " bg-gray-200" : " ";

      return (
        <div
          key={index}
          className={`mt-2 shadow rounded ${rowIndex} ${selected} ${occupied}`}
          onClick={() => {
            if (totalSeats === -1) {
              return false;
            }
            onClick(resource, requiredSeats, pendingSeats);
          }}
        >
          <TableContent name={resource.name} seats={totalSeats} />
        </div>
      );
    });
  }
}

export default ResourceItems;
