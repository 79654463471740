import { firstAndLastDayOfMonth } from "../../lib/dates/calendar";

const [firstDayMonth, lastDayMonth] = firstAndLastDayOfMonth(new Date());
const defaultParams = {
  accountId: "",
  approved: "all",
  tags: "",
  startDate: firstDayMonth,
  endDate: lastDayMonth
};

const logEntryQuery = (state = defaultParams, action) => {
  switch (action.type) {
    case "UPDATE_QUERY_PARAMS":
      return action.params;
    default:
      return state;
  }
};

export { logEntryQuery };
