import React from "react";

import { Providers } from "../../../../lib/database/";
import ProviderHeader from "./Header";
import LoadingDialog from "../../../Shared/Dialogs/Loading";
import ProviderShowDeliveries from "./Deliveries";

class AdminProvidersShow extends React.Component {
  state = {
    provider: {},
    loading: false
  };

  componentDidMount() {
    const providerId = this.props.match.params.id.toString();

    this.setState({ loading: true });
    Providers.doc(providerId)
      .get()
      .then(doc => {
        const provider = doc.data();
        provider.id = providerId;
        this.setState({ provider, loading: false });
      });
  }

  render() {
    if (this.state.loading || !this.state.provider.id) {
      return <LoadingDialog />;
    }

    return (
      <div>
        <ProviderHeader provider={this.state.provider} />
        <ProviderShowDeliveries providerId={this.state.provider.id} />
      </div>
    );
  }
}

export default AdminProvidersShow;
