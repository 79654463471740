import React, { useState } from "react";
import { connect } from "react-redux";
import SelectSearch from "react-select-search";
import { Button } from "@material-ui/core";
import Search from "@material-ui/icons/Search";

import { updateSalesProductsParams } from "../../../store/actions";
import DateRangeSelector from "../../Shared/DateRange";

const SalesProductsSearch = ({
  queryParams,
  updateSalesProductsParams,
  products
}) => {
  const [queryFilter, setQueryFilter] = useState({ ...queryParams });

  const submitForm = () => {
    updateSalesProductsParams(queryFilter);
  };

  const changeDate = (fieldName, value) => {
    const newQuery = { ...queryFilter };
    newQuery[fieldName] = value;
    setQueryFilter(newQuery);
  };

  const onResourceSelect = product => {
    const newQuery = { ...queryFilter };
    newQuery.productId = product.value;
    setQueryFilter(newQuery);
  };

  const productOptions = products.map(product => ({
    name: product.title,
    value: product.id
  }));
  productOptions.unshift({ name: "** Todos **", value: "all" });

  return (
    <div className="w-full flex">
      <div className="mt-4 z-50 w-2/4">
        <DateRangeSelector
          startDate={queryFilter.startDate}
          endDate={queryFilter.endDate}
          dateChange={changeDate}
        />
      </div>

      <div className="mt-4 w-1/4">
        <label>Producto</label>
        <SelectSearch
          options={productOptions}
          onChange={product => onResourceSelect(product)}
          value={queryFilter.productId}
          name="product"
          placeholder="Selecciona un producto"
        />
      </div>
      <div className="mt-4 w-1/4">
        <Button variant="contained" color="primary" onClick={submitForm}>
          <Search /> Buscar
        </Button>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({ ...state });

const mapDispatchToProps = dispatch => ({
  updateSalesProductsParams: params =>
    dispatch(updateSalesProductsParams(params))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SalesProductsSearch);
