import React from "react";
import { connect } from "react-redux";

import AdminDeliveriesList from "./List";
import LoadingDialog from "../../Shared/Dialogs/Loading";
import { Deliveries, FirebaseResults } from "../../../lib/database/";

class AdminDeliveriesFetch extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      deliveries: []
    };
  }

  stopSubscription = null;

  componentDidMount = () => {
    this.loadData();
  };

  componentDidUpdate = prevProps => {
    if (prevProps.queryParams !== this.props.queryParams) {
      this.loadData();
    }
  };

  componentWillUnmount = () => {
    this.stopSubscription();
  };

  loadData = async () => {
    this.setState({ loading: true });
    const { queryParams } = this.props;

    let query = Deliveries.orderBy("createdAt")
      .where("createdAt", ">=", queryParams.startDate)
      .where("createdAt", "<=", queryParams.endDate);

    if (
      queryParams.accountId &&
      queryParams.accountId.trim().length > 0 &&
      queryParams.accountId !== "all"
    ) {
      query = query.where("accountId", "==", queryParams.accountId);
    }

    this.stopSubscription = query.onSnapshot(docs => {
      this.setState({
        deliveries: FirebaseResults.map(docs) || [],
        loading: false
      });
    });
  };

  render() {
    const { deliveries, loading } = this.state;

    if (loading) {
      return <LoadingDialog />;
    }

    return (
      <AdminDeliveriesList
        deliveries={deliveries}
        accounts={this.props.accounts}
      />
    );
  }
}

const mapStateToProps = state => ({
  queryParams: state.mainData.deliveryQuery,
  accounts: state.mainData.accounts
});

export default connect(mapStateToProps)(AdminDeliveriesFetch);
