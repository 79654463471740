import match from "autosuggest-highlight/match";

const filterProducts = (products, inputValue, categoryIds) => {
  const newValue = inputValue.trim();
  if (newValue.length < 2 && categoryIds.length === 0) {
    return products;
  }
  return products.filter(product => {
    return hasMatch(product, newValue, categoryIds);
  });
};

const hasMatch = (product, inputValue, categoryIds) => {
  let textMatch = true;
  let categoryMatch = true;
  if (inputValue.length !== 0) {
    const titleMatch = match(product.title, inputValue).length > 0;
    const tagMatch = match(product.tags.join(" "), inputValue).length > 0;
    textMatch = titleMatch || tagMatch;
  }

  if (categoryIds.length !== 0) {
    categoryMatch = !!categoryIds.find(
      categoryId => product.categoryId === categoryId
    );
  }

  return textMatch && categoryMatch;
};

const productMatchesCategories = (productId, products, categoryIds) => {
  const product = findProductById(products, productId);

  return categoryIds.includes(product.categoryId);
};

const findProductById = (products, productId) =>
  products.find(products => products.id === productId);

export { filterProducts, findProductById, productMatchesCategories };
