import React from "react";
import { Dialog } from "@material-ui/core";

import ReservationList from "../../Reservations/List";
import Reservations from "../../Reservations";
import EditReservation from "../../Reservations/Edit";
import { isAdmin } from "../../../lib/users/permission";

class ManagerList extends React.Component {
  state = {
    newReservation: false,
    editReservation: false,
    bookingInfo: {}
  };

  editReservation = bookingInfo => {
    this.setState({ editReservation: true, bookingInfo });
  };

  toggleEdit = () => {
    this.setState({ editReservation: !this.state.editReservation });
  };

  toggleReservation = newReservation => {
    this.setState({ newReservation });
  };
  render() {
    const {
      myBookings,
      toggleCalendar,
      eventsForToday,
      profile,
      defaultDate,
      slots,
      resources
    } = this.props;
    const { newReservation, editReservation, bookingInfo } = this.state;
    if (editReservation) {
      return (
        <Dialog open>
          <EditReservation
            toggleModal={this.toggleEdit}
            bookingInfo={bookingInfo}
            eventsForToday={eventsForToday}
            slots={slots}
            resources={resources}
          />
        </Dialog>
      );
    }
    if (myBookings.length > 0 && newReservation === false) {
      return (
        <ReservationList
          open
          bookings={myBookings}
          toggleCalendar={toggleCalendar}
          onCreate={this.toggleReservation}
          onEdit={this.editReservation}
        />
      );
    } else {
      let twoDaysAgo = new Date();
      twoDaysAgo.setDate(twoDaysAgo.getDate() - 3);

      if (defaultDate >= twoDaysAgo || isAdmin(profile)) {
        return (
          <Reservations
            open
            defaultDate={defaultDate}
            toggleCalendar={toggleCalendar}
            eventsForToday={eventsForToday}
          />
        );
      } else {
        return null;
      }
    }
  }
}

export default ManagerList;
