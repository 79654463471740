import React from "react";
import SelectSearch from "react-select-search";
import {
  Card,
  CardHeader,
  CardContent,
  CardActionArea
} from "@material-ui/core";

import CustomInput from "../../../../components/CustomInput/CustomInput";
import GridContainer from "../../../../components/Grid/GridContainer";
import GridItem from "../../../../components/Grid/GridItem";
import DateInputLocalised from "../../../Shared/DateInputLocalised";
import DeliveriesActionButtons from "../ActionButtons";
import DeliveriesFormItems from "./Items";

class AdminDeliveriesForm extends React.Component {
  constructor(props) {
    super(props);

    const providers = props.accounts.filter(account => account.type !== "user");
    const providerOptions = providers.map(provider => ({
      name: provider.name,
      value: provider.id
    }));
    if (props.delivery) {
      const delivery = { ...props.delivery };
      delivery.createdAt = new Date(delivery.createdAt.seconds * 1000);
      this.state = { providerOptions, delivery, selectedItem: "" };
    } else {
      this.state = {
        providerOptions,
        delivery: {
          id: "",
          accountId: "",
          reference: "",
          items: [],
          total: 0,
          createdAt: new Date()
        },
        selecteditem: ""
      };
    }
  }

  dateChange = date => {
    const newDate = new Date(date);
    const delivery = { ...this.state.delivery };
    delivery.createdAt = newDate;
    this.setState({ delivery });
  };

  onChange = event => {
    const delivery = { ...this.state.delivery };
    delivery[event.target.name] = event.target.value;
    this.setState({ delivery });
  };

  onResourceSelect = provider => {
    const delivery = { ...this.state.delivery };
    delivery.accountId = provider.value;

    this.setState({ delivery });
  };

  onSubmit = () => {
    this.props.submitData(this.state.delivery);
  };

  updateItems = items => {
    const delivery = { ...this.state.delivery };
    delivery.items = items;
    this.setState({ delivery });
  };

  render() {
    const { delivery, providerOptions } = this.state;

    return (
      <Card>
        <CardHeader title="Pedido" />
        <CardContent style={{ margin: "1em" }}>
          <GridContainer>
            <GridItem xs={12} sm={6} style={{ marginTop: "1em" }}>
              <label>Fecha del pedido</label>&nbsp;&nbsp;
              <DateInputLocalised
                dateChange={this.dateChange}
                dateValue={new Date(delivery.createdAt)}
                placeholder="Fecha del pedido"
              />
            </GridItem>
            <GridItem xs={12} sm={6} style={{ marginTop: "1em" }}>
              <label>Selecciona un proveedor</label>
              <SelectSearch
                options={providerOptions}
                onChange={this.onResourceSelect}
                value={delivery.accountId}
                name="provider"
                placeholder="Selecciona un proveedor"
              />
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={6} style={{ marginTop: "1em" }}>
              <label>Referencia</label>&nbsp;&nbsp;
              <CustomInput
                labelText="Referencia"
                inputProps={{
                  id: "reference",
                  name: "reference",
                  autoFocus: true,
                  onChange: this.onChange,
                  value: delivery.reference
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={6} style={{ marginTop: "1em" }}>
              <label>Importe total</label>
              <CustomInput
                formControlProps={{
                  fullWidth: true
                }}
                labelText="Importe"
                inputProps={{
                  id: "total",
                  name: "total",
                  type: "number",
                  onChange: this.onChange,
                  value: delivery.total
                }}
              />
            </GridItem>
          </GridContainer>
        </CardContent>
        <DeliveriesFormItems
          items={delivery.items}
          updateItems={this.updateItems}
        />
        <CardActionArea>
          <DeliveriesActionButtons onSubmit={this.onSubmit} />
        </CardActionArea>
      </Card>
    );
  }
}

export default AdminDeliveriesForm;
