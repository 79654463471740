const orderResults = (results, fieldName) => {
  if (!results || results.length === 0) {
    return [];
  }
  const sortedArray = results.slice(0);

  return sortedArray.sort((a, b) => {
    const nameA = a[fieldName].toLowerCase();

    const nameB = b[fieldName].toLowerCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });
};

const orderResultsByDate = (results, dateField) => {
  if (!results || results.length === 0) {
    return [];
  }
  const sortedArray = results.slice(0);

  return sortedArray.sort((a, b) => {
    const nameA = a[dateField].seconds * 1000;

    const nameB = b[dateField].seconds * 1000;
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });
};

export { orderResults, orderResultsByDate };
