import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import UserForm from "./form";

class EditUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: ""
    };
  }

  onChange = event => {
    this.setState({ name: event.target.value });
  };

  onSubmit = (event, editUser) => {
    event.preventDefault();
    if (!this.state.name || this.state.name.trim().length === 0) {
      return false;
    }

    editUser({ input: { id: this.props.user.id, name: this.state.name } });
    this.props.onClose();
  };

  componentWillReceiveProps = nextProps => {
    if (!nextProps.user) {
      return false;
    }

    if (nextProps.user.name !== this.props.user.name) {
      this.setState({ name: nextProps.user.name });
    } else {
      this.setState({ name: this.props.user.name });
    }
  };

  render() {
    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.onClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Modificar usuario</DialogTitle>
        <DialogContent>
          <UserForm
            name={this.state.name}
            onChange={this.onChange}
            errorName={false}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" onClick={this.onSubmit}>
            Guardar
          </Button>
          <Button onClick={this.props.onClose}>Cancelar</Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default EditUser;
