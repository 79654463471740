import React from "react";
import { withStyles } from "@material-ui/core";

import Table from "../../../../components/Table/Table";
import { formatDate } from "../../../../lib/dates/format";
import extendedTablesStyle from "../../../../assets/jss/views/extendedTablesStyle";

const showItem = (delivery, classes) => [
  <p className={classes.tdNameSmall}>{formatDate(delivery.createdAt)}</p>,
  <p className={classes.tdNameSmall}>{delivery.reference}</p>,
  <p className={classes.tdNumber}>{delivery.items.length}</p>,
  <p className={classes.tdNumber}>{delivery.total}</p>
];

const DeliveryShowItems = ({ deliveries, providerId, classes }) => (
  <Table
    striped
    tableHead={["Fecha", "Referencia", "Num. Productos", "Coste Total"]}
    tableData={deliveries.map(delivery =>
      showItem(delivery, providerId, classes)
    )}
  />
);

export default withStyles(extendedTablesStyle)(DeliveryShowItems);
