import React from "react";
import { css } from "@emotion/core";
import { InputAdornment } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import AddIcon from "@material-ui/icons/Add";

import SharedMessage from "../../../Shared/Message";
import AdminAccountsListItems from "./ListItems";
import AdminAccountsNew from "./New";
import GridContainer from "../../../../components/Grid/GridContainer";
import GridItem from "../../../../components/Grid/GridItem";
import Button from "../../../../components/CustomButtons/Button";
import CustomInput from "../../../../components/CustomInput/CustomInput";

class AdminAccountsList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showEdit: false,
      showNew: false,
      showDelete: false,
      activeAccount: {},
      searchTerm: "",
      accounts: props.accounts || []
    };
  }

  toggleModal = () => {
    this.setState({ showNew: !this.state.showNew });
  };

  componentDidUpdate(newProps) {
    if (newProps.accounts !== this.props.accounts) {
      this.filterAccounts();
    }
  }

  onChange = event => {
    this.setState({ searchTerm: event.target.value }, this.filterAccounts);
  };

  filterAccounts = () => {
    let filterAccounts = this.props.accounts;
    const searchTerm = this.state.searchTerm.trim().toLowerCase();
    if (searchTerm.length > 1) {
      filterAccounts = this.props.accounts.filter(account =>
        account.name.toLowerCase().includes(searchTerm)
      );
    }

    this.setState({ accounts: filterAccounts });
  };

  render() {
    const accounts = this.state.accounts;

    return (
      <div>
        <GridContainer>
          <GridItem xs={12} lg={6}>
            <CustomInput
              labelText="Filtrar"
              inputProps={{
                id: "searchTerm",
                name: "searchTerm",
                autoFocus: true,
                onChange: this.onChange,
                value: this.state.searchTerm,
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                )
              }}
            />
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} lg={12}>
            <Button
              round
              color="linkedin"
              aria-label="Nueva cuenta"
              onClick={() => this.toggleModal()}
            >
              <AddIcon /> Nueva cuenta
            </Button>
          </GridItem>
          <GridItem xs={12} lg={12}>
            {accounts.length > 0 ? (
              <AdminAccountsListItems accounts={accounts} />
            ) : (
              <SharedMessage message="No hemos encontrado ninguna cuenta." />
            )}
          </GridItem>
          <GridItem
            xs={12}
            lg={12}
            className={css`
              margin-top: 1em;
            `}
          >
            <Button
              round
              color="linkedin"
              aria-label="Nueva cuenta"
              onClick={() => this.toggleModal()}
            >
              <AddIcon /> Nueva cuenta
            </Button>

            {this.state.showNew && (
              <AdminAccountsNew toggleModal={() => this.toggleModal()} />
            )}
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

export default AdminAccountsList;
