import React from "react";
import styled from "@emotion/styled";

const CalendarBadge = styled("span")`
  display: inline-block;
  margin-bottom: 0.2em;
  padding: 0.2em 0.4em;
  color: #fff;
  ${props => {
    switch (props.name) {
      case "Almuerzo":
        return "background-color: #f44336;";
      case "Barra":
        return "background-color: transparent; color: #000;";
      case "Comida":
        return "background-color: #00acc1;";
      case "Pending":
        return "background-color: #ffa726;";
      default:
        return "background-color: #4caf50;";
    }
  }};
  border-radius: 5%;
  font-size: 0.9em;
`;

const ReservationBadge = ({ children, name }) => {
  return <CalendarBadge name={name}>{children}</CalendarBadge>;
};

export default ReservationBadge;
