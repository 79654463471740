import React from "react";

import Table from "../../../components/Table/Table";
import AdminProvidersEdit from "./Edit";
import AdminProvidersItemOptions from "./ItemOptions";
import { Link } from "@material-ui/core";
import { Providers } from "../../../lib/database/";
import DeleteDialog from "../../Shared/Dialogs/Delete";

class AdminProvidersListItems extends React.Component {
  state = {
    showEdit: false,
    showDelete: false,
    activeProvider: {}
  };

  toggleModal = (name, activeProvider = {}) => {
    switch (name) {
      case "edit":
        this.setState({ showEdit: !this.state.showEdit, activeProvider });
        break;
      case "delete":
        this.setState({ showDelete: !this.state.showDelete, activeProvider });
        break;
      default:
        break;
    }
  };

  onClose = () => {
    this.setState({ showDelete: false });
  };

  onDelete = () => {
    const providerId = this.state.activeProvider.id;
    Providers.doc(providerId)
      .update({ active: false })
      .then(() => {
        this.setState({ showDelete: false });
      });
  };

  showProviders = () => {
    return this.props.providers.map(provider => [
      <Link href={`/admin/proveedor/${provider.id}`}>{provider.name}</Link>,
      provider.tags.join(", "),
      this.optionsForProvider(provider)
    ]);
  };

  optionsForProvider = provider => (
    <AdminProvidersItemOptions
      provider={provider}
      toggleModal={this.toggleModal}
    />
  );

  render() {
    return (
      <div>
        <Table
          striped
          tableHead={["Nombre", "Etiquetas", "Opciones"]}
          tableData={this.showProviders()}
        />
        {this.state.showEdit && (
          <AdminProvidersEdit
            toggleModal={() => this.toggleModal("edit")}
            activeProvider={this.state.activeProvider}
          />
        )}
        {this.state.showDelete && (
          <DeleteDialog
            title="Borrar proveedor"
            onClose={this.onClose}
            item={this.state.activeProvider}
            open
            onDelete={this.onDelete}
          />
        )}
      </div>
    );
  }
}

export default AdminProvidersListItems;
