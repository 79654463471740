import React from "react";
import { Route, Switch } from "react-router";

import ShowCalendar from "../Show";
import CalendarManager from "../Manager";
import ListReservations from "../List";

class Schedule extends React.Component {
  state = {
    openCalendar: false
  };

  daySelected = day => {
    this.props.onSelect(day);
    this.setState({ openCalendar: true });
  };

  toggleCalendar = () => {
    this.setState({ openCalendar: !this.state.openCalendar });
  };

  render() {
    const { defaultDate } = this.props;

    return (
      <div>
        <Switch>
          <Route path="/reservas/listado" render={() => <ListReservations />} />
          <Route
            path="/reservas"
            render={() => (
              <ShowCalendar
                defaultDate={defaultDate}
                onSelect={this.daySelected}
              />
            )}
          />
        </Switch>

        <CalendarManager
          toggleCalendar={this.toggleCalendar}
          defaultDate={defaultDate}
          open={this.state.openCalendar}
        />
      </div>
    );
  }
}

export default Schedule;
