import React, { useEffect, useState } from "react";

import LoadingDialog from "../../../Shared/Dialogs/Loading";
import { Bookings, FirebaseResults } from "../../../../lib/database/";
import ProductsShowBookings from "./Bookings";

function ProductsShowDeliveries({ productId }) {
  const [localState, setLocalState] = useState({
    loading: false,
    bookings: []
  });

  useEffect(
    function() {
      setLocalState({ loading: true, deliveries: [] });
      return Bookings.orderBy("createdAt")
        .where("productIds", "array-contains", productId)
        .onSnapshot(docs => {
          setLocalState({
            bookings: FirebaseResults.map(docs),
            loading: false
          });
        });
    },
    [productId]
  );

  if (localState.loading) {
    return <LoadingDialog />;
  }

  return (
    <ProductsShowBookings
      bookings={localState.bookings}
      productId={productId}
    />
  );
}

export default ProductsShowDeliveries;
