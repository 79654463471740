import React, { useState, useEffect } from "react";

import LoadingDialog from "../../Shared/Dialogs/Loading";
import SalesCategoriesList from "./List";
import { Bookings, FirebaseResults } from "../../../lib/database/";
import { salesFromCategory } from "../../../lib/bookings/finder";

function SalesCategoriesFetch({ queryParams, categories, products }) {
  const [fetchEntries, setFetchEntries] = useState({
    loading: false,
    sales: []
  });

  useEffect(
    function() {
      function loadData() {
        setFetchEntries({ loading: true });

        let query = Bookings.orderBy("startDate")
          .where("startDate", ">=", queryParams.startDate)
          .where("startDate", "<=", queryParams.endDate);

        return query.get().then(docs => {
          const bookings = FirebaseResults.map(docs);
          setFetchEntries({
            sales: salesFromCategory(
              bookings,
              queryParams.categoryId,
              categories,
              products
            ),
            loading: false
          });
        });
      }

      loadData();
    },
    [queryParams, products, categories]
  );

  const { sales, loading } = fetchEntries;

  if (loading) {
    return <LoadingDialog />;
  }

  return (
    <SalesCategoriesList
      sales={sales}
      categories={categories}
      queryParams={queryParams}
    />
  );
}

export default SalesCategoriesFetch;
