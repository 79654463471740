import * as React from "react";

import Table from "../../components/Table/Table";
import { FirebaseResults, LogEntries } from "../../lib/database/";
import { formatDate } from "../../lib/dates/format";

class ListPayments extends React.Component {
  unsubscribe = "";
  state = {
    loading: true,
    logEntries: []
  };

  componentDidMount() {
    let accountSelector = "bookingId";
    let accountId = this.props.bookingId;
    if (this.props.deliveryId) {
      accountSelector = "deliveryId";
      accountId = this.props.deliveryId;
    }
    this.unsubscribe = LogEntries.where(
      accountSelector,
      "==",
      accountId
    ).onSnapshot(docs => {
      this.setState({
        logEntries: FirebaseResults.map(docs),
        loading: false
      });
    });
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  showItems() {
    return this.state.logEntries.map(logEntry => [
      formatDate(logEntry.createdAt),
      logEntry.total,
      logEntry.incomeName,
      logEntry.outcomeName
    ]);
  }

  render() {
    if (this.state.loading || this.state.logEntries.length === 0) {
      return null;
    }

    return (
      <div className="m-4 p-4 shadow-lg rounded">
        <h3 className="text-2xl text-blue-700 font-bold">Pagos</h3>
        <Table
          tableHead={["Fecha", "Importe", "Entrada", "Salida"]}
          tableData={this.showItems()}
        />
      </div>
    );
  }
}

export default ListPayments;
