import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import withStyles from "@material-ui/core/styles/withStyles";
import Pay from "@material-ui/icons/EuroSymbol";
import Delete from "@material-ui/icons/Delete";

import Button from "../../../components/CustomButtons/Button";
import Table from "../../../components/Table/Table";
import extendedTablesStyle from "../../../assets/jss/views/extendedTablesStyle";
import DeleteBooking from "../../Activities/Delete";
import { canEditBooking } from "../../../lib/users/permission";
import { findAccountById } from "../../../lib/accounts/finder";
import { BookingsPersistence } from "../../../lib/bookings/persistence";

class BookingsList extends React.Component {
  state = {
    showDelete: false,
    booking: {}
  };

  payButton = booking => (
    <Button
      key="pay"
      color="primary"
      size="sm"
      onClick={() => this.props.history.push(`/reservas/ticket/${booking.id}`)}
    >
      <Pay /> Pagar
    </Button>
  );

  onDelete = booking => {
    BookingsPersistence.remove(booking.id).then(() => {
      this.setState({ showDelete: false });
    });
  };

  deleteButton = booking => (
    <Button
      key="delete"
      color="danger"
      size="sm"
      onClick={() => this.setState({ showDelete: true, booking })}
    >
      <Delete /> Borrar
    </Button>
  );

  showOptions = booking => [
    this.payButton(booking),
    this.deleteButton(booking)
  ];

  showItems = () => {
    return this.props.items.map(item => {
      const newArray = Array.from(item.content);
      const account = findAccountById(
        this.props.accounts,
        item.booking.accountId
      );

      if (
        canEditBooking(
          this.props.profile,
          account.originId,
          item.booking.status
        )
      ) {
        newArray.push(this.showOptions(item.booking));
      }

      return newArray;
    });
  };

  render() {
    const { head } = this.props;
    const { showDelete, booking } = this.state;

    return (
      <div>
        <Table tableHead={head} tableData={this.showItems()} />
        {showDelete && (
          <DeleteBooking
            open
            item={booking}
            onClose={() => this.setState({ showDelete: false })}
            onDelete={booking => this.onDelete(booking)}
          />
        )}
      </div>
    );
  }
}

export default connect(({ firebase: { profile } }) => ({ profile }))(
  withStyles(extendedTablesStyle)(withRouter(BookingsList))
);
