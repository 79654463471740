import { beginningAndEndOfDay } from "../dates/calendar";

const findEventsForDay = (events, day) => {
  const [beginningDay, endDay] = beginningAndEndOfDay(day);

  return events.filter(event => {
    const startDate = new Date(event.startDate.seconds * 1000);
    return startDate >= beginningDay && startDate <= endDay;
  });
};

const filterEventsByUser = (events, accountId) =>
  events.filter(event => event.accountId === accountId);

const groupEventsBySlot = events => {
  const results = {};

  events.forEach(event => {
    if (results[event.slotName]) {
      results[event.slotName] += parseInt(event.seats);
    } else {
      results[event.slotName] = parseInt(event.seats);
    }
  });

  return results;
};

const totalReservedSeats = (events, slotId) =>
  events.reduce((totalSeats, event) => {
    if (event.slotId === slotId) {
      totalSeats += event.seats;
    }

    return totalSeats;
  }, 0);

export {
  groupEventsBySlot,
  findEventsForDay,
  totalReservedSeats,
  filterEventsByUser
};
