import React from "react";
import Dialog from "@material-ui/core/Dialog";

import AdminProvidersForm from "./Form";
import { Accounts, Providers } from "../../../lib/database/";

const toggleNew = toggleModal => {
  toggleModal("new");
};

const onSubmit = (providerInfo, toggleModal) => {
  const newProvider = {
    name: providerInfo.name,
    tags: providerInfo.tags,
    oldId: providerInfo.oldId
  };

  Providers.add(newProvider).then(doc => {
    const newAccount = {
      name: newProvider.name,
      tags: newProvider.tags,
      originId: doc.id,
      active: true,
      type: "provider"
    };
    Accounts.add(newAccount);
    toggleNew(toggleModal);
  });
};

const AdminProvidersNew = ({ toggleModal }) => (
  <Dialog open>
    <AdminProvidersForm
      toggleModal={() => toggleNew(toggleModal)}
      submitData={providerInfo => onSubmit(providerInfo, toggleModal)}
    />
  </Dialog>
);

export default AdminProvidersNew;
