const accounts = (state = [], action) => {
  switch (action.type) {
    case "UPDATE_ACCOUNTS":
      return action.accounts;
    default:
      return state;
  }
};

export { accounts };
