import React from "react";

import AdminProductsList from "./List";
import { FirebaseResults, Products } from "../../../lib/database/";

class AdminProductsFetch extends React.Component {
  state = {
    products: []
  };

  stopSubscription = null;

  componentDidMount = () => {
    this.loadData();
  };

  componentDidUpdate(prevProps) {
    if (this.props.categoryId !== prevProps.categoryId) {
      this.loadData();
    }
  }

  componentWillUnmount = () => {
    this.stopSubscription();
  };

  loadData() {
    this.stopSubscription = Products.orderBy("title")
      .where("categoryId", "==", this.props.categoryId)
      .onSnapshot(docs => {
        this.setState({ products: FirebaseResults.map(docs) });
      });
  }

  render() {
    if (!this.props.categoryId) {
      return null;
    }

    return (
      <div>
        <AdminProductsList
          categoryId={this.props.categoryId}
          products={this.state.products}
        />
      </div>
    );
  }
}

export default AdminProductsFetch;
