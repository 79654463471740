import React from "react";
import styled from "@emotion/styled";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

const FormContainer = styled("div")`
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 1em;
`;

const ResourceForm = ({
  name,
  seats,
  errorName,
  errorSeats,
  oldId,
  available,
  onChange
}) => (
  <FormContainer>
    <TextField
      error={errorName}
      name="name"
      id="name"
      label="Nombre"
      value={name}
      onChange={onChange}
      helperText="Nombre de la actividad"
    />
    <TextField
      error={errorSeats}
      name="seats"
      id="seats"
      label="Plazas"
      value={seats}
      type="number"
      onChange={onChange}
      helperText="Número de plazas disponibles"
    />
    <TextField
      error={errorName}
      name="oldId"
      id="oldId"
      label="oldId"
      value={oldId}
      onChange={onChange}
      helperText="ID en dynamoDb"
    />
    <FormControlLabel
      control={
        <Checkbox
          checked={available}
          name="available"
          onChange={onChange}
          value={available ? "false" : "true"}
        />
      }
      label="Disponible"
    />
  </FormContainer>
);

export default ResourceForm;
