const defaultMessage = {
  showMessage: false,
  message: ""
};
const globalMessage = (state = defaultMessage, action) => {
  switch (action.type) {
    case "ADD_GLOBAL_MESSAGE":
      return {
        ...state,
        showMessage: true,
        message: action.message
      };
    case "CLEAR_GLOBAL_MESSAGE":
      return {
        ...state,
        ...defaultMessage
      };
    default:
      return state;
  }
};

export { globalMessage };
