import React from "react";
import Dialog from "@material-ui/core/Dialog";

import AdminCategoriesForm from "./Form";
import { Categories } from "../../../lib/database/";

const toggleEdit = toggleModal => {
  toggleModal("edit");
};

const onSubmit = async (category, toggleModal) => {
  Categories.doc(category.id).update({
    title: category.title
  });
  toggleModal("edit");
};

const AdminCategoriesEdit = ({ activeCategory, toggleModal }) => {
  return (
    <Dialog open>
      <AdminCategoriesForm
        activeCategory={activeCategory}
        toggleModal={() => toggleEdit(toggleModal)}
        submitData={info => onSubmit(info, toggleModal)}
      />
    </Dialog>
  );
};

export default AdminCategoriesEdit;
