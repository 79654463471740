import React from "react";
import Dialog from "@material-ui/core/Dialog";

import AdminProductsForm from "./Form";
import { Products } from "../../../lib/database/";

const toggleEdit = toggleModal => {
  toggleModal("edit");
};

const onSubmit = async (product, toggleModal) => {
  Products.doc(product.id).update({
    title: product.title,
    categoryId: product.categoryId,
    s3Image: product.s3Image || "",
    image: product.image || "",
    tags: product.tags,
    vat: parseFloat(product.vat),
    sellPrice: parseFloat(product.sellPrice),
    buyPrice: parseFloat(product.buyPrice),
    stockAmount: parseFloat(product.stockAmount),
    active: product.active
  });
  toggleModal("edit");
};

const AdminProductsEdit = ({ activeProduct, toggleModal }) => {
  return (
    <Dialog open>
      <AdminProductsForm
        activeProduct={activeProduct}
        toggleModal={() => toggleEdit(toggleModal)}
        submitData={info => onSubmit(info, toggleModal)}
      />
    </Dialog>
  );
};

export default AdminProductsEdit;
