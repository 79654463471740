import React from "react";
import { connect } from "react-redux";
import styled from "@emotion/styled";

import {
  findEventsForDay,
  groupEventsBySlot
} from "../../../lib/events/finder";
import ReservationBadge from "../../Shared/ReservationBadge";

const CapacityContainer = styled("div")`
  text-align: center;
  margin-top: 0.3em;
  @media (max-width: 740px) {
    font-size: 0.8em;
  }
`;

const ShowCapacity = ({ day, bookings, slots }) => {
  const dailyEvents = findEventsForDay(bookings, day);
  const occupation = groupEventsBySlot(dailyEvents);

  return (
    <CapacityContainer>
      {slots.map(slot => {
        return occupation[slot.name] ? (
          <ReservationBadge key={slot.id} name={slot.name}>
            {`${slot.name}: ${occupation[slot.name]}`}
          </ReservationBadge>
        ) : null;
      })}
    </CapacityContainer>
  );
};

const mapStateToProps = state => {
  return {
    bookings: state.mainData.bookings,
    slots: state.mainData.slots
  };
};

export default connect(mapStateToProps)(ShowCapacity);
