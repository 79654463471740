import React from "react";
import { firebaseConnect } from "react-redux-firebase";
import { compose } from "redux";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import UserForm from "./form";

class NewUser extends React.Component {
  state = {
    name: "",
    email: "",
    role: "user",
    enabled: true
  };

  onChange = event => {
    if (event.target.name === "enabled") {
      this.setState({ enabled: event.target.value === "true" });
    } else {
      this.setState({ [event.target.name]: event.target.value });
    }
  };

  onSubmit = event => {
    event.preventDefault();
    if (!this.state.name || this.state.name.trim().length === 0) {
      return false;
    }

    const userInfo = {
      email: this.state.email,
      password: "cambiame"
    };

    const profileInfo = {
      name: this.state.name,
      active: true,
      role: this.state.role
    };

    this.props.firebase
      .createUser(userInfo, profileInfo)
      .then(credentials => {
        console.log(credentials);
        this.props.onClose();
      })
      .catch(error => {
        console.log(error);
      });
  };

  render() {
    const { open, onClose } = this.props;
    return (
      <Dialog
        open={open}
        onClose={this.props.onClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Nuevo usuario</DialogTitle>
        <DialogContent>
          <UserForm name={this.state.name} onChange={this.onChange} />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" onClick={this.onSubmit}>
            Guardar
          </Button>
          <Button onClick={onClose}>Cancelar</Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default compose(
  firebaseConnect(),
  connect(({ firebase: { auth } }) => ({ auth }))
)(NewUser);
