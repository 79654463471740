import React from "react";
import { connect } from "react-redux";

import AdminReservationsList from "./List";
import { Bookings, FirebaseResults } from "../../../lib/database/";
import { updateMonth } from "../../../lib/dates/format";
import LoadingDialog from "../../Shared/Dialogs/Loading";
import { convertBookings } from "../../../lib/log_entries/converter";

class AdminReservations extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showDialog: false,
      defaultDate: props.queryParams.date,
      bookings: [],
      loading: false
    };
  }

  closeSubscription = null;

  componentDidMount = () => {
    this.loadData();
  };

  componentDidUpdate(prevProps) {
    if (prevProps.queryParams !== this.props.queryParams) {
      this.setState(
        { defaultDate: this.props.queryParams.date },
        this.loadData
      );
    }
  }

  componentWillUnmount = () => {
    this.closeSubscription();
  };

  loadData() {
    this.setState({ loading: true });
    let query = Bookings.orderBy("startDate")
      .where("startDate", ">=", this.props.queryParams.startDate)
      .where("startDate", "<=", this.props.queryParams.endDate);

    if (this.props.queryParams.accountId) {
      query = query.where("accountId", "==", this.props.queryParams.accountId);
    }

    if (
      this.props.queryParams.status &&
      this.props.queryParams.status !== "all"
    ) {
      query = query.where("status", "==", this.props.queryParams.status);
    }

    this.closeSubscription = query.onSnapshot(docs => {
      this.setState({ bookings: FirebaseResults.map(docs), loading: false });
    });
  }

  handleClickOpen = defaultDate => {
    this.setState({ defaultDate, showDialog: true });
  };

  onClose = () => this.setState({ showDialog: false });

  changeMonth = newMonth => {
    const newDate = updateMonth(this.state.defaultDate, newMonth);
    this.setState({ defaultDate: newDate }, this.loadData);
  };

  render() {
    const { bookings, defaultDate, loading } = this.state;

    if (loading) {
      return <LoadingDialog />;
    }

    convertBookings(bookings, this.props.accounts);

    return (
      <AdminReservationsList
        bookings={bookings}
        changeMonth={this.changeMonth}
        defaultDate={defaultDate}
        onSelect={this.handleClickOpen}
        accounts={this.props.accounts}
      />
    );
  }
}

const mapStateToProps = state => ({
  queryParams: state.mainData.reservationsQuery,
  accounts: state.mainData.accounts
});

export default connect(mapStateToProps)(AdminReservations);
