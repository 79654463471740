import React from "react";
import styled from "@emotion/styled";

const MonthRowHeader = styled("div")`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  border: 1px solid #fff;
  background-color: #b5a33f;
  text-align: center;
  color: #fff;
  font-weight: bold;
  padding: 0.2em;
`;

const WeekHeader = () => (
  <MonthRowHeader>
    <div>Lun.</div>
    <div>Mar.</div>
    <div>Mié.</div>
    <div>Jue.</div>
    <div>Vie.</div>
    <div>Sáb.</div>
    <div>Dom.</div>
  </MonthRowHeader>
);

export default WeekHeader;
