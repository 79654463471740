import { ProductCategories } from "../products/categories";

function BasketPersistence(basket) {
  function add(product) {
    const newBasket = Array.from(basket);
    const productIndex = newBasket.findIndex(
      element => product.id === element.productId
    );

    if (!~productIndex) {
      newBasket.unshift({
        productId: product.id,
        quantity: 0,
        title: product.title,
        vat: product.vat || 0,
        price: parseFloat(product.sellPrice),
        isExtra: ProductCategories.isExtraProduct(product)
      });
    } else {
      const newElement = newBasket[productIndex];
      newElement.quantity += 1;
      newBasket[productIndex] = newElement;
    }

    return newBasket;
  }

  function update(item, quantity) {
    const newBasket = Array.from(basket);
    const productIndex = newBasket.findIndex(
      element => item.productId === element.productId
    );
    if (productIndex !== -1) {
      const newElement = newBasket[productIndex];
      newElement.quantity = parseFloat(quantity);
      newBasket[productIndex] = newElement;
    }

    return newBasket;
  }

  function remove(productId) {
    return Array.from(basket).filter(
      element => element.productId !== productId
    );
  }

  return Object.freeze({ add, update, remove });
}

export { BasketPersistence };
