import React from "react";
import styled from "@emotion/styled";

import ShowCapacity from "./Capacity";
import DayNumber from "./DayNumber";
import { dateIsToday, dateIsOtherMonth } from "../../../lib/dates/calendar";

const CalendarMonthDate = styled("div")`
  height: 8em;
  border: 1px solid #eee;
`;

const DayMonth = ({ day, onSelect, defaultDate }) => (
  <CalendarMonthDate onClick={() => onSelect(day)}>
    <DayNumber
      day={day}
      dateIsToday={dateIsToday(day)}
      otherMonth={dateIsOtherMonth(defaultDate, day)}
    />
    <ShowCapacity day={day} />
  </CalendarMonthDate>
);

export default DayMonth;
