import { convertToDinero } from "../utils/format";

const findAccountById = (accounts, accountId) =>
  accounts.find(account => account.id === accountId);

const findAccountByOriginId = (accounts, originId) =>
  accounts.find(account => account.originId === originId);

const findAccountByName = (accounts, name) =>
  accounts.find(account => account.name === name);

function addOrReplaceAccount(currentAccounts, account) {
  const accounts = [...currentAccounts];
  account.total = convertToDinero(account.total);
  const accountIndex = currentAccounts.findIndex(
    currentAccount => currentAccount.id === account.id
  );
  if (!~accountIndex) {
    accounts.push(account);
  } else {
    if (account.type === "delete") {
      accounts.splice(accountIndex, 1);
    } else {
      accounts[accountIndex] = account;
    }
  }

  return accounts;
}

export {
  addOrReplaceAccount,
  findAccountById,
  findAccountByOriginId,
  findAccountByName
};
