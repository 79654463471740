import React from "react";

import DeleteDialog from "../../Shared/Dialogs/Delete";

const DELETE_CATEGORY = `
  mutation DeleteCategory($input: DeleteCategoriesInput!) {
    deleteCategories(input: $input) {
      id
    }
  }
`;

const AdminDeleteCategory = ({ onClose, item, open }) => (
  <DeleteDialog
    title="Borrar categoría"
    onClose={onClose}
    item={item}
    mutationQuery={DELETE_CATEGORY}
    open={open}
  />
);

export default AdminDeleteCategory;
