import React from "react";

import { Storage } from "../../config/firebase";
// import { Products } from "../../lib/database/";

class ProductItem extends React.Component {
  state = {
    imageSrc: "",
    open: false
  };

  productImageName = () => {
    // if (this.props.product.imageSrc) {
    //   this.setState({ imageSrc: this.props.product.imageSrc });
    //   return true;
    // }

    if (!this.props.product.image || this.state.imageSrc) {
      return this.state.imageSrc;
    }

    const fileRef = Storage.ref().child(this.props.product.image);
    fileRef.getDownloadURL().then(url => {
      this.setState({ imageSrc: url });
      // Products.doc(this.props.product.id).update({ imageSrc: url });
    });
  };

  selectItem = product => {
    this.props.onSelect(product);
    this.setState({ open: true });
  };

  onSubmit = (quantity, description) => {
    this.props.onUpdate(this.props.product, quantity, description);
    this.toggle();
  };

  toggle = () => {
    this.setState({ open: !this.state.open });
  };

  render() {
    const { product, onSelect } = this.props;
    this.productImageName();
    return (
      <div className="w:1/2 md:w-1/6">
        <button
          className="p-1 flex flex-col justify-center items-center"
          onClick={() => onSelect(product)}
        >
          {product.s3Image ? (
            <img
              className="rounded w-12 h-12 md:w-24 md:h-24 object-cover"
              src={`https://depuracepaaws-userfiles-mobilehub-933783480.s3.eu-central-1.amazonaws.com/public/${product.s3Image}`}
              alt={product.name}
            />
          ) : (
            <img
              className="rounded w-12 h-12 md:w-24 md:h-24 object-cover"
              src={this.state.imageSrc}
              alt={product.name}
            />
          )}

          <p className="text-xs text-gray-900">
            {product.title}
            <br />
            <span className="text-gray-700">P.V.P. {product.sellPrice}</span>
          </p>
        </button>
      </div>
    );
  }
}

export default ProductItem;
