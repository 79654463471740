import React from "react";

const NumberDialog = ({ currentProduct, onSubmit }) => {
  let quantity = currentProduct.quantity.toString();
  const onChange = number => {
    if (number === "." && ~quantity.indexOf(".")) {
      return;
    }
    let newValue = quantity + number;

    if (quantity === 0) {
      newValue = quantity;
    }

    submitQty(newValue);
  };

  const onDelete = () => {
    if (quantity.length === 1) {
      onSubmit("0");
    } else {
      submitQty(quantity.slice(0, -1));
    }
  };

  const submitQty = newValue => {
    const newNumber = parseFloat(newValue);

    onSubmit(newNumber);
  };

  return (
    <div>
      <div className="p-4 rounded bg-blue-100">
        <h3 className="text-lg text-blue-700">{currentProduct.title}</h3>
        <p className="mt-2 text-3xl text-right">{quantity}</p>
      </div>
      <div>
        <div className="mt-2 flex flex-wrap justify-between">
          <div className="w-1/3 pr-1">
            <button
              className="mt-1 w-full rounded shadow text-white text-4xl bg-blue-400"
              color="info"
              onClick={() => onChange("7")}
            >
              7
            </button>
          </div>
          <div className="w-1/3 pr-1">
            <button
              className="mt-1 w-full rounded shadow text-white text-4xl bg-blue-400"
              onClick={() => onChange("8")}
            >
              8
            </button>
          </div>
          <div className="w-1/3 pr-1">
            <button
              className="mt-1 w-full rounded shadow text-white text-4xl bg-blue-400"
              color="info"
              onClick={() => onChange("9")}
            >
              9
            </button>
          </div>
          <div className="w-1/3 pr-1">
            <button
              className="mt-1 w-full rounded shadow text-white text-4xl bg-blue-400"
              color="info"
              onClick={() => onChange("4")}
            >
              4
            </button>
          </div>
          <div className="w-1/3 pr-1">
            <button
              className="mt-1 w-full rounded shadow text-white text-4xl bg-blue-400"
              color="info"
              onClick={() => onChange("5")}
            >
              5
            </button>
          </div>
          <div className="w-1/3 pr-1">
            <button
              className="mt-1 w-full rounded shadow text-white text-4xl bg-blue-400"
              color="info"
              onClick={() => onChange("6")}
            >
              6
            </button>
          </div>
          <div className="w-1/3 pr-1">
            <button
              className="mt-1 w-full rounded shadow text-white text-4xl bg-blue-400"
              color="info"
              onClick={() => onChange("1")}
            >
              1
            </button>
          </div>
          <div className="w-1/3 pr-1">
            <button
              className="mt-1 w-full rounded shadow text-white text-4xl bg-blue-400"
              color="info"
              onClick={() => onChange("2")}
            >
              2
            </button>
          </div>
          <div className="w-1/3 pr-1">
            <button
              className="mt-1 w-full rounded shadow text-white text-4xl bg-blue-400"
              color="info"
              onClick={() => onChange("3")}
            >
              3
            </button>
          </div>
          <div className="w-1/3 pr-1">
            <button
              className="mt-1 w-full rounded shadow text-white text-4xl bg-teal-400"
              onClick={() => onDelete()}
            >
              Borrar
            </button>
          </div>
          <div className="w-1/3 pr-1">
            <button
              className="mt-1 w-full rounded shadow text-white text-4xl bg-blue-400"
              color="info"
              onClick={() => onChange("0")}
            >
              0
            </button>
          </div>
          <div className="w-1/3 pr-1">
            <button
              className="mt-1 w-full rounded shadow text-white text-4xl bg-blue-400"
              color="primary"
              onClick={() => onChange(".")}
            >
              .
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NumberDialog;
