import { combineReducers } from "redux";
import { providers } from "./providers";
import { users } from "./users";
import { accounts } from "./accounts";
import { bookings } from "./bookings";
import { slots } from "./slots";
import { resources } from "./resources";
import { logEntryQuery } from "./log_entry_query";
import { deliveryQuery } from "./delivery_query";
import { reservationsQuery } from "./reservations";
import { globalMessage } from "./global_message";
import { salesQuery } from "./sales";
import { salesProductsQuery } from "./sales_products";
import { layout } from "./layout";

export default combineReducers({
  providers,
  users,
  bookings,
  slots,
  accounts,
  resources,
  logEntryQuery,
  globalMessage,
  deliveryQuery,
  reservationsQuery,
  salesQuery,
  salesProductsQuery,
  layout
});
