const updateUserInfo = userInfo => ({
  type: "UPDATE_USER_INFO",
  userInfo
});

const updateProviders = providers => ({
  type: "UPDATE_PROVIDERS",
  providers
});

const updateLayoutId = layoutInfo => ({
  type: "UPDATE_LAYOUT_ID",
  layoutInfo
});

const updateActiveUsers = users => ({
  type: "UPDATE_ACTIVE_USERS",
  users
});

const updateAccounts = accounts => ({
  type: "UPDATE_ACCOUNTS",
  accounts
});

const updateBookings = bookings => ({
  type: "UPDATE_BOOKINGS",
  bookings
});

const updateSlots = slots => ({
  type: "UPDATE_SLOTS",
  slots
});

const updateResources = resources => ({
  type: "UPDATE_RESOURCES",
  resources
});

const updateReservationParams = params => ({
  type: "UPDATE_RESERVATION_PARAMS",
  params
});

const updateLogEntryParams = params => ({
  type: "UPDATE_QUERY_PARAMS",
  params
});

const updateDeliveryParams = params => ({
  type: "UPDATE_DELIVERY_QUERY",
  params
});

const updateSalesParams = params => ({
  type: "UPDATE_SALES_QUERY",
  params
});

const updateSalesProductsParams = params => ({
  type: "UPDATE_SALES_PRODUCTS_QUERY",
  params
});

const addGlobalMessage = message => {
  return {
    type: "ADD_GLOBAL_MESSAGE",
    message
  };
};
const clearGlobalMessage = () => {
  return {
    type: "CLEAR_GLOBAL_MESSAGE"
  };
};

export {
  updateUserInfo,
  updateProviders,
  updateActiveUsers,
  updateBookings,
  updateAccounts,
  updateSlots,
  updateResources,
  updateLogEntryParams,
  addGlobalMessage,
  clearGlobalMessage,
  updateDeliveryParams,
  updateReservationParams,
  updateSalesParams,
  updateSalesProductsParams,
  updateLayoutId
};
