import React from "react";
import styled from "@emotion/styled";
import {
  TextField,
  FormControl,
  FormControlLabel,
  Checkbox
} from "@material-ui/core";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import Check from "@material-ui/icons/Check";

const FormContainer = styled("div")`
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 1em;
`;

const SlotForm = ({
  name,
  startHour,
  endHour,
  requireSeats,
  toggleCheckBox,
  onChange
}) => (
  <FormContainer>
    <TextField
      name="name"
      id="name"
      label="Nombre"
      value={name}
      onChange={onChange}
      helperText="Nombre de la actividad"
    />
    <TextField
      name="startHour"
      id="startHour"
      label="Inicio"
      value={startHour}
      onChange={onChange}
      helperText="Hora de inicio"
    />
    <TextField
      name="endHour"
      id="endHour"
      label="Fin"
      value={endHour}
      onChange={onChange}
      helperText="Hora de fin"
    />
    <FormControl fullWidth>
      <FormControlLabel
        control={
          <Checkbox
            onChange={toggleCheckBox}
            icon={<CheckBoxOutlineBlankIcon />}
            checkedIcon={<Check />}
            checked={requireSeats}
          />
        }
        label="Incluir comensales?"
      />
    </FormControl>
  </FormContainer>
);

export default SlotForm;
