import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core";

import SharedMessage from "../../Shared/Message";
import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem";
import CustomInput from "../../../components/CustomInput/CustomInput";
import Button from "../../../components/CustomButtons/Button";
import Card from "../../../components/Card/Card.jsx";
import CardHeader from "../../../components/Card/CardHeader.jsx";
import CardText from "../../../components/Card/CardText.jsx";
import CardBody from "../../../components/Card/CardBody.jsx";
import { cardTitle } from "../../../assets/jss/material-dashboard-pro-react.jsx";

import { findAccountByName } from "../../../lib/accounts/finder";
import DateInputLocalised from "../../Shared/DateInputLocalised";
import { removeSociedad } from "../../../lib/users/filter";
import { generateMonthlyFee } from "../../../lib/log_entries/monthly_fee";

const styles = {
  cardTitle,
  cardTitleWhite: {
    ...cardTitle,
    color: "#FFFFFF",
    marginTop: "0"
  },
  cardCategoryWhite: {
    margin: "0",
    color: "rgba(255, 255, 255, 0.8)",
    fontSize: ".875rem"
  },
  cardCategory: {
    color: "#999999",
    marginTop: "10px"
  }
};

class AdminMonthlyFee extends React.Component {
  state = {
    activeUsers: [],
    entryDate: new Date(),
    total: 50,
    description: "Cuota mensual "
  };

  componentDidMount = () => {
    const activeUsers = removeSociedad(this.props.users);

    this.setState({ activeUsers });
  };

  dateChange = date => {
    this.setState({ entryDate: new Date(date) });
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  onSubmit = event => {
    event.preventDefault();

    this.state.activeUsers.forEach(user => {
      const incomeAccount = findAccountByName(this.props.accounts, user.name);
      const bankAccount = findAccountByName(this.props.accounts, "Caja Rural");

      generateMonthlyFee({
        incomeAccount,
        bankAccount,
        user,
        total: this.state.total,
        date: this.state.entryDate,
        description: this.state.description
      });
    });
  };

  render() {
    const { entryDate, activeUsers, description, total } = this.state;
    const { classes } = this.props;

    if (activeUsers.length === 0) {
      return <SharedMessage message="No hay usuarios activos. Qué raro no?" />;
    }

    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={6}>
          <Card>
            <CardHeader color="warning" text>
              <CardText color="warning">
                <h4 className={classes.cardTitleWhite}>Generador de cuotas</h4>
                <h4 className={classes.cardCategoryWhite}>
                  Genera un apunte para cada usuario activo
                </h4>
              </CardText>
            </CardHeader>
            <CardBody>
              <form onSubmit={this.onSubmit}>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <DateInputLocalised
                      dateChange={this.dateChange}
                      dateValue={entryDate}
                      placeholder="Fecha del apunte"
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <CustomInput
                      labelText="Descripción"
                      inputProps={{
                        id: "description",
                        name: "description",
                        autoFocus: true,
                        onChange: this.onChange,
                        value: description
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <CustomInput
                      labelText="Importe cuota"
                      inputProps={{
                        id: "total",
                        name: "total",
                        type: "number",
                        onChange: this.onChange,
                        value: total
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <Button type="submit" color="tumblr">
                      Generar apuntes
                    </Button>
                  </GridItem>
                </GridContainer>
              </form>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

const mapStateToProps = state => {
  return {
    users: state.mainData.users,
    accounts: state.mainData.accounts
  };
};

export default connect(mapStateToProps)(withStyles(styles)(AdminMonthlyFee));
