import { LogEntries } from "../database";
import { convertToDinero } from "../utils/format";

function generateMonthlyFee({
  incomeAccount,
  bankAccount,
  user,
  total,
  date,
  description
}) {
  const totalFee = parseFloat(total);
  const amount = convertToDinero(totalFee);
  const logEntryInfo = {
    type: "income",
    title: `${description} ${incomeAccount.name}`,
    tags: ["cuota mensual"],
    userId: user.id,
    userName: user.name,
    total: totalFee,
    createdAt: date,
    approved: true,
    incomeId: incomeAccount.id,
    incomeName: incomeAccount.name,
    outcomeId: "i0qajrTd7AlhwhsuV3xl",
    outcomeName: "Cuota mensual"
  };
  logEntryInfo.accounts = [
    {
      id: incomeAccount.id,
      name: incomeAccount.name,
      total: amount,
      type: "debit"
    },
    {
      id: "i0qajrTd7AlhwhsuV3xl",
      name: "Cuota mensual",
      total: amount,
      type: "credit"
    }
  ];
  logEntryInfo.accountIds = [incomeAccount.id, "i0qajrTd7AlhwhsuV3xl"];
  // console.log(logEntryInfo);
  LogEntries.add(logEntryInfo);

  const logEntryPayment = {
    type: "income",
    title: `Pago ${description} ${incomeAccount.name}`,
    tags: ["cuota mensual", "ingreso"],
    userId: user.id,
    userName: user.name,
    total: totalFee,
    createdAt: date,
    approved: true,
    incomeId: bankAccount.id,
    incomeName: bankAccount.name,
    outcomeId: incomeAccount.id,
    outcomeName: incomeAccount.name
  };

  logEntryPayment.accounts = [
    {
      id: bankAccount.id,
      name: bankAccount.name,
      total: amount,
      type: "debit"
    },
    {
      id: incomeAccount.id,
      name: incomeAccount.name,
      total: amount,
      type: "credit"
    }
  ];
  logEntryPayment.accountIds = [incomeAccount.id, bankAccount.id];
  // console.log(logEntryPayment);
  LogEntries.add(logEntryPayment);
}

export { generateMonthlyFee };
