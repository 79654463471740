import React from "react";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";

import Button from "../../../components/CustomButtons/Button";

function AdminProductsItemOptions({ product, toggleModal }) {
  return (
    <td className="flex justify-between">
      <Button
        key="edit"
        color="success"
        simple
        onClick={() => toggleModal("edit", product)}
        className="p-0"
      >
        <EditIcon />
      </Button>
      <Button
        key="delete"
        color="danger"
        simple
        onClick={() => toggleModal("delete", product)}
        className="p-0"
      >
        <DeleteIcon />
      </Button>
    </td>
  );
}

export default AdminProductsItemOptions;
