import React from "react";
import { css } from "@emotion/core";
import AddIcon from "@material-ui/icons/Add";

import SharedMessage from "../../Shared/Message";
import AdminDeliveriesListItems from "./ListItems";
import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem";
import DeliveriesSearch from "./Search";
import LinkButton from "../../Shared/LinkButton";

class AdminDelivieriesList extends React.Component {
  state = {
    showEdit: false,
    showNew: false,
    showDelete: false
  };

  toggleModal = () => {
    this.setState({ showNew: !this.state.showNew });
  };

  render() {
    const { deliveries, accounts } = this.props;

    return (
      <GridContainer
        className={css`
          min-height: 400px;
          margin-left: 1em;
        `}
      >
        <DeliveriesSearch />

        <GridItem
          xs={12}
          lg={12}
          className={css`
            margin-top: 1em;
          `}
        >
          <LinkButton
            color="linkedin"
            aria-label="Nuevo pedido"
            to="/admin/pedidos/new"
          >
            <AddIcon /> Nuevo apunte
          </LinkButton>
        </GridItem>

        <GridItem xs={12} lg={12}>
          {deliveries.length > 0 ? (
            <AdminDeliveriesListItems
              deliveries={deliveries}
              accounts={accounts}
            />
          ) : (
            <SharedMessage message="Todavía no hay ningun pedido." />
          )}
        </GridItem>
        <GridItem
          xs={12}
          lg={12}
          className={css`
            margin-top: 1em;
          `}
        />
      </GridContainer>
    );
  }
}

export default AdminDelivieriesList;
