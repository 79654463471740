import React from "react";
import FormControl from "@material-ui/core/FormControl";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

import ImageSelector from "../../Shared/ImageSelector";
import CustomInput from "../../../components/CustomInput/CustomInput";
import Button from "../../../components/CustomButtons/Button";

class AdminCategoriesForm extends React.Component {
  constructor(props) {
    super(props);
    if (this.props.activeCategory) {
      this.state = {
        category: this.props.activeCategory
      };
    } else {
      this.state = {
        category: {
          id: "",
          title: "",
          parentId: this.props.parentId || "0",
          image: "",
          count: 0
        }
      };
    }
  }

  onChange = event => {
    const category = { ...this.state.category };
    category[event.target.name] = event.target.value;
    console.log(event.target, event.target.value, category);
    this.setState({ category });
  };

  updateImageName = name => {
    const category = { ...this.state.category };
    category.image = name;
    this.setState({ category });
  };

  onSubmit = event => {
    event.preventDefault();

    this.props.submitData(this.state.category);
  };

  render() {
    const { toggleModal } = this.props;
    const { category } = this.state;

    return (
      <form onSubmit={this.onSubmit}>
        <DialogTitle>Categorías</DialogTitle>
        <DialogContent>
          <FormControl fullWidth>
            <CustomInput
              labelText="Nombre"
              autoFocus
              inputProps={{
                onChange: this.onChange,
                value: category.title,
                id: "title",
                name: "title"
              }}
            />
          </FormControl>
          <FormControl fullWidth>
            <ImageSelector
              filesPath="category"
              name={category.image}
              updateImageName={this.updateImageName}
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={toggleModal} color="transparent">
            Cancelar
          </Button>
          <Button type="submit" color="success">
            Guardar
          </Button>
        </DialogActions>
      </form>
    );
  }
}

export default AdminCategoriesForm;
