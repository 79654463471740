import { formatFloatToFixed } from "../utils/format";

const calculatePrice = (items, productId) => {
  const product = items.find(item => item.productId === productId);
  const unitPrice = formatFloatToFixed(getUnitPrice(product));
  const totalPrice = formatFloatToFixed(product.quantity * unitPrice);

  return [totalPrice, unitPrice, product.quantity];
};

const getUnitPrice = product => {
  const netAmount = (product.price - product.discount) / product.quantity;

  if (!product.vat || product.vat === 0) {
    return netAmount;
  }
  return netAmount + (netAmount * product.vat) / 100;
};

export { calculatePrice };
