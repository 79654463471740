import React from "react";

import DeleteDialog from "../../Shared/Dialogs/Delete";

const DELETE_SLOT = `
  mutation EditSlot($input: DeleteSlotInput!) {
    deleteSlot(input: $input) {
      id
    }
  }
`;

const DeleteSlot = ({ onClose, item, open }) => (
  <DeleteDialog
    title="Borrar actividad"
    onClose={ onClose }
    item={ item }
    mutationQuery={ DELETE_SLOT }
    open={ open }
  />
);

export default DeleteSlot;
