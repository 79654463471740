import React from "react";

import AdminNewReservation from "../../New";
import AdminReservationsTableContentHead from "./head";

const AdminReservationsTableContent = ({
  tableRows,
  modalOpen,
  operation,
  bookingInfo,
  toggleModal,
  accounts
}) => (
  <div>
    <div className="rounded-lg shadow mt-4">
      <table className="table-auto w-full text-gray-900">
        <AdminReservationsTableContentHead />
        <tbody>{tableRows.map(row => row)}</tbody>
      </table>
    </div>

    {modalOpen && (
      <AdminNewReservation
        operation={operation}
        open={modalOpen}
        booking={bookingInfo}
        toggleModal={toggleModal}
        accounts={accounts}
      />
    )}
  </div>
);

export default AdminReservationsTableContent;
