import { Bookings } from "../database/";
import { totalPayable, removeExtrasAndZeros } from "../products/basket";
import { convertToDinero } from "../utils/format";

function bookingsPersistence() {
  function updateBasket(bookingId, basket, payment = "cash") {
    const amountToPay = parseFloat(totalPayable(basket));
    const amount = convertToDinero(amountToPay);
    const newBasket = removeExtrasAndZeros(basket);

    const newInfo = {
      status: "pending",
      items: newBasket,
      total: amountToPay,
      amount,
      payment
    };

    return Bookings.doc(bookingId).update(newInfo);
  }

  function approve(bookingId) {
    return Bookings.doc(bookingId).update({ status: "approved" });
  }

  function remove(bookingId) {
    return Bookings.doc(bookingId).delete();
  }

  return Object.freeze({ updateBasket, approve, remove });
}
const BookingsPersistence = bookingsPersistence();

export { BookingsPersistence };
