import React from "react";
import Dialog from "@material-ui/core/Dialog";
import withStyles from "@material-ui/core/styles/withStyles";

import NewReservation from "./New";
import modalStyle from "../../assets/jss/modalStyle";

const Reservations = ({
  eventsForToday,
  open,
  toggleCalendar,
  defaultDate
}) => {
  return (
    <Dialog open={open}>
      <NewReservation
        toggleModal={toggleCalendar}
        defaultDate={defaultDate}
        eventsForToday={eventsForToday}
      />
    </Dialog>
  );
};

export default withStyles(modalStyle)(Reservations);
