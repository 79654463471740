import { beginningAndEndOfYear } from "../dates/calendar";
import { LogEntries, FirebaseResults, Reports } from "../database";
import { getYear } from "date-fns";

const reportsSummary = () => {
  const getMembersFee = results => {
    const membersFee = results.filter(result =>
      result.tags.includes("cuota mensual")
    );
    const total = membersFee.reduce((total, fee) => (total += fee.amount), 0);

    return total;
  };

  const getSales = results => {
    const sales = results.filter(result => result.tags.includes("reservas"));
    const total = sales.reduce((total, fee) => (total += fee.amount), 0);

    return total;
  };

  const getCleaning = results => {
    const cleaning = results.filter(result => result.tags.includes("limpieza"));
    const total = cleaning.reduce((total, fee) => (total += fee.amount), 0);

    return total;
  };

  const getInsurance = results => {
    const insurance = results.filter(result => result.tags.includes("seguros"));
    const total = insurance.reduce((total, fee) => (total += fee.amount), 0);

    return total;
  };

  const getTaxes = results => {
    const taxes = results.filter(result => result.tags.includes("impuestos"));
    const total = taxes.reduce((total, fee) => (total += fee.amount), 0);

    return total;
  };

  const getRent = results => {
    const rent = results.filter(result => result.tags.includes("alquiler"));
    const total = rent.reduce((total, fee) => (total += fee.amount), 0);

    return total;
  };

  const getBankFees = results => {
    const bankFees = results.filter(result => result.tags.includes("gastos"));
    const total = bankFees.reduce((total, fee) => (total += fee.amount), 0);

    return total;
  };

  const getStock = results => {
    const stock = results.filter(result => result.tags.includes("compras"));
    const total = stock.reduce((total, fee) => (total += fee.amount), 0);

    return total;
  };

  const getSuppliers = results => {
    const suppliers = results.filter(
      result =>
        result.tags.includes("electricidad") ||
        result.tags.includes("calefacción") ||
        result.tags.includes("internet")
    );
    const total = suppliers.reduce((total, fee) => (total += fee.amount), 0);

    return total;
  };

  const getRepairs = results => {
    const repairs = results.filter(
      result =>
        result.tags.includes("reformas") ||
        result.tags.includes("mantenimientos")
    );
    const total = repairs.reduce((total, fee) => (total += fee.amount), 0);

    return total;
  };

  const getOthers = results => {
    const others = results.filter(
      result =>
        !result.tags.includes("reformas") &&
        !result.tags.includes("mantenimientos") &&
        !result.tags.includes("compras") &&
        !result.tags.includes("gastos") &&
        !result.tags.includes("alquiler") &&
        !result.tags.includes("impuestos") &&
        !result.tags.includes("seguros") &&
        !result.tags.includes("limpieza") &&
        !result.tags.includes("reservas") &&
        !result.tags.includes("electricidad") &&
        !result.tags.includes("internet") &&
        !result.tags.includes("calefacción") &&
        !result.tags.includes("cuota mensual")
    );

    const total = others.reduce((total, fee) => (total += fee.amount), 0);

    return total;
  };

  const generate = selectedYear => {
    const [startDate, endDate] = beginningAndEndOfYear(selectedYear);
    const currentYear = getYear(startDate);
    const queryParams = {
      startDate,
      endDate,
      tags: "compras"
    };

    LogEntries.where("createdAt", ">=", queryParams.startDate)
      .where("createdAt", "<=", queryParams.endDate)
      .get()
      .then(docs => {
        const results = FirebaseResults.map(docs);
        const membersFee = getMembersFee(results);
        const sales = getSales(results);
        const cleaning = getCleaning(results);
        const insurance = getInsurance(results);
        const taxes = getTaxes(results);
        const rent = getRent(results);
        const bankFees = getBankFees(results);
        const stock = getStock(results);
        const repairs = getRepairs(results);
        const others = getOthers(results);
        const suppliers = getSuppliers(results);
        const updatedAt = new Date();
        Reports.where("name", "==", "summary")
          .where("year", "==", currentYear)
          .get()
          .then(querySnapshot => {
            if (!querySnapshot.empty) {
              const reportId = querySnapshot.docs[0].id;
              Reports.doc(reportId).update({
                membersFee,
                sales,
                cleaning,
                insurance,
                taxes,
                rent,
                bankFees,
                stock,
                repairs,
                suppliers,
                others,
                updatedAt
              });
            } else {
              console.error("no reports, that is weird.");
            }
          });
      });
  };

  return Object.freeze({ generate });
};

const ReportsSummary = reportsSummary();

export default ReportsSummary;
