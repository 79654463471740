import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import InputAdornment from "@material-ui/core/InputAdornment";
import People from "@material-ui/icons/People";

import Slots from "./Slots";
import Resources from "../Resources";
import Button from "../../../components/CustomButtons/Button";
import CustomInput from "../../../components/CustomInput/CustomInput";
import regularFormsStyle from "../../../assets/jss/views/regularFormsStyle";
import { totalReservedSeats } from "../../../lib/events/finder";
import { findSlotById } from "../../../lib/slots/finder";
import {
  selectTable,
  mapTableToResourceItems
} from "../../../lib/events/validations";
import {
  reservedSeatsByResource,
  currentlyReservedTables
} from "../../../lib/resources/finder";
import NotificationMessage from "../../Shared/Notification";

class ReservationForm extends React.Component {
  constructor(props) {
    super(props);
    let totalAvailableSeats = 0;
    props.resources.map(
      resource => (totalAvailableSeats += parseInt(resource.seats))
    );
    this.state = {
      showNotification: false,
      message: "",
      totalAvailableSeats,
      currentAvailableSeats: 0,
      pendingSeats: 0,
      seats: this.props.seats,
      slotId: this.props.slotId,
      tables: this.props.tables,
      reservedResources: {}
    };
  }

  componentDidMount = () => {
    this.updateAvailability(this.state.slotId);
    this.updateAvailableSeats(this.state.seats, this.state.tables);
  };

  updateAvailability = newValue => {
    const slot = findSlotById(this.props.slots, newValue);
    if (!slot) {
      return false;
    }

    let seats = this.state.seats;

    if (!slot.requireSeats) {
      seats = 0;
    }

    const reservedResources = reservedSeatsByResource(
      this.props.eventsForToday,
      this.state.tables,
      slot.id,
      this.props.resources
    );

    this.setState({ reservedResources, slotId: slot.id, seats });
  };

  updateSeats = newValue => {
    this.updateAvailableSeats(newValue, this.state.tables);
  };

  updateAvailableSeats = (seats, tables) => {
    const slot = findSlotById(this.props.slots, this.state.slotId);

    if (!slot.requireSeats) {
      return;
    }

    const reservedSeats = totalReservedSeats(
      this.props.eventsForToday,
      this.state.slotId
    );

    const pendingSeats = seats - currentlyReservedTables(tables);
    const currentAvailableSeats =
      this.state.totalAvailableSeats - reservedSeats;

    this.setState({ currentAvailableSeats, pendingSeats, tables, seats });
  };

  toggleNotification = () => {
    this.setState({ showNotification: !this.state.showNotification });
  };

  onSelectTable = (resource, seats, pendingSeats) => {
    let tables = selectTable(this.state.tables, resource, seats, pendingSeats);

    if (!tables) {
      tables = [];
    }

    this.updateAvailableSeats(this.state.seats, tables);
  };

  handleSubmit = event => {
    event.preventDefault();

    const slot = findSlotById(this.props.slots, this.state.slotId);

    if (slot.requireSeats && (!this.state.seats || this.state.seats < 1)) {
      this.setState({
        showNotification: true,
        message:
          "Por favor añade el número estimado de comensales a la reserva."
      });

      return true;
    }

    this.props.onSubmit({
      seats: this.state.seats,
      resources: mapTableToResourceItems(this.state.tables),
      slotId: this.state.slotId
    });
  };

  render() {
    const { defaultDate, toggleModal, resources, slots } = this.props;

    return (
      <form onSubmit={this.handleSubmit}>
        <DialogTitle>Reserva para el día {defaultDate}</DialogTitle>
        <DialogContent>
          <Slots
            onChange={event => this.updateAvailability(event.target.value)}
            slotId={this.state.slotId}
            slots={slots}
          />
          <CustomInput
            labelText="Comensales"
            formControlProps={{
              fullWidth: true
            }}
            helpText={`Comensales: (El total disponible es de ${
              this.state.currentAvailableSeats
            })`}
            inputProps={{
              id: "seats",
              name: "seats",
              type: "number",
              autoFocus: true,
              value: this.state.seats,
              onChange: event =>
                this.updateSeats(parseInt(event.target.value, 10)),
              endAdornment: (
                <InputAdornment position="end">
                  <People />
                </InputAdornment>
              )
            }}
          />

          <Resources
            onClick={this.onSelectTable}
            tables={this.state.tables}
            reservedResources={this.state.reservedResources}
            pendingSeats={this.state.pendingSeats}
            availableSeats={this.state.currentAvailableSeats}
            resources={resources}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => toggleModal()} color="transparent">
            Cancelar
          </Button>
          <Button type="submit" color="success">
            Guardar
          </Button>
        </DialogActions>
        {this.state.showNotification && (
          <NotificationMessage
            message={this.state.message}
            toggleNotification={this.toggleNotification}
          />
        )}
      </form>
    );
  }
}

export default withStyles(regularFormsStyle)(ReservationForm);
