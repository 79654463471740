import React from "react";
import { connect } from "react-redux";
import SelectSearch from "react-select-search";
import {
  withStyles,
  FormControl,
  Select,
  MenuItem,
  InputAdornment
} from "@material-ui/core";
import People from "@material-ui/icons/People";
import Pay from "@material-ui/icons/EuroSymbol";

import GridContainer from "../../../../components/Grid/GridContainer";
import GridItem from "../../../../components/Grid/GridItem";
import Slots from "../../../Reservations/New/Slots";
import Button from "../../../../components/CustomButtons/Button";
import CustomInput from "../../../../components/CustomInput/CustomInput";
import extendedFormsStyle from "../../../../assets/jss/views/extendedFormsStyle";
import { isAdmin } from "../../../../lib/users/permission";
import DateInputLocalised from "../../../Shared/DateInputLocalised";

function AdminReservationsFormField({
  onSubmit,
  toggleModal,
  slots,
  onChange,
  onSelect,
  bookingInfo,
  users,
  dateChange,
  profile,
  title
}) {
  const userOptions = users.map(user => ({
    name: user.name,
    value: user.id
  }));
  return (
    <form onSubmit={onSubmit}>
      <h2 className="p-4 text-2xl text-blue-800">{title}</h2>
      <div className="p-4">
        <div className="flex mt-1">
          <div className="w-1/2">
            <DateInputLocalised
              dateChange={dateChange}
              dateValue={new Date(bookingInfo.selectedDate)}
              placeholder="Fecha de la reserva"
            />
          </div>
          {isAdmin(profile) && (
            <div className="w-1/2">
              <legend>Selecciona un socio</legend>
              <SelectSearch
                options={userOptions}
                onChange={onSelect}
                value={bookingInfo.accountId}
                name="account"
                placeholder="Selecciona un socio"
              />
            </div>
          )}
        </div>
        <FormControl fullWidth>
          <Slots
            onChange={onChange}
            slotId={bookingInfo.slotId}
            slots={slots}
          />
        </FormControl>
        <FormControl fullWidth>
          <GridContainer>
            <GridItem xs={12} sm={6} md={6}>
              <CustomInput
                labelText="Comensales"
                type="number"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  onChange,
                  name: "seats",
                  id: "seats",
                  value: bookingInfo.seats,
                  endAdornment: (
                    <InputAdornment position="end">
                      <People />
                    </InputAdornment>
                  )
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={6} md={6}>
              <CustomInput
                labelText="Importe"
                type="number"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  onChange,
                  id: "total",
                  name: "total",
                  value: bookingInfo.total,
                  endAdornment: (
                    <InputAdornment position="end">
                      <Pay />
                    </InputAdornment>
                  )
                }}
              />
            </GridItem>
          </GridContainer>
        </FormControl>
        <FormControl fullWidth>
          <GridContainer>
            <GridItem xs={12} sm={6} md={6}>
              <legend>Forma de pago</legend>
              <Select
                value={bookingInfo.payment}
                onChange={onChange}
                inputProps={{
                  name: "payment",
                  id: "payment"
                }}
              >
                <MenuItem value="cash">Efectivo</MenuItem>
                <MenuItem value="card">Tarjeta</MenuItem>
                <MenuItem value="bank">Transferencia</MenuItem>
              </Select>
            </GridItem>
            <GridItem xs={12} sm={6} md={6}>
              <legend>Estado</legend>
              <Select
                value={bookingInfo.status}
                onChange={onChange}
                inputProps={{
                  name: "status",
                  id: "status"
                }}
              >
                <MenuItem value="pending">Pendiente</MenuItem>
                <MenuItem value="created">Pagada</MenuItem>
                <MenuItem value="approved">Aprobada</MenuItem>
                <MenuItem value="rejected">Rechazada</MenuItem>
              </Select>
            </GridItem>
          </GridContainer>
        </FormControl>
        <FormControl fullWidth>
          <CustomInput
            labelText="Comentarios"
            inputProps={{
              onChange,
              id: "comments",
              name: "comments",
              value: bookingInfo.comments
            }}
          />
        </FormControl>
      </div>
      <div className="p-4 flex justify-end">
        <Button onClick={() => toggleModal()} color="transparent">
          Cancelar
        </Button>
        <Button type="submit" color="success">
          Guardar
        </Button>
      </div>
    </form>
  );
}

export default connect(({ firebase: { profile } }) => ({ profile }))(
  withStyles(extendedFormsStyle)(AdminReservationsFormField)
);
