const bookingsOrderedBySlot = bookings =>
  bookings.sort((a, b) => {
    if (a.slot.id > b.slot.id) {
      return 1;
    }

    if (a.slot.id < b.slot.id) {
      return -1;
    }

    return 0;
  });

const orderSlots = slots => {
  const sortedArray = Array.from(slots);

  return sortedArray.sort((a, b) => {
    if (a.order < b.order) {
      return -1;
    }
    if (a.order > b.order) {
      return 1;
    }
    return 0;
  });
};

export { bookingsOrderedBySlot, orderSlots };
