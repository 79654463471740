import React from "react";
import { Paper, Tabs, Tab } from "@material-ui/core";

const SummarySearch = ({ elements, selectedYear, onChange }) => {
  const renderElements = () => {
    return elements.map(element => <Tab label={element} key={element} />);
  };
  const selectedElement = elements.indexOf(selectedYear);

  return (
    <Paper square>
      <Tabs
        value={selectedElement}
        indicatorColor="primary"
        textColor="primary"
        onChange={onChange}
      >
        {renderElements()}
      </Tabs>
    </Paper>
  );
};

export default SummarySearch;
