const SEATS_ID = "73NYkpjDq2DcuN3RhHx4";

const initializeBasket = (products, seats, items) => {
  if (items && items.length > 0) {
    items.forEach(item => {
      item.isExtra = false;
    });
    return items;
  }

  const seatsProduct = products.find(product => product.id === SEATS_ID);

  const basket = [];
  if (seatsProduct) {
    basket.push({
      productId: seatsProduct.id,
      quantity: parseInt(seats),
      title: seatsProduct.title,
      vat: 0,
      price: parseFloat(seatsProduct.sellPrice),
      isExtra: false
    });
  }

  return basket;
};

const basketWithNoExtras = basket => basket.filter(item => !item.isExtra);

const removeExtrasAndZeros = basket => {
  let currentBasket = Array.from(basket);
  currentBasket = basketWithNoExtras(currentBasket);
  currentBasket.forEach(item => {
    delete item.isExtra;
  });
  currentBasket = currentBasket.filter(item => item.quantity > 0);

  return currentBasket;
};

const totalAmount = (basket, removeExtras = false) => {
  let currentBasket;
  if (removeExtras) {
    currentBasket = basket.filter(item => !item.isExtra);
  } else {
    currentBasket = Array.from(basket);
  }

  const total = currentBasket.reduce(
    (price, item) => price + parseFloat(item.price) * parseFloat(item.quantity),
    0
  );

  return total;
};

const totalPayable = basket => {
  const total = totalAmount(basket, true);

  return total;
};

const totalSeats = basket => {
  const seatsItem = basket.find(item => item.productId === SEATS_ID);

  return seatsItem ? seatsItem.quantity : 1;
};

export {
  initializeBasket,
  totalAmount,
  basketWithNoExtras,
  removeExtrasAndZeros,
  totalSeats,
  totalPayable
};
