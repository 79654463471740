import React from "react";
import styled from "@emotion/styled";

import SummaryItem from "./Item";
import GridItem from "../../../components/Grid/GridItem";
import { formatDinero } from "../../../lib/utils/format";

const SummaryColumn = styled("div")`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 1em;
`;

const SummaryExpenses = ({ summaryReport }) => {
  const total =
    summaryReport.rent +
    summaryReport.cleaning +
    summaryReport.insurance +
    summaryReport.taxes +
    summaryReport.bankFees +
    summaryReport.stock +
    summaryReport.repairs +
    summaryReport.suppliers +
    327997;
  return (
    <GridItem xs={12} sm={6}>
      <h2>Gastos</h2>
      <hr />
      <SummaryColumn>
        <SummaryItem
          title="Alquiler"
          amount={formatDinero(summaryReport.rent)}
        />
        <SummaryItem
          title="Limpieza"
          amount={formatDinero(summaryReport.cleaning)}
        />
        <SummaryItem
          title="Seguros"
          amount={formatDinero(summaryReport.insurance)}
        />
        <SummaryItem
          title="Ayuntamiento"
          amount={formatDinero(summaryReport.taxes)}
        />
        <SummaryItem
          title="Gastos bancarios"
          amount={formatDinero(summaryReport.bankFees)}
        />
        <SummaryItem title="Deuda pendiente" amount={formatDinero(327997)} />
        <SummaryItem
          title="Compras"
          amount={formatDinero(summaryReport.stock)}
        />
        <SummaryItem
          title="Suministros"
          amount={formatDinero(summaryReport.suppliers)}
        />
        <SummaryItem
          title="Obras y mantenimiento"
          amount={formatDinero(summaryReport.repairs)}
        />
        <SummaryItem title="Total" amount={formatDinero(total)} />
      </SummaryColumn>
    </GridItem>
  );
};

export default SummaryExpenses;
