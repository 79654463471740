import React, { useState } from "react";
import { getYear } from "date-fns";

import SummarySearch from "../../Shared/SummarySearch";
import Button from "../../../components/CustomButtons/Button";
import ReportsGenerator from "../../../lib/reports/generator";

const currentYear = getYear(new Date());
const elements = [currentYear - 2, currentYear - 1, currentYear];

const AdminReports = () => {
  const [selectedYear, setSelectedYear] = useState(currentYear);
  const yearChange = (_event, value) => {
    setSelectedYear(elements[value]);
  };

  const generateReport = () => {
    ReportsGenerator.summary(selectedYear);
  };

  return (
    <div>
      <h1>Generador de informes</h1>
      <SummarySearch
        elements={elements}
        selectedYear={selectedYear}
        onChange={yearChange}
      />
      <div>
        <Button onClick={generateReport} type="submit" color="tumblr">
          Generar informe
        </Button>
      </div>
    </div>
  );
};

export default AdminReports;
