import React from "react";
import { withStyles } from "@material-ui/core";

import Table from "../../../../components/Table/Table";
import { formatDate } from "../../../../lib/dates/format";
import extendedTablesStyle from "../../../../assets/jss/views/extendedTablesStyle";
import { getProductFromBooking } from "../../../../lib/bookings/finder";

const showItem = (booking, productId, classes) => {
  const bookingProduct = getProductFromBooking(booking.items, productId);
  return [
    <p className={classes.tdNameSmall}>{formatDate(booking.createdAt)}</p>,
    <p className={classes.tdNameSmall}>{booking.accountName}</p>,
    <p className={classes.tdNumber}>{bookingProduct.quantity}</p>,
    <p className={classes.tdNumber}>{bookingProduct.total}</p>,
    <p className={classes.tdNumber}>{bookingProduct.price}</p>
  ];
};

const ProductsShowBookings = ({ bookings, productId, classes }) => (
  <Table
    striped
    tableHead={["Fecha", "Socio", "Cantidad", "Importe"]}
    tableData={bookings.map(booking => showItem(booking, productId, classes))}
  />
);

export default withStyles(extendedTablesStyle)(ProductsShowBookings);
