import React from "react";
import { css } from "@emotion/core";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";

import Button from "../../../../components/CustomButtons/Button";

const AdminAccountsItemOptions = ({ account, toggleModal }) => [
  <Button
    key="edit"
    color="success"
    simple
    onClick={() => toggleModal("edit", account)}
    className={css`
      padding: 0;
    `}
  >
    <EditIcon />
  </Button>,
  <Button
    key="delete"
    color="danger"
    simple
    onClick={() => toggleModal("delete", account)}
    className={css`
      padding: 0;
    `}
  >
    <DeleteIcon />
  </Button>
];

export default AdminAccountsItemOptions;
