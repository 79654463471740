import React from "react";
import styled from "@emotion/styled";

import DayOfMonth from "./DayMonth";

const CalendarMonthRow = styled("div")`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
`;

const WeekOfMonth = ({ days, onSelect, defaultDate }) => {
  return (
    <CalendarMonthRow>
      {days.map((day, index) => (
        <DayOfMonth
          key={index}
          day={day}
          onSelect={onSelect}
          defaultDate={defaultDate}
        />
      ))}
    </CalendarMonthRow>
  );
};

export default WeekOfMonth;
