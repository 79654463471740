const slots = (state = [], action) => {
  switch (action.type) {
    case "UPDATE_SLOTS":
      return action.slots;
    default:
      return state;
  }
};

export { slots };
