import { sidebarRoutes, adminRoutes, managerRoutes } from "../routes/sidebar";
import { Auth } from "../../config/firebase";

const availableRoutes = user => {
  switch (user.role) {
    case "admin":
      return sidebarRoutes.concat(adminRoutes).concat(managerRoutes);
    case "manager":
      return sidebarRoutes.concat(managerRoutes);
    default:
      return sidebarRoutes;
  }
};

const isAdmin = profile => profile.role === "admin";
const isManager = profile => profile.role === "manager" || isAdmin(profile);

const canEditBooking = (profile, userId, status) => {
  return (
    (Auth.currentUser.uid === userId && status === "pending") ||
    isAdmin(profile)
  );
};

export { availableRoutes, isAdmin, isManager, canEditBooking };
