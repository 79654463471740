import React from "react";

import { formatDate } from "../../../lib/dates/format";
import { formatDinero } from "../../../lib/utils/format";

const showDebit = account => (
  <tr key={account.id}>
    <td colSpan="3" className="p-2 text-sm text-right">
      {account.name}
    </td>
    <td className="p-2 text-sm text-right">{formatDinero(account.total)}</td>
    <td></td>
  </tr>
);

const showCredit = account => (
  <tr key={account.id}>
    <td colSpan="3" className="p-2 text-sm text-right">
      {account.name}
    </td>
    <td>&nbsp;</td>
    <td className="px-4 text-sm text-right text-red-600">
      {formatDinero(account.total)}
    </td>
  </tr>
);

const LogEntriesListEntry = ({ logEntry }) => {
  if (!logEntry.accounts) {
    return null;
  }

  return (
    <React.Fragment>
      <tr>
        <td className="p-2 text-sm">{formatDate(logEntry.createdAt)}</td>
        <td className="p-2 text-sm" colSpan="4">
          {logEntry.title}
        </td>
      </tr>
      {logEntry.accounts.map(account => {
        if (account.type === "debit") {
          return showDebit(account);
        } else {
          return showCredit(account);
        }
      })}
      <tr className="border-b border-gray-200">
        <td colSpan="4">&nbsp;</td>
      </tr>
    </React.Fragment>
  );
};

export default LogEntriesListEntry;
