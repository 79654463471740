import React from "react";
import { format } from "date-fns";
import { connect } from "react-redux";
import Dialog from "@material-ui/core/Dialog";
import withStyles from "@material-ui/core/styles/withStyles";

import ReservationForm from "./Form";
import modalStyle from "../../../assets/jss/modalStyle";
import { findSlotByName, findSlotById } from "../../../lib/slots/finder";
import { Bookings } from "../../../lib/database/";
import LoadingDialog from "../../Shared/Dialogs/Loading";
import { Auth } from "../../../config/firebase";
import { findAccountByOriginId } from "../../../lib/accounts/finder";
import { createLogEntryFromBooking } from "../../../lib/log_entries/persistence";

const createReservation = (
  newInfo,
  defaultDate,
  toggleModal,
  slots,
  accounts
) => {
  const slot = findSlotById(slots, newInfo.slotId);
  const account = findAccountByOriginId(accounts, Auth.currentUser.uid);

  const bookingItem = {
    startDate: defaultDate,
    endDate: defaultDate,
    seats: parseInt(newInfo.seats),
    total: 0,
    payment: "cash",
    status: "pending",
    slotId: newInfo.slotId,
    slotName: slot.name,
    accountId: account.id,
    accountName: account.name,
    resources: newInfo.resources,
    createdAt: new Date()
  };

  Bookings.add(bookingItem).then(doc => {
    bookingItem.id = doc.id;
    bookingItem.date = bookingItem.startDate;
    createLogEntryFromBooking(bookingItem, accounts);
  });
  toggleModal();
};

const NewReservation = ({
  defaultDate,
  toggleModal,
  profile,
  eventsForToday,
  slots,
  accounts,
  resources
}) => {
  if (profile.isLoading || slots.length === 0 || resources.length === 0) {
    return <LoadingDialog />;
  }

  const slot = findSlotByName(slots, "Cena");

  return (
    <Dialog open>
      <ReservationForm
        toggleModal={toggleModal}
        onSubmit={bookingInfo =>
          createReservation(
            bookingInfo,
            defaultDate,
            toggleModal,
            slots,
            accounts
          )
        }
        seats={1}
        tables={[]}
        slotId={slot.id}
        defaultDate={format(defaultDate, "dd/MM/yyyy")}
        slots={slots}
        resources={resources}
        eventsForToday={eventsForToday}
      />
    </Dialog>
  );
};

const mapStateToProps = state => {
  return {
    resources: state.mainData.resources,
    accounts: state.mainData.accounts,
    slots: state.mainData.slots,
    profile: state.firebase.profile
  };
};

export default connect(mapStateToProps)(withStyles(modalStyle)(NewReservation));
