import React from "react";
import { css } from "@emotion/core";

import AddIcon from "@material-ui/icons/Add";

import SharedMessage from "../../Shared/Message";
import AdminProductsListItems from "./ListItems";
import AdminProductsNew from "./New";
import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem";
import Button from "../../../components/CustomButtons/Button";

class AdminProductsList extends React.Component {
  state = {
    showEdit: false,
    showNew: false,
    showDelete: false,
    activeProduct: {}
  };

  toggleModal = () => {
    this.setState({ showNew: !this.state.showNew });
  };

  render() {
    const { products, categoryId } = this.props;

    return (
      <GridContainer>
        <GridItem xs={12} lg={12}>
          {products.length > 0 ? (
            <AdminProductsListItems products={products} />
          ) : (
            <SharedMessage message="Esta categoría no tiene asociado ningún producto." />
          )}
        </GridItem>
        <GridItem
          xs={12}
          lg={12}
          className={css`
            margin-top: 1em;
          `}
        >
          <Button
            round
            color="linkedin"
            aria-label="Nuevo producto"
            onClick={() => this.toggleModal()}
          >
            <AddIcon /> Nuevo producto
          </Button>

          {this.state.showNew && (
            <AdminProductsNew
              categoryId={categoryId}
              toggleModal={() => this.toggleModal()}
            />
          )}
        </GridItem>
      </GridContainer>
    );
  }
}

export default AdminProductsList;
