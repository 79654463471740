import {
  addDays,
  endOfDay,
  startOfDay,
  startOfMonth,
  endOfMonth,
  startOfWeek,
  isSameDay,
  startOfYear,
  endOfYear
} from "date-fns";
import {} from "date-fns/esm";

const firstAndLastDayOfMonth = date => {
  const firstDayMonth = startOfMonth(date);
  const lastDayMonth = endOfMonth(date);

  return [firstDayMonth, lastDayMonth];
};

const beginningAndEndOfDay = date => {
  const beginningDay = startOfDay(date);
  const endDay = endOfDay(date);

  return [beginningDay, endDay];
};

const beginningAndEndOfYear = year => {
  const date = new Date(year, 1, 1);
  const beginningYear = startOfYear(date);
  const endYear = endOfYear(date);

  return [beginningYear, endYear];
};

const arrayOfWeeks = date => {
  const [firstDayMonth, lastDayMonth] = firstAndLastDayOfMonth(date);

  const firstWeekNumber = getWeek(firstDayMonth);
  let lastWeekNumber = getWeek(lastDayMonth);
  if (lastDayMonth.getDay() === 0) {
    // It's sunday. New week start for USA
    lastWeekNumber -= 1;
  }

  let iteratorDate = new Date(firstDayMonth);
  const results = [];

  for (let n = firstWeekNumber; n <= lastWeekNumber; n++) {
    results.push(getDaysInfo(iteratorDate));
    iteratorDate = addDays(iteratorDate, 7);
  }

  return results;
};

const getDaysInfo = date => {
  const monday = startOfWeek(date, { weekStartsOn: 1 });
  const results = [];

  for (let n = 0; n < 7; n++) {
    results.push(addDays(monday, n));
  }

  return results;
};

const getWeek = date => {
  const firstJanuary = new Date(date.getFullYear(), 0, 1);

  return Math.ceil(
    ((date.getTime() - firstJanuary.getTime()) / 86400000 +
      firstJanuary.getDay() +
      1) /
      7
  );
};

const dateIsToday = date => isSameDay(new Date(), date);

const dateIsOtherMonth = (date1, date2) =>
  date1.getMonth() !== date2.getMonth();

export {
  firstAndLastDayOfMonth,
  beginningAndEndOfDay,
  beginningAndEndOfYear,
  arrayOfWeeks,
  dateIsToday,
  dateIsOtherMonth
};
