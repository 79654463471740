import React from "react";
import { withRouter } from "react-router";

import Button from "../../components/CustomButtons/Button.jsx";

const LinkButton = ({ to, children, history, ...rest }) => (
  <Button
    onClick={() => {
      history.push(to);
    }}
    {...rest}
  >
    {children}
  </Button>
);

export default withRouter(LinkButton);
