import React from "react";
import { connect } from "react-redux";

import { Bookings } from "../../../lib/database/";
import LoadingDialog from "../../Shared/Dialogs/Loading";
import ReservationsShowHeader from "./Header";

class ReservationsShow extends React.Component {
  state = {
    value: 0,
    loading: true,
    booking: {}
  };

  componentDidMount() {
    const bookingId = this.props.match.params.id.toString();

    this.setState({ loading: true });
    Bookings.doc(bookingId)
      .get()
      .then(doc => {
        const booking = doc.data();
        booking.id = bookingId;
        this.setState({ booking, loading: false });
      });
  }

  handleChange = (_event, value) => {
    this.setState({ value });
  };

  render() {
    if (this.state.loading || this.props.resources.length === 0) {
      return <LoadingDialog />;
    }

    return (
      <div>
        <ReservationsShowHeader
          booking={this.state.booking}
          resources={this.props.resources}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    resources: state.mainData.resources
  };
};

export default connect(mapStateToProps)(ReservationsShow);
