import React from "react";
import { Input, Button } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";

import { Storage } from "../../config/firebase";

class ImageSelector extends React.Component {
  state = {
    imageSrc: ""
  };

  componentDidMount() {
    const { imageName } = this.props;
    if (!imageName) {
      return;
    }

    if (imageName.startsWith("product_")) {
      // S3 image
      this.setState({ imageSrc: "" });
    }

    const fileRef = Storage.ref().child(imageName);
    fileRef.getDownloadURL().then(url => {
      this.setState({ imageSrc: url });
    });
  }

  onChange = event => {
    const { filesPath, updateImageName } = this.props;

    if (!event.target.files || event.target.files.length === 0) {
      return;
    }
    this.props.loading(true);
    const file = event.target.files[0];
    const extension = file.name.split(".").pop();
    const name = Date.now();
    const imageRoute = `images/${filesPath}_${name}.${extension}`;
    const fileRef = Storage.ref().child(imageRoute);

    fileRef.put(file).then(() => {
      updateImageName(imageRoute);
      this.props.loading(false);
    });
  };

  deleteImage = () => {
    this.props.loading(true);
    const fileRef = Storage.ref().child(this.props.imageName);
    fileRef.delete().then(() => {
      this.setState({ imageSrc: "" });
      this.props.loading(false);
    });
  };

  render() {
    const { imageName } = this.props;

    return (
      <div>
        {this.state.imageSrc ? (
          <span>
            <img src={this.state.imageSrc} alt={imageName} />
            <Button
              variant="contained"
              color="secondary"
              onClick={this.deleteImage}
            >
              <DeleteIcon />
              Borrar imagen
            </Button>
          </span>
        ) : (
          <Input type="file" onChange={this.onChange} />
        )}
      </div>
    );
  }
}

export default ImageSelector;
