import React from "react";
import { connect } from "react-redux";
import { Link } from "@material-ui/core";

import { formatDate } from "../../../lib/dates/format";
import BookingsList from "../../Shared/Bookings/List";
import ReservationBadge from "../../Shared/ReservationBadge";
import { findSlotById } from "../../../lib/slots/finder";
import { formatDinero } from "../../../lib/utils/format";

const getItems = (bookings, slots) =>
  bookings.map(booking => {
    const slot = findSlotById(slots, booking.slotId);
    return {
      content: [
        <Link href={`/reserva/${booking.id}`}>
          {formatDate(booking.startDate)}
        </Link>,
        <ReservationBadge name={slot.name}>{slot.name}</ReservationBadge>,
        booking.accountName,
        booking.seats,
        formatDinero(booking.amount)
      ],
      booking
    };
  });

const ListReservationItems = ({ bookings, slots, accounts }) => {
  const tableHead = ["Fecha", "Evento", "Socio", "Pax", "Importe", "Opciones"];

  return (
    <BookingsList
      head={tableHead}
      items={getItems(bookings, slots)}
      accounts={accounts}
    />
  );
};

const mapStateToProps = state => ({
  bookings: state.mainData.bookings,
  slots: state.mainData.slots,
  accounts: state.mainData.accounts
});

export default connect(mapStateToProps)(ListReservationItems);
