import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import withStyles from "@material-ui/core/styles/withStyles";

import modalStyle from "../../../assets/jss/modalStyle";
import Button from "../../../components/CustomButtons/Button";
import Content from "./Content";

class ReservationList extends React.Component {
  state = {
    open: this.props.open
  };

  toggleModal = () => {
    this.props.toggleCalendar();
  };

  render() {
    const { bookings, onCreate, onEdit } = this.props;
    return (
      <Dialog open={this.state.open}>
        <DialogTitle>Tus reservas</DialogTitle>
        <DialogContent>
          <Content bookings={bookings} onEdit={onEdit} />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => this.toggleModal()} color="transparent">
            Cerrar
          </Button>
          <Button onClick={() => onCreate(true)} color="linkedin">
            Nueva reserva
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(modalStyle)(ReservationList);
