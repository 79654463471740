import * as React from "react";
import { withStyles } from "@material-ui/core";

import extendedTablesStyle from "../../../assets/jss/views/extendedTablesStyle";
import Table from "../../../components/Table/Table";
import { formatDate } from "../../../lib/dates/format";
import SalesCategoriesHeader from "./Header";
import { formatFloatToFixed } from "../../../lib/utils/format";

const showItems = (classes, sales) => {
  let total = 0;
  let quantity = 0;
  const items = sales.map(sale => {
    total += sale.total;
    quantity += sale.quantity;
    return [
      <p className={classes.tdNameSmall}>{formatDate(sale.createdAt)}</p>,
      <p className={classes.tdNameSmall}>{sale.name}</p>,
      <p className={classes.tdNameSmall}>{sale.accountName}</p>,
      <p className={classes.tdNumber}>{sale.quantity}</p>,
      <p className={classes.tdNumber}>{formatFloatToFixed(sale.total)}</p>
    ];
  });

  return [items, quantity, total];
};

const SalesCategoriesItems = ({ classes, sales }) => {
  const [items, quantity, total] = showItems(classes, sales);
  return (
    <React.Fragment>
      {quantity > 0 && (
        <SalesCategoriesHeader quantity={quantity} total={total} />
      )}
      <Table
        striped
        tableHead={[
          "Fecha",
          "Descripción",
          "Socio",
          "Cantidad",
          "Importe Total"
        ]}
        tableData={items}
      />
    </React.Fragment>
  );
};

export default withStyles(extendedTablesStyle)(SalesCategoriesItems);
