import React from "react";
import SelectSearch from "react-select-search";
import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent
} from "@material-ui/core";

import Button from "../../../../components/CustomButtons/Button";
import CustomInput from "../../../../components/CustomInput/CustomInput";
import GridContainer from "../../../../components/Grid/GridContainer";
import GridItem from "../../../../components/Grid/GridItem";

class DeliveriesItemForm extends React.Component {
  constructor(props) {
    super(props);
    const productOptions = props.products.map(product => ({
      name: product.name,
      value: product.id
    }));
    if (props.item) {
      this.state = { ...props.item };
    } else {
      this.state = {
        productOptions,
        item: {
          productId: "",
          quantity: 0,
          price: 0,
          discount: 0
        }
      };
    }
  }

  onChange = event => {
    const item = { ...this.state.item };
    item[event.target.name] = event.target.value;
    this.setState({ item });
  };

  onResourceSelect = product => {
    const item = { ...this.state.item };
    item.productId = product.value;

    this.setState({ item });
  };

  onSubmit = () => {
    this.props.onSubmit(this.state.item);
    const item = {
      productId: "",
      quantity: 0,
      price: 0,
      discount: 0
    };
    this.setState({ item });
  };

  render() {
    const { item, productOptions } = this.state;

    return (
      <Dialog open={this.props.openForm}>
        <DialogTitle>Nuevo producto</DialogTitle>
        <DialogContent>
          <GridContainer style={{ minHeight: "18em" }}>
            <GridItem xs={12} sm={8}>
              <SelectSearch
                options={productOptions}
                onChange={this.onResourceSelect}
                value={item.productId}
                name="product"
                placeholder="Selecciona un producto"
              />
            </GridItem>
            <GridItem xs={12} sm={4}>
              <CustomInput
                labelText="Cantidad/Unidades"
                inputProps={{
                  id: "quantity",
                  type: "number",
                  name: "quantity",
                  onChange: this.onChange,
                  value: item.quantity
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={6}>
              <CustomInput
                formControlProps={{
                  fullWidth: true
                }}
                labelText="Precio"
                inputProps={{
                  id: "price",
                  name: "price",
                  type: "number",
                  onChange: this.onChange,
                  value: item.price
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={6}>
              <CustomInput
                formControlProps={{
                  fullWidth: true
                }}
                labelText="Descuento"
                inputProps={{
                  id: "discount",
                  name: "discount",
                  type: "number",
                  onChange: this.onChange,
                  value: item.discount
                }}
              />
            </GridItem>
          </GridContainer>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => this.props.toggleModal(false)}
            color="transparent"
          >
            Cancelar
          </Button>
          <Button type="button" onClick={this.onSubmit} color="success">
            Guardar
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default DeliveriesItemForm;
