import React from "react";
import Dialog from "@material-ui/core/Dialog";

import AdminCategoriesForm from "./Form";
import { Categories } from "../../../lib/database/";

const toggleNew = toggleModal => {
  toggleModal("new");
};

const onSubmit = async (categoryInfo, toggleModal) => {
  const newCategory = {
    title: categoryInfo.title,
    parentId: categoryInfo.parentId,
    count: 0,
    createdAt: new Date()
  };

  if (categoryInfo.image.trim().length > 0) {
    newCategory.image = categoryInfo.image;
  }

  Categories.add(newCategory);
  toggleModal("new");
};

const AdminCategoriesNew = ({ toggleModal, parentId }) => (
  <Dialog open>
    <AdminCategoriesForm
      parentId={parentId}
      toggleModal={() => toggleNew(toggleModal)}
      submitData={categoryInfo => onSubmit(categoryInfo, toggleModal)}
    />
  </Dialog>
);

export default AdminCategoriesNew;
