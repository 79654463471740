import React, { useState, useEffect } from "react";
import SelectSearch from "react-select-search";
import { formatDinero } from "../../../../lib/utils/format";

function accountsAreDifferent(currentAccount, account) {
  return currentAccount.id && currentAccount.id !== account.id;
}

function AdminLogEntriesAccountForm({
  accountOptions,
  submitAccount,
  currentAccount = {}
}) {
  const [account, setAccount] = useState({
    total: 0,
    type: "debit"
  });

  useEffect(
    function() {
      if (accountsAreDifferent(currentAccount, account)) {
        setAccount({
          ...currentAccount,
          total: formatDinero(currentAccount.total, false)
        });
      }
    },
    [currentAccount, account]
  );

  function onResourceSelect(selectedAccount) {
    setAccount({
      ...account,
      id: selectedAccount.value,
      name: selectedAccount.name
    });
  }

  function onChange(event) {
    setAccount({
      ...account,
      total: event.target.value
    });
  }

  function toggleType() {
    if (account.type === "debit") {
      setAccount({ ...account, type: "credit" });
    } else {
      setAccount({ ...account, type: "debit" });
    }
  }

  function saveAccount() {
    if (account.id) {
      submitAccount({ ...account });
    }
  }

  return (
    <div>
      <div className="flex justify-end">
        <div>
          <button type="button" onClick={saveAccount} className="btn success">
            +
          </button>
        </div>
      </div>
      <div className="flex">
        <div className="w-3/5 z-50">
          <label>Cuenta</label>
          <SelectSearch
            options={accountOptions}
            onChange={selectedAccount => onResourceSelect(selectedAccount)}
            value={account.id}
            name="account"
            placeholder="Selecciona una cuenta"
          />
        </div>
        <div className="w-1/5">
          <label>Importe</label>
          <input
            className="w-full text-lg text-gray-700 border-2 rounded p-1"
            type="number"
            name="total"
            value={account.total}
            onChange={onChange}
          />
        </div>
        <div className="w-1/5 flex items-center justify-center">
          <label>
            Ingreso{" "}
            <input
              name="type"
              type="checkbox"
              checked={account.type === "debit"}
              onChange={toggleType}
            />
          </label>
        </div>
      </div>
    </div>
  );
}

export default AdminLogEntriesAccountForm;
