import React from "react";

import WeekHeader from "./WeekHeader";
import WeekOfMonth from "./WeekLine";
import { arrayOfWeeks } from "../../../lib/dates/calendar";
import "../calendar.css";
import CalendarNavigation from "../Navigation";

const ShowCalendar = ({ defaultDate, onSelect }) => {
  const daysForCalendar = arrayOfWeeks(defaultDate);

  return (
    <div>
      <CalendarNavigation defaultDate={defaultDate} />
      <WeekHeader />
      {daysForCalendar.map((week, index) => (
        <WeekOfMonth
          key={index}
          days={week}
          onSelect={onSelect}
          defaultDate={defaultDate}
        />
      ))}
    </div>
  );
};

export default ShowCalendar;
