import React from "react";
import CustomCardHeader from "./CustomCardHeader";

const FinancialInfoCard = ({ totalIncome, totalExpenses, totalPending }) => {
  return (
    <div className="flex">
      <CustomCardHeader title="Entrada" amount={totalIncome} />
      <CustomCardHeader title="Salida" amount={totalExpenses} />
      <CustomCardHeader title="Saldo" amount={totalPending} />
    </div>
  );
};

export default FinancialInfoCard;
