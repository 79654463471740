import React from "react";
import { connect } from "react-redux";

import AdminLogEntriesFetch from "./Fetch";

const AdminLogEntries = ({ queryParams }) => (
  <AdminLogEntriesFetch queryParams={queryParams} />
);

const mapStateToProps = state => ({
  queryParams: state.mainData.logEntryQuery
});

export default connect(mapStateToProps)(AdminLogEntries);
