const welcomeMessage = {
  random_1: "Que gusto da verte de nuevo por aquí",
  random_2: "Muy buenas, espero que esté todo a tu gusto",
  random_3: "Hombre, ya tardabas en dejarte ver por aquí",
  random_4: "Contigo ya estamos todos. Que empiece la fiesta!",
  random_5: "Te echabamos de menos. Estaba la sociedad tristona. ",
  random_6: "Te has acordado de la contraseña! Tenía mis dudas. :-)",
  random_7: "No manejas mal los ordenadores, para tu edad.",
  random_8: "Contigo, esta sociedad ha mejorado bastante.",
  random_9: "Ole mi niño!, que ha puesto los datos correctos."
};

export { welcomeMessage };
