import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import ResourceForm from "./form";
import { Resources } from "../../../lib/database/";

class NewResource extends React.Component {
  state = {
    id: "",
    name: "",
    seats: 0,
    oldId: "",
    available: true
  };

  onChange = event => {
    if (event.target.name === "available") {
      this.setState({ available: event.target.value === "true" });
    } else {
      this.setState({ [event.target.name]: event.target.value });
    }
  };

  onSubmit = event => {
    event.preventDefault();
    if (!this.state.name || this.state.name.trim().length === 0) {
      return false;
    }

    Resources.add({
      name: this.state.name,
      seats: parseInt(this.state.seats),
      available: this.state.available,
      oldId: this.state.oldId
    });
    this.props.onClose();
  };

  render() {
    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.onClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Nuevo recurso</DialogTitle>
        <DialogContent>
          <ResourceForm
            errorName={false}
            errorSeats={false}
            name={this.state.name}
            oldId={this.state.oldId}
            seats={this.state.seats}
            available={this.state.available}
            onChange={this.onChange}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" onClick={this.onSubmit}>
            Guardar
          </Button>
          <Button onClick={this.props.onClose}>Cancelar</Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default NewResource;
