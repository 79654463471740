import React from "react";
import AddIcon from "@material-ui/icons/Add";
import Button from "@material-ui/core/Button";

import NewUser from "./new";
import EditUser from "./edit";
import ListUsers from "./list";
import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem";

class Users extends React.Component {
  state = {
    user: { id: "", name: "", role: "", cognitoId: "" },
    new: false,
    edit: false,
    delete: false,
    reload: false
  };

  openForm = (formName) => {

    this.setState({ [formName]: true });
  };

  closeForm = (formName) => {

    this.setState({ [formName]: false, reload: true });
  };

  onEdit = (user) => {
    this.setState({ user, edit: true });
  };

  onDelete = (user) => {
    this.setState({ user, delete: true });
  };

  render() {
    return (
      <GridContainer>
        <GridItem xs={ 12 } sm={ 12 } md={ 12 }>
          <Button
            variant="contained"
            color="primary"
            onClick={ () => this.openForm("new") }
          >
            <AddIcon /> Nuevo usuario
          </Button>
        </GridItem>
        <GridItem xs={ 12 } sm={ 12 } md={ 12 }>
          <ListUsers onEdit={ this.onEdit } onDelete={ this.onDelete } />

          <NewUser
            open={ this.state.new }
            onClose={ () => this.closeForm("new") }
          />
          <EditUser
            open={ this.state.edit }
            user={ this.state.user }
            onClose={ () => this.closeForm("edit") }
          />
        </GridItem>
      </GridContainer>
    );
  }
}

export default Users;
