import React from "react";
import { formatDinero } from "../../../../lib/utils/format";

const showAmount = (account, key, credit, totalRows) => (
  <tr key={key} className={key === totalRows ? "border-b border-gray-200" : ""}>
    <td colSpan="4" className="p-2 text-sm text-right">
      {account.name}
    </td>
    <td
      colSpan={credit ? "2" : "1"}
      className={"p-2 text-sm text-right" + (credit ? " text-red-600" : "")}
    >
      {formatDinero(account.total)}
    </td>
    <td colSpan={credit ? "1" : "2"}></td>
  </tr>
);

const AdminLogEntriesListAmount = ({ accounts }) => {
  const totalRows = accounts.length - 1;
  return accounts.map((account, key) => {
    if (account.type === "debit") {
      return showAmount(account, key, false, totalRows);
    } else {
      return showAmount(account, key, true, totalRows);
    }
  });
};

export default AdminLogEntriesListAmount;
