import { findCategoryById } from "../categories/finder";
import { findAllSubcategories } from "../categories/presenter";
import { productMatchesCategories } from "../products/finder";

const getProductFromBooking = (items, productId) =>
  items.find(item => item.productId === productId);

const getAccountsFromBooking = (accounts, booking) => {
  let income;
  const outcome = accounts.find(account => account.id === booking.accountId);
  if (booking.payment === "cash") {
    income = accounts.find(account => account.name === "Caja metálico");
  } else {
    income = accounts.find(account => account.name === "Caja Rural");
  }

  return [outcome, income];
};

const sumBookings = bookings => {
  let totalPayments = 0;
  let totalSeats = 0;
  let totalPending = 0;

  bookings.forEach(booking => {
    totalPayments += booking.amount;
    totalSeats += parseInt(booking.seats);
  });

  return [totalPayments, totalPending, totalSeats];
};

const salesFromProduct = (bookings, productId) => {
  if (productId === "all") {
    return [];
  }

  const sales = [];

  bookings.forEach(booking => {
    booking.items &&
      booking.items.forEach(item => {
        if (item.productId === productId) {
          sales.push({
            bookingId: booking.id,
            createdAt: booking.startDate,
            accountName: booking.accountName,
            quantity: item.quantity,
            price: item.price,
            name: item.title,
            total: item.price * item.quantity
          });
        }
      });
  });
  return sales;
};

const salesFromCategory = (bookings, categoryId, categories, products) => {
  if (categoryId === "all") {
    return [];
  }

  const sales = [];

  const category = findCategoryById(categories, categoryId);
  const result = new Set();
  result.add(category.id);
  findAllSubcategories(category.id, categories, result);
  const subCategories = [...result];

  bookings.forEach(booking => {
    booking.items &&
      booking.items.forEach(item => {
        if (productMatchesCategories(item.productId, products, subCategories)) {
          sales.push({
            bookingId: booking.id,
            createdAt: booking.startDate,
            accountName: booking.accountName,
            quantity: item.quantity,
            price: item.price,
            name: item.title,
            total: item.price * item.quantity
          });
        }
      });
  });

  console.log(sales);

  return sales;
};

export {
  getProductFromBooking,
  getAccountsFromBooking,
  sumBookings,
  salesFromCategory,
  salesFromProduct
};
