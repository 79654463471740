import React from "react";

import AdminReservationsTable from "./Table";
import ReservationsSearch from "./Search";

const AdminReservationsList = ({ bookings, accounts }) => (
  <div>
    <ReservationsSearch />
    <AdminReservationsTable bookings={bookings} accounts={accounts} />
  </div>
);

export default AdminReservationsList;
