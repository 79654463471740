import React, { useState } from "react";
import { connect } from "react-redux";
import SelectSearch from "react-select-search";
import Search from "@material-ui/icons/Search";

import { updateSalesParams } from "../../../store/actions";
import DateRangeSelector from "../../Shared/DateRange";

const SalesCategoriesSearch = ({
  queryParams,
  updateSalesParams,
  categories
}) => {
  const [queryFilter, setQueryFilter] = useState({ ...queryParams });

  const submitForm = () => {
    updateSalesParams(queryFilter);
  };

  const changeDate = (fieldName, value) => {
    const newQuery = { ...queryFilter };
    newQuery[fieldName] = value;
    setQueryFilter(newQuery);
  };

  const onResourceSelect = category => {
    const newQuery = { ...queryFilter };
    newQuery.categoryId = category.value;
    setQueryFilter(newQuery);
  };

  const categoryOptions = categories.map(category => ({
    name: category.title,
    value: category.id
  }));
  categoryOptions.unshift({ name: "** Todas **", value: "all" });

  return (
    <div className="p-4 min-h-full bg-gray-100">
      <div className="flex flex-col md:flex-row">
        <div className="w-full md:w-1/2 z-50">
          <DateRangeSelector
            startDate={queryFilter.startDate}
            endDate={queryFilter.endDate}
            dateChange={changeDate}
          />
        </div>

        <div className="mt-2 w-full md:w-1/2 md:mt-0">
          <label className="mb-2 font-semibold">Categoría</label>
          <SelectSearch
            options={categoryOptions}
            onChange={category => onResourceSelect(category)}
            value={queryFilter.categoryId}
            name="category"
            placeholder="Selecciona una categoría"
          />
        </div>
      </div>
      <div>
        <button className="btn primary" onClick={submitForm}>
          <Search /> Buscar
        </button>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({ ...state });

const mapDispatchToProps = dispatch => ({
  updateSalesParams: params => dispatch(updateSalesParams(params))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SalesCategoriesSearch);
