import React from "react";

import TicketsCategoryTabs from "../CategoryTabs";

const HeaderCategories = ({ categories, filterCategory }) => (
  <div className="w-full md:w-1/2">
    <TicketsCategoryTabs
      categories={categories}
      filterCategory={filterCategory}
    />
  </div>
);

export default HeaderCategories;
