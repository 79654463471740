import React from "react";
import { connect } from "react-redux";

import ReservationBadge from "../../Shared/ReservationBadge";
import ReservationsShowItems from "./Items";
import { formatDate } from "../../../lib/dates/format";
import SharedMessage from "../../Shared/Message";
import ListPayments from "../../Shared/ListPayments";
import { getResourcesNames } from "../../../lib/resources/finder";

const ReservationsShowHeader = ({ booking, resources }) => {
  return (
    <div>
      <div className="m-4 flex justify-between">
        <ReservationBadge name={booking.slotName}>
          {booking.slotName}
        </ReservationBadge>
        &nbsp;&nbsp;
        <h3 className="text-lg font-bold text-blue-800">
          {booking.accountName}
        </h3>
        <h3 className="text-md text-gray-800">
          {booking.slotName} - Pax. {booking.seats} - Total: {booking.total}
        </h3>
        <div className="flex justify-between w-1/5">
          <p>Fecha: {formatDate(booking.startDate)}</p>

          <p>
            Mesa(s): {getResourcesNames(resources, booking.resources || [])}
          </p>
        </div>
      </div>
      <div>
        <ListPayments bookingId={booking.id} />
        <h3 className="text-2xl font-bold text-blue-800">Consumo</h3>
        {booking.items ? (
          <ReservationsShowItems booking={booking} />
        ) : (
          <SharedMessage message="Esta reserva no tiene añadido ningún consumo todavía." />
        )}
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    resources: state.mainData.resources
  };
};

export default connect(mapStateToProps)(ReservationsShowHeader);
