import { format } from "date-fns";
import esLocale from "date-fns/locale/es";

const formatDate = fbDate => {
  const date = new Date(fbDate.seconds * 1000);

  return format(date, "dd-MM-yyyy");
};

const formatDateForSearch = date => {
  return format(date, "yyyy-MM-dd");
};

const monthNameAndYear = date => {
  return format(date, "MMMM - yyyy", { locale: esLocale });
};

const updateMonth = (date, newMonth) => {
  const updatedDate = new Date(date);
  return new Date(updatedDate.setMonth(updatedDate.getMonth() + newMonth));
};

export { formatDate, formatDateForSearch, monthNameAndYear, updateMonth };
