import * as React from "react";
import {
  Link,
  withStyles,
  Card,
  CardContent,
  Typography
} from "@material-ui/core";

const styles = {
  card: {
    maxWidth: "100%"
  },
  media: {
    display: "inline-block",
    height: 140
  },
  title: {
    display: "inline-block"
  }
};

const ProviderHeader = ({ provider, classes }) => {
  return (
    <Card className={classes.card}>
      <CardContent>
        <Typography
          gutterBottom
          variant="h5"
          component="h2"
          className={classes.title}
        >
          {provider.name} |{" "}
          <Link href={`/admin/providers`}>
            Volver a la lista de proveedores
          </Link>
        </Typography>
      </CardContent>
    </Card>
  );
};

export default withStyles(styles)(ProviderHeader);
