import React from "react";
import { connect } from "react-redux";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { clearGlobalMessage } from "../../store/actions";

class GlobalMessage extends React.Component {
  handleClose = (_event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    this.props.clearMessage();
  };

  render() {
    return (
      <Snackbar
        open={this.props.showMessage}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={8000}
        onClose={this.handleClose}
        message={this.props.message}
        action={[
          <IconButton
            key="close"
            aria-label="Cerrar"
            color="inherit"
            onClick={() => this.props.clearMessage()}
          >
            <CloseIcon />
          </IconButton>
        ]}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    showMessage: state.mainData.globalMessage.showMessage,
    message: state.mainData.globalMessage.message
  };
};

const mapDispatchToProps = dispatch => ({
  clearMessage: () => dispatch(clearGlobalMessage())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GlobalMessage);
