import React, { useState, useEffect } from "react";

import LoadingDialog from "../Shared/Dialogs/Loading";
import LogEntriesList from "./List";
import { FirebaseResults, LogEntries } from "../../lib/database/";

function LogEntriesFetch({ queryParams }) {
  const [fetchEntries, setFetchEntries] = useState({
    loading: false,
    logEntries: []
  });

  useEffect(
    function() {
      function loadData() {
        setFetchEntries({ loading: true });
        let results = [];

        let query = LogEntries.orderBy("createdAt")
          .where("createdAt", ">=", queryParams.startDate)
          .where("createdAt", "<=", queryParams.endDate);

        if (queryParams.tags && queryParams.tags.trim().length > 0) {
          query = query.where("tags", "array-contains", queryParams.tags);
        }

        if (queryParams.accountId && queryParams.accountId.trim().length > 0) {
          let outcomeQuery = query.where(
            "accountIds",
            "array-contains",
            queryParams.accountId
          );
          return outcomeQuery.get().then(docs => {
            results = FirebaseResults.map(docs);

            setFetchEntries({
              logEntries: results,
              loading: false
            });
          });
        } else {
          return query.onSnapshot(docs => {
            setFetchEntries({
              logEntries: FirebaseResults.map(docs) || [],
              loading: false
            });
          });
        }
      }
      loadData();
    },
    [queryParams]
  );

  const { logEntries, loading } = fetchEntries;

  if (loading) {
    return <LoadingDialog />;
  }

  return <LogEntriesList logEntries={logEntries} queryParams={queryParams} />;
}

export default LogEntriesFetch;
