import React, { useEffect, useState } from "react";

import AdminProvidersList from "./List";
import { FirebaseResults, Providers } from "../../../lib/database/";
import LoadingDialog from "../../Shared/Dialogs/Loading";

const AdminProvidersFetch = () => {
  const [localState, setLocalState] = useState({
    loading: false,
    providers: []
  });

  useEffect(() => {
    setLocalState({ loading: true, providers: [] });
    return Providers.orderBy("name").onSnapshot(docs => {
      setLocalState({ providers: FirebaseResults.map(docs), loading: false });
    });
  }, []);

  if (localState.loading) {
    return <LoadingDialog />;
  }

  return <AdminProvidersList providers={localState.providers} />;
};

export default AdminProvidersFetch;
