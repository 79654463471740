import React from "react";
import { connect } from "react-redux";

import SalesProductsFetch from "./Fetch";

const SalesProducts = ({ queryParams, products }) => {
  return <SalesProductsFetch queryParams={queryParams} products={products} />;
};

const mapStateToProps = state => ({
  queryParams: state.mainData.salesProductsQuery
});

export default connect(mapStateToProps)(SalesProducts);
