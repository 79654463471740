import React from "react";
import { css } from "@emotion/core";
import SearchIcon from "@material-ui/icons/Search";
import AddIcon from "@material-ui/icons/Add";

import SharedMessage from "../../Shared/Message";
import AdminProvidersListItems from "./ListItems";
import AdminProvidersNew from "./New";
import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem";
import Button from "../../../components/CustomButtons/Button";
import CustomInput from "../../../components/CustomInput/CustomInput";
import { InputAdornment } from "@material-ui/core";

class AdminProvidersList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showEdit: false,
      showNew: false,
      showDelete: false,
      activeProvider: {},
      searchTerm: "",
      providers: props.providers || []
    };
  }

  toggleModal = () => {
    this.setState({ showNew: !this.state.showNew });
  };

  componentDidUpdate(newProps) {
    if (newProps.providers !== this.props.providers) {
      this.filterProviders();
    }
  }

  onChange = event => {
    this.setState({ searchTerm: event.target.value }, this.filterProviders);
  };

  filterProviders = () => {
    let filterProviders = this.props.providers;
    const searchTerm = this.state.searchTerm.trim().toLowerCase();
    if (searchTerm.length > 1) {
      filterProviders = this.props.providers.filter(provider =>
        provider.name.toLowerCase().includes(searchTerm)
      );
    }

    this.setState({ providers: filterProviders });
  };

  render() {
    const providers = this.state.providers;

    return (
      <div>
        <GridContainer>
          <GridItem xs={12} lg={6}>
            <CustomInput
              labelText="Filtrar"
              inputProps={{
                id: "searchTerm",
                name: "searchTerm",
                autoFocus: true,
                onChange: this.onChange,
                value: this.state.searchTerm,
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                )
              }}
            />
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} lg={12}>
            <Button
              round
              color="linkedin"
              aria-label="Nuevo proveedor"
              onClick={() => this.toggleModal()}
            >
              <AddIcon /> Nuevo proveedor
            </Button>
          </GridItem>
          <GridItem xs={12} lg={12}>
            {providers.length > 0 ? (
              <AdminProvidersListItems providers={providers} />
            ) : (
              <SharedMessage message="No hemos encontrado ningún proveedor." />
            )}
          </GridItem>
          <GridItem
            xs={12}
            lg={12}
            className={css`
              margin-top: 1em;
            `}
          >
            <Button
              round
              color="linkedin"
              aria-label="Nuevo proveedor"
              onClick={() => this.toggleModal()}
            >
              <AddIcon /> Nuevo proveedor
            </Button>

            {this.state.showNew && (
              <AdminProvidersNew toggleModal={() => this.toggleModal()} />
            )}
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

export default AdminProvidersList;
