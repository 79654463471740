import React, { useEffect, useState } from "react";
import Edit from "@material-ui/icons/Edit";

import Button from "../../../components/CustomButtons/Button";
import Table from "../../../components/Table/Table";
import GridItem from "../../../components/Grid/GridItem";
import GridContainer from "../../../components/Grid/GridContainer";
import { FirebaseResults, Slots } from "../../../lib/database/";
import { translate } from "../../../locale/es";

const ListSlots = ({ onEdit }) => {
  const [localState, setLocalState] = useState({
    loading: false,
    slots: []
  });

  useEffect(() => {
    setLocalState({ loading: true, slots: [] });
    return Slots.orderBy("name").onSnapshot(docs => {
      setLocalState({ slots: FirebaseResults.map(docs), loading: false });
    });
  }, []);

  const renderItems = () => {
    return localState.slots.map(slot => [
      slot.name,
      slot.startHour,
      slot.endHour,
      translate[slot.requireSeats],
      <Button color="primary" simple onClick={() => onEdit(slot)}>
        <Edit />
      </Button>
    ]);
  };

  if (localState.loading) {
    return null;
  }

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Table
          tableHead={[
            "Nombre",
            "Hora inicio",
            "Hora fin",
            "Comensales",
            "Opciones"
          ]}
          tableData={renderItems()}
        />
      </GridItem>
    </GridContainer>
  );
};

export default ListSlots;
