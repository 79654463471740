import React from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";

import { FirebaseResults, Resources } from "../../../lib/database/";
import Button from "../../../components/CustomButtons/Button";

class ListResources extends React.Component {
  state = {
    resources: []
  };

  closeSubscription = null;

  componentDidMount() {
    this.closeSubscription = Resources.orderBy("name").onSnapshot(docs => {
      this.setState({ resources: FirebaseResults.map(docs) });
    });
  }

  componentWillUnmount = () => {
    this.closeSubscription();
  };

  formatItem(resource) {
    return `${resource.name} - Comensales: ${resource.seats} - Disponible: ${
      resource.available ? "Si" : "No"
    }`;
  }

  renderItems() {
    const { resources } = this.state;

    if (!resources || resources.length === 0) {
      return [<p key="1">Nada</p>];
    }

    return resources.map(resource => (
      <tr>
        <td>{this.formatItem(resource)}</td>
        <td>
          {
            <Button
              key="edit"
              color="success"
              simple
              aria-label="Edit"
              onClick={() => this.props.onEdit(resource)}
            >
              <EditIcon />
            </Button>
          }

          {
            <Button
              key="delete"
              color="danger"
              simple
              aria-label="Delete"
              onClick={() => this.props.onDelete(resource)}
            >
              <DeleteIcon />
            </Button>
          }
        </td>
      </tr>
    ));
  }

  render() {
    return (
      <table className="table-auto w-full">
        <thead>
          <tr>
            <td>Nombre</td>
            <td>Opciones</td>
          </tr>
        </thead>
        <tbody>{this.renderItems()}</tbody>
      </table>
    );
  }
}

export default ListResources;
