import React from "react";
import {
  Link,
  withStyles,
  Card,
  CardContent,
  Typography
} from "@material-ui/core";

import { formatDate } from "../../../../lib/dates/format";

const styles = {
  card: {
    maxWidth: "100%"
  },
  media: {
    display: "inline-block",
    height: 140
  },
  title: {
    display: "inline-block"
  }
};

const DeliveryHeader = ({ delivery, classes }) => {
  return (
    <Card className={classes.card}>
      <CardContent>
        <Typography gutterBottom variant="h5">
          {delivery.providerName}
        </Typography>
        <Typography gutterBottom variant="subtitle2">
          {formatDate(delivery.createdAt)} - Total pedido: {delivery.total} -
          Ref. {delivery.reference}
        </Typography>
        <Link href={`/admin/pedidos`}>Volver a la lista de pedidos</Link>
      </CardContent>
    </Card>
  );
};

export default withStyles(styles)(DeliveryHeader);
