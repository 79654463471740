import React from "react";
import { formatDinero } from "../../../../lib/utils/format";

import AdminLogEntriesAccountTotal from "./Total";

function accountTotal(account) {
  if (account.type === "debit") {
    return <p>{formatDinero(account.total)}</p>;
  } else {
    return <p className="text-red-700">{formatDinero(account.total)}</p>;
  }
}

function AdminLogEntriesAccountEntry({ logEntry, selectEntry, removeEntry }) {
  if (!logEntry.accounts) {
    return null;
  }

  return (
    <div className="text-sm text-gray-800">
      {logEntry.accounts.map((account, key) => (
        <div key={key} className="mb-2 flex justify-between items-center">
          <p onClick={() => selectEntry(account)}>{account.name}</p>
          {accountTotal(account)}
          <p className="text-red-400" onClick={() => removeEntry(account)}>
            <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24">
              <path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z" />
              <path d="M0 0h24v24H0z" fill="none" />
            </svg>
          </p>
        </div>
      ))}
      <AdminLogEntriesAccountTotal accounts={logEntry.accounts} />
    </div>
  );
}

export default AdminLogEntriesAccountEntry;
