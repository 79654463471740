import React from "react";
import { connect } from "react-redux";

import ListReservationItems from "./Items";
import AdminReservationsTableHeader from "../../Admin/Reservations/Table/Header";
import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem";
import ReservationsSearch from "../../Admin/Reservations/Search";
import { updateLayoutId } from "../../../store/actions";

const ListReservations = ({ bookings, updateLayoutId }) => {
  updateLayoutId({ id: "defaultApp" });

  return (
    <div>
      <ReservationsSearch />
      <GridContainer>
        <GridItem xs={12} md={12}>
          <AdminReservationsTableHeader bookings={bookings} />
        </GridItem>
        <GridItem xs={12} md={12}>
          <ListReservationItems />
        </GridItem>
      </GridContainer>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    bookings: state.mainData.bookings
  };
};

const mapDispatchToProps = dispatch => ({
  updateLayoutId: newId => dispatch(updateLayoutId(newId))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ListReservations);
