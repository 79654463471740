import React from "react";

import { Deliveries } from "../../../../lib/database/";
import DeliveryHeader from "./Header";
import LoadingDialog from "../../../Shared/Dialogs/Loading";
import DeliveryShowProducts from "./Products";
import ListPayments from "../../../Shared/ListPayments";

class AdminDeliveriesShow extends React.Component {
  state = {
    delivery: {},
    loading: false
  };

  componentDidMount() {
    const deliveryId = this.props.match.params.id.toString();

    this.setState({ loading: true });
    Deliveries.doc(deliveryId)
      .get()
      .then(doc => {
        const delivery = doc.data();
        delivery.id = deliveryId;
        this.setState({ delivery, loading: false });
      });
  }

  render() {
    const { delivery } = this.state;
    if (this.state.loading || !delivery.id) {
      return <LoadingDialog />;
    }

    return (
      <React.Fragment>
        <DeliveryHeader delivery={delivery} />
        <ListPayments deliveryId={delivery.id} />
        <DeliveryShowProducts deliveryItems={delivery.items} />
      </React.Fragment>
    );
  }
}

export default AdminDeliveriesShow;
