import React, { useState, useEffect } from "react";

import LoadingDialog from "../../Shared/Dialogs/Loading";
import SalesProductsList from "./List";
import { Bookings, FirebaseResults } from "../../../lib/database/";
import { salesFromProduct } from "../../../lib/bookings/finder";

function SalesProductsFetch({ queryParams, products }) {
  const [fetchEntries, setFetchEntries] = useState({
    loading: false,
    sales: []
  });

  useEffect(
    function() {
      function loadData() {
        setFetchEntries({ loading: true });

        let query = Bookings.orderBy("startDate")
          .where("startDate", ">=", queryParams.startDate)
          .where("startDate", "<=", queryParams.endDate);

        return query.get().then(docs => {
          const bookings = FirebaseResults.map(docs);
          setFetchEntries({
            sales: salesFromProduct(bookings, queryParams.productId, products),
            loading: false
          });
        });
      }
      loadData();
    },
    [queryParams, products]
  );

  const { sales, loading } = fetchEntries;

  if (loading) {
    return <LoadingDialog />;
  }

  return (
    <SalesProductsList
      sales={sales}
      products={products}
      queryParams={queryParams}
    />
  );
}

export default SalesProductsFetch;
