import React from "react";

import AdminReservationNewForm from "./new";
import AdminReservationUpdateForm from "./edit";

const AdminReservationForm = ({ operation, ...rest }) =>
  operation === "edit" ? (
    <AdminReservationUpdateForm { ...rest } />
  ) : (
      <AdminReservationNewForm { ...rest } />
    );

export default AdminReservationForm;
