import React from "react";
import styled from "@emotion/styled";

import SummaryItem from "./Item";
import GridItem from "../../../components/Grid/GridItem";
import { formatDinero } from "../../../lib/utils/format";

const SummaryColumn = styled("div")`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 1em;
`;

const SummaryIncome = ({ summaryReport }) => {
  const total = summaryReport.membersFee / 2 + summaryReport.sales / 2;
  return (
    <GridItem xs={12} sm={6}>
      <h2>Ingresos</h2>
      <hr />
      <SummaryColumn>
        <SummaryItem
          title="Cuotas"
          amount={formatDinero(summaryReport.membersFee / 2)}
        />
        <SummaryItem
          title="Consumos"
          amount={formatDinero(summaryReport.sales / 2)}
        />
        <SummaryItem title="Otros" amount={0} />
        <SummaryItem title="Tesorería" amount={0} />
        <SummaryItem title="Caja" amount={0} />
        <SummaryItem title="Banco" amount={0} />
        <SummaryItem title="Total" amount={formatDinero(total)} />
      </SummaryColumn>
    </GridItem>
  );
};

export default SummaryIncome;
