import React from "react";
import { connect } from "react-redux";
import Edit from "@material-ui/icons/Edit";

import BookingsList from "../../Shared/Bookings/List";
import Button from "../../../components/CustomButtons/Button";

const tableData = (bookings, onEdit) =>
  bookings.map(booking => {
    return {
      content: [
        <Button color="info" round size="sm" onClick={() => onEdit(booking)}>
          <Edit />
          {booking.slotName}&nbsp;{booking.seats} pax.
        </Button>
      ],
      booking
    };
  });

const Content = ({ bookings, onEdit, accounts }) => {
  const tableHead = ["Evento", "Acciones"];

  return (
    <BookingsList
      head={tableHead}
      items={tableData(bookings, onEdit)}
      accounts={accounts}
    />
  );
};

const mapStateToProps = state => ({
  accounts: state.mainData.accounts
});

export default connect(mapStateToProps)(Content);
