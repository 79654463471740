import * as React from "react";

import { Storage } from "../../config/firebase";

class ShowImage extends React.Component {
  state = {
    imageSrc: ""
  };
  componentDidMount() {
    const { product } = this.props;

    if (product.s3Image) {
      this.setState({
        imageSrc: `https://depuracepaaws-userfiles-mobilehub-933783480.s3.eu-central-1.amazonaws.com/public/${
          product.s3Image
        }`
      });
    } else {
      const fileRef = Storage.ref().child(product.image);
      fileRef.getDownloadURL().then(url => {
        this.setState({ imageSrc: url });
      });
    }
  }

  render() {
    const { product, ...rest } = this.props;
    return <img src={this.state.imageSrc} alt={product.title} {...rest} />;
  }
}

export default ShowImage;
