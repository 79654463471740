import React from "react";
import { connect } from "react-redux";
import Snackbar from "@material-ui/core/Snackbar";

import AdminReservationsFormField from "./Fields";
import { Bookings } from "../../../../lib/database/";
import { findSlotById, findSlotByName } from "../../../../lib/slots/finder";
import { findAccountById } from "../../../../lib/accounts/finder";
import { convertToDinero } from "../../../../lib/utils/format";

class AdminReservationNewForm extends React.Component {
  constructor(props) {
    super(props);

    const defaultSlot = findSlotByName(props.slots, "Cena");

    this.state = {
      snackOpen: false,
      infoMessage: "",
      booking: {
        slotId: defaultSlot.id,
        slotName: defaultSlot.name,
        selectedDate: new Date(),
        total: 0,
        seats: 0,
        accountId: "",
        accountName: "",
        payment: "cash",
        status: "pending",
        comments: "",
        resources: []
      }
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.slots !== this.props.slots && this.props.slots.length > 0) {
      const slot = findSlotByName(this.props.slots, "Cena");
      const booking = { ...this.state.booking };
      booking.slotId = slot.id;
      booking.slotName = slot.name;
      this.setState({ booking });
    }
  }

  dateChange = date => {
    const booking = { ...this.state.booking };
    booking.selectedDate = date;
    this.setState({ booking });
  };

  onChange = event => {
    const booking = { ...this.state.booking };
    booking[event.target.name] = event.target.value;
    this.setState({ booking });
  };

  onSelect = user => {
    const booking = { ...this.state.booking };
    booking.accountId = user.value;

    this.setState({ booking });
  };

  handleSubmit = event => {
    event.preventDefault();

    const { booking } = this.state;

    const slot = findSlotById(this.props.slots, booking.slotId);
    const account = findAccountById(this.props.users, booking.accountId);
    const totalAmount = parseFloat(booking.total);
    const amount = convertToDinero(totalAmount);

    const newBooking = {
      slotId: booking.slotId,
      startDate: new Date(booking.selectedDate),
      endDate: new Date(booking.selectedDate),
      amount,
      total: totalAmount,
      seats: parseInt(booking.seats),
      accountId: account.id,
      accountName: account.name,
      payment: booking.payment,
      status: booking.status,
      slotName: slot.name,
      createdAt: new Date()
    };

    if (booking.comments && booking.comments.trim().length > 0) {
      newBooking.comments = booking.comments;
    }

    Bookings.add(newBooking);
    this.props.toggleModal();
  };

  render() {
    const { toggleModal, slots, users } = this.props;

    return (
      <div>
        <AdminReservationsFormField
          title="Nueva reserva"
          toggleModal={toggleModal}
          slots={slots}
          onSubmit={this.handleSubmit}
          onChange={this.onChange}
          onSelect={this.onSelect}
          dateChange={this.dateChange}
          bookingInfo={this.state.booking}
          users={users}
        />
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left"
          }}
          open={this.state.snackOpen}
          autoHideDuration={6000}
          message={this.state.infoMessage}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  accounts: state.mainData.accounts
});

export default connect(mapStateToProps)(AdminReservationNewForm);
