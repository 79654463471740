import React from "react";

import ResourceItems from "./Resource";

const ListResources = ({
  onClick,
  tables,
  reservedResources,
  pendingSeats,
  availableSeats,
  resources
}) => {
  const showMessage = () => {
    if (availableSeats < pendingSeats) {
      return (
        <p className="text-red-600">
          Solo hay disponible sitio para {availableSeats} plazas y tu tienes
          pendiente de asignar {pendingSeats} comensales.
        </p>
      );
    } else {
      return <p>Pendiente de asignar sitio para {pendingSeats} comensales.</p>;
    }
  };

  return (
    <div>
      <div className="flex flex-wrap text-gray-900">
        <ResourceItems
          onClick={onClick}
          pendingSeats={pendingSeats}
          tables={tables}
          reservedResources={reservedResources}
          items={resources}
        />
      </div>
      {showMessage()}
    </div>
  );
};

export default ListResources;
