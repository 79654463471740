import React from "react";
import { AppBar, Tab, Tabs } from "@material-ui/core";

import { Products } from "../../../../lib/database/";
import LoadingDialog from "../../../Shared/Dialogs/Loading";
import ProductHeader from "./Header";
import ProductsShowDeliveries from "./Deliveries";
import ProductsShowSales from "./Sales";

class AdminProductsShow extends React.Component {
  state = {
    value: 0,
    loading: true,
    product: {}
  };

  componentDidMount() {
    const productId = this.props.match.params.id.toString();

    this.setState({ loading: true });
    Products.doc(productId)
      .get()
      .then(doc => {
        const product = doc.data();
        product.id = productId;
        this.setState({ product, loading: false });
      });
  }

  handleChange = (_event, value) => {
    this.setState({ value });
  };

  render() {
    if (this.state.loading) {
      return <LoadingDialog />;
    }

    return (
      <div>
        <ProductHeader product={this.state.product} />
        <AppBar position="static">
          <Tabs value={this.state.value} onChange={this.handleChange}>
            <Tab label="Compras" />
            <Tab label="Ventas" />
          </Tabs>
        </AppBar>
        {this.state.value === 0 && (
          <ProductsShowDeliveries productId={this.state.product.id} />
        )}
        {this.state.value === 1 && (
          <ProductsShowSales productId={this.state.product.id} />
        )}
      </div>
    );
  }
}

export default AdminProductsShow;
