import React, { useState, useEffect } from "react";

import AdminLogEntriesList from "./List";
import LoadingDialog from "../../../Shared/Dialogs/Loading";
import { LogEntries, FirebaseResults } from "../../../../lib/database";
import { convertToDinero } from "../../../../lib/utils/format";
import { sumLogEntryAccounts } from "../../../../lib/log_entries/calc";

function AdminLogEntriesFetch({ queryParams }) {
  const [listEntries, setListEntries] = useState({
    loading: false,
    logEntries: []
  });

  useEffect(
    function() {
      async function loadData() {
        setListEntries({ ...listEntries, loading: true });
        let results = [];

        let query = LogEntries.orderBy("createdAt")
          .where("createdAt", ">=", queryParams.startDate)
          .where("createdAt", "<=", queryParams.endDate);

        if (queryParams.tags && queryParams.tags.trim().length > 0) {
          query = query.where("tags", "array-contains", queryParams.tags);
        }

        if (queryParams.accountId && queryParams.accountId.trim().length > 0) {
          let outcomeQuery = query.where(
            "accountIds",
            "array-contains",
            queryParams.accountId
          );
          return outcomeQuery.get().then(docs => {
            results = FirebaseResults.map(docs);
            setListEntries({
              logEntries: [...results],
              loading: false
            });
          });
        } else {
          return query.get().then(docs => {
            setListEntries({
              logEntries: FirebaseResults.map(docs) || [],
              loading: false
            });
          });
        }
      }

      loadData();
    },
    [queryParams]
  );

  const { logEntries, loading } = listEntries;
  let sumEntries;

  if (loading) {
    return <LoadingDialog />;
  }

  // logEntries.forEach(function(logEntry) {
  //   const result = sumLogEntryAccounts(logEntry);
  //   const amount = result.totalIncome;
  //   console.log(logEntry.id, amount);
  //   LogEntries.doc(logEntry.id).update({ amount });
  // });

  return (
    <AdminLogEntriesList
      logEntries={logEntries}
      sumEntries={sumEntries}
      queryParams={queryParams}
    />
  );
}

export default AdminLogEntriesFetch;
