import React from "react";

import DeleteDialog from "../Shared/Dialogs/Delete";

const DeleteBooking = ({ onClose, item, open, onDelete }) => (
  <DeleteDialog
    title="Borrar reserva"
    onClose={onClose}
    item={item}
    open={open}
    onDelete={onDelete}
  />
);

export default DeleteBooking;
