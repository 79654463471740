const resources = (state = [], action) => {
  switch (action.type) {
    case "UPDATE_RESOURCES":
      return action.resources;
    default:
      return state;
  }
};

export { resources };
